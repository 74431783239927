import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress as LinearProgressMaterial } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ProgressText } from './styles';

/* istanbul ignore next */
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgressMaterial);

const LinearProgress = ({ progress }) => (
  <>
    <BorderLinearProgress value={progress} variant="determinate" />
    <ProgressText>
      {progress}%
    </ProgressText>
  </>
);

LinearProgress.defaultProps = {
  progress: 0,
};

LinearProgress.propTypes = {
  progress: PropTypes.number,
};

export default LinearProgress;
