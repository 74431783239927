import React from 'react';
import { LinearProgress as LinearProgressMaterial } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LinearProgressTheme = withStyles((theme) => ({
  root: {
    height: 6,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#1a90ff',
  },
}))(LinearProgressMaterial);

const LoadingBar = () => (
  <LinearProgressTheme data-testid="test-loading-bar" />
);

export default LoadingBar;
