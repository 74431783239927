import { API } from 'config';
import axios from 'axios';

const getPromotions = async (cpf) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');
	const cleanCpf = cpf.replace(/[^\d]+/g, '');
	const response = await axios
		.get(`${API.LIST_PROMOTIONS}/${cleanCpf}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	if (response?.data?.success === false) {
		throw response?.data?.value;
	}

	if (response?.data) {
		return response?.data?.value;
	}

	return [];
};

export default getPromotions;
