import styled from 'styled-components';
import { Button } from '@material-ui/core';

const ICON_SIZE = 20;

export const Content = styled.div`
  padding-bottom: 16px;
`;

export const TableWrapper = styled.div`
  border: 1px solid ${({ noBorder }) => (noBorder ? 'transparent' : '#e0e0e0')};
  border-radius: 8px;
  padding: 8px 16px;
  max-width: 400px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Td = styled.div`
  padding: 2px 0;
  min-width: 270px;
`;

export const TdLeft = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 16px;
  color: #666666;
`;

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
  padding-bottom: 24px;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;

export const StatusBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StatusLabel = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 8px;
  color: #666666;
`;

export const StatusTitle = styled.div`
  color: #004d40;
  font-weight: 600;
  font-size: 14px;
`;

export const Icon = styled.img`
  margin-left: 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  vertical-align: sub;
  cursor: pointer;
`;

export const Modal = styled.div`
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 500px;
  border: 1px solid rgb(153, 153, 153);
  position: fixed;
  z-index: 999;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 20%) 3px 3px 5px 5px;
  padding: 15px;
`;

export const CloseButton = styled.img`
  height: 20px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
  padding-bottom: 24px;
`;

export const ButtonOk = styled(Button)`
  float: right;
`;

export const TextWrapper = styled.div`
`;
