import { API } from 'config';
import axios from 'axios';
import { getToken } from 'auth/user';

const getExternalDebts = async (userId) => {
	const accessToken = getToken();
	const response = await axios
		.get(`${API.EXTERNAL_DEBTS}`, {
			params: {
				userId,
			},
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((error) => {
			return { status: error?.response?.data?.statusCode, ...error?.response?.data };
		});

	if (response?.data) {
		return response.data;
	}

	return null;
};

export default getExternalDebts;
