import React from "react";
import { AlertContainer, CloseButton } from "./styles";
import PropTypes from "prop-types";

export const ALERT_TYPES = {
	ERROR: "error",
	SUCCESS: "success",
	WARNING: "warning",
	PRIMARY: "primary",
	SECONDARY: "secondary",
	INFO: "info",
};

function Alert({ children, type = ALERT_TYPES.INFO, visible, onClose }) {
	return (
		<AlertContainer className={`${type} ${!visible ? "hide" : ""}`}>
			{onClose && (
				<CloseButton data-testid="close-alert" onClick={() => onClose(false)}>
					&times;
				</CloseButton>
			)}
			{children}
		</AlertContainer>
	);
}

Alert.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

export default Alert;