/* eslint-disable */

import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import iconClose from "../assets/close-window-xxl.png";
import { Select } from "_FUTURE_LIBS_/@ali-ui";
import { CPFField, FullnameField, NaturalnessField, IssuerDateField, DocumentIssuerField } from "_FUTURE_LIBS_/@ali-forms";
import { Button } from "@material-ui/core";
import { Modal, CloseButton, ModalTitle, ButtonOk, TextWrapper, UploadWrapper } from "./styles";

import { pdf2Image, compressImage } from "./uploadUtils";
import getCountries from "api/alicore/getCountries";

export const validateNationalId = (data) => {
  if (!data) return false;
  const validFront = data.alreadyHasFront || data.pictureFront;
  const validBack = data.documentType === "CNH" || data.alreadyHasBack || data.pictureBack;

  let invalid =
    !(data.documentType || data.type) ||
    !data.number ||
    !data.issuerInstitution ||
    !data.expeditionDate ||
    !data.naturalness ||
    !data.nationality ||
    !data.maritalStatus ||
    !data.motherName;

  if (!invalid) {
    invalid = !validFront || !validBack;
  }

  return !invalid;
};
export const getNationalIdFormData = (data) => {
  const formData = new FormData();
  formData.append("number", data.number);
  formData.append("documentType", data.documentType);
  formData.append("issuerInstitution", data.issuerInstitution);
  formData.append("motherName", data.motherName);
  formData.append("fatherName", data.fatherName);
  formData.append("naturalness", data.naturalness);
  formData.append("nationality", data.nationality);
  formData.append("expeditionDate", data.expeditionDate);
  formData.append("maritalStatus", data.maritalStatus);
  formData.append("pictureFront", data.pictureFront.content, data.pictureFront.name);
  if (data.pictureBack) formData.append("pictureBack", data.pictureBack.content, data.pictureBack.name);

  return formData;
};

const NationalId = ({ isOpen, onClose, doc, onOK, loading }) => {
  const [documentType, setDocumentType] = useState();
  const [number, setNumber] = useState();
  const [issuerInstitution, setIssuerInstitution] = useState();
  const [motherName, setMotherName] = useState();
  const [fatherName, setFatherName] = useState();
  const [naturalness, setNaturalness] = useState();
  const [nationality, setNationality] = useState();
  const [expeditionDate, setExpeditionDate] = useState();
  const [maritalStatus, setMaritalStatus] = useState();
  const [pictureFront, setPictureFront] = useState();
  const [pictureBack, setPictureBack] = useState();
  const [processingFile, setProcessingFile] = useState(false);
  const [countries, setCountries] = useState([]);

  const RG = "RG";
  const CNH = "CNH";
  const documentTypes = [
    { text: RG, value: RG },
    { text: CNH, value: CNH },
  ];

  const SINGLE = "Single";
  const MARRIED = "Married";
  const SEPARATED = "Separated";
  const DIVORCED = "Divorced";
  const WIDOWER = "Widower";
  const STABLEUNION = "StableUnion";
  const UNINFORMED = "Uninformed";
  const maritalStatuses = [
    { text: "Solteiro", value: SINGLE },
    { text: "Casado", value: MARRIED },
    { text: "Separado", value: SEPARATED },
    { text: "Divorciado(a)", value: DIVORCED },
    { text: "Viuvo(a)", value: WIDOWER },
    { text: "União estável", value: STABLEUNION },
    { text: "Não Informado", value: UNINFORMED },
  ];

  const handleOnClose = () => {
    if (onClose) onClose(doc);
  };

  const handleOnOK = () => {
    const data = {
      number,
      documentType,
      issuerInstitution,
      motherName,
      fatherName,
      naturalness,
      expeditionDate: moment(expeditionDate, "DD/MM/YYYY").toISOString(),
      nationality,
      maritalStatus,
      pictureFront,
      pictureBack,
    };

    if (onOK) onOK(doc, data);
  };

  const handleOnChangeDocumentType = async (value) => {
    setDocumentType(value);
  };

  const handleOnChangeNumber = async (value) => {
    setNumber(value);
  };

  const handleOnChangeIssuerInstitution = async (value) => {
    setIssuerInstitution(value);
  };

  const handleOnChangeMotherName = async (value) => {
    setMotherName(value);
  };

  const handleOnChangeFatherName = async (value) => {
    setFatherName(value);
  };

  const handleOnChangeNaturalness = async (value) => {
    setNaturalness(value);
  };

  const handleOnChangeExpeditionDate = async (value) => {
    setExpeditionDate(value);
  };

  const handleOnChangeNationality = async (value) => {
    setNationality(value);
  };

  const handleOnChangeMaritalStatus = async (value) => {
    setMaritalStatus(value);
  };

  const handleOnChangePictureFrontFile = async (e) => {
    let file = null;
    if (e.target.files && e.target.files.length > 0) {
      file = { content: e.target.files[0], name: e.target.files[0].name };
      setProcessingFile(true);
      const pdfImage = await pdf2Image(file, true, true);
      if (pdfImage) {
        file.content = pdfImage[0];
        file.name = file.name.toLowerCase().replace(".pdf", ".png");
      } else {
        file.content = await compressImage(file, true);
        const pos = file.name.lastIndexOf(".");
        file.name = file.name.substr(0, pos < 0 ? file.name.length : pos) + ".png";
      }
      setProcessingFile(false);
    }
    setPictureFront(file);
  };

  const handleOnChangePictureBackFile = async (e) => {
    let file = null;
    if (e.target.files && e.target.files.length > 0) {
      file = { content: e.target.files[0], name: e.target.files[0].name };
      setProcessingFile(true);
      const pdfImage = await pdf2Image(file, true, true);
      if (pdfImage) {
        file.content = pdfImage[0];
        file.name = file.name.toLowerCase().replace(".pdf", ".png");
      } else {
        file.content = await compressImage(file, true);
        const pos = file.name.lastIndexOf(".");
        file.name = file.name.substr(0, pos < 0 ? file.name.length : pos) + ".png";
      }
      setProcessingFile(false);
    }
    setPictureBack(file);
  };

  const handleOnClickPictureFrontFile = () => {
    document.getElementById("pictureFront").click();
  };

  const handleOnClickPictureBackFile = () => {
    document.getElementById("pictureBack").click();
  };

  const handleGetCountries = async () => {
    const countriesResult = await getCountries();

    const countries = countriesResult?.map((item) => {
      return {
        value: item.key,
        text: item.value,
      };
    });

    setCountries(countries);
  };

  useEffect(() => {
    handleGetCountries();
  }, []);

  return (
    <Modal style={{ display: isOpen ? "block" : "none" }}>
      <ModalTitle>{doc.value}</ModalTitle>
      <CloseButton src={iconClose} onClick={handleOnClose} data-testid={"nationalId-closeModal"} />
      <div>
        <Select
          label="Tipo de documento enviado"
          options={documentTypes}
          value={documentType}
          onChange={handleOnChangeDocumentType}
          fullWidth
        />
        <TextWrapper>
          <CPFField label="CPF do Cliente" placeholder="" value={number} onChange={handleOnChangeNumber} />
        </TextWrapper>
        <TextWrapper>
          <FullnameField label="Nome do pai" placeholder="" value={fatherName} onChange={handleOnChangeFatherName} />
        </TextWrapper>
        <TextWrapper>
          <FullnameField label="Nome da mãe" placeholder="" value={motherName} onChange={handleOnChangeMotherName} />
        </TextWrapper>
        <TextWrapper>
          <NaturalnessField label="Naturalidade" placeholder="" value={naturalness} onChange={handleOnChangeNaturalness} />
        </TextWrapper>
        <TextWrapper>
          <IssuerDateField label="Data da expedição" placeholder="" value={expeditionDate} onChange={handleOnChangeExpeditionDate} />
        </TextWrapper>
        <TextWrapper>
          <Select label="País de Origem" options={countries} value={nationality} onChange={handleOnChangeNationality} fullWidth />
        </TextWrapper>
        <TextWrapper>
          <DocumentIssuerField
            label="Orgão expedidor"
            placeholder=""
            value={issuerInstitution}
            onChange={handleOnChangeIssuerInstitution}
          />
        </TextWrapper>
        <TextWrapper>
          <Select label="Estado civil" options={maritalStatuses} value={maritalStatus} onChange={handleOnChangeMaritalStatus} fullWidth />
        </TextWrapper>
      </div>
      <UploadWrapper>
        <Button
          variant="contained"
          color={!pictureFront ? "default" : "primary"}
          disabled={processingFile}
          onClick={handleOnClickPictureFrontFile}
        >
          {processingFile ? "Processando" : "Frente"}
        </Button>
        <input
          id="pictureFront"
          type="file"
          onChange={handleOnChangePictureFrontFile}
          accept="image/*,application/pdf"
          style={{ display: "none" }}
        />
        {documentType == RG && (
          <>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color={!pictureBack ? "default" : "primary"}
              disabled={processingFile}
              onClick={handleOnClickPictureBackFile}
            >
              {processingFile ? "Processando" : "Verso"}
            </Button>
            <input
              id="pictureBack"
              type="file"
              onChange={handleOnChangePictureBackFile}
              accept="image/*,application/pdf"
              style={{ display: "none" }}
            />
          </>
        )}
      </UploadWrapper>
      <ButtonOk disabled={loading} variant="contained" color="primary" onClick={handleOnOK} data-testid={"nationalId-okModal"}>
        {loading ? "Enviando" : "OK"}
      </ButtonOk>
    </Modal>
  );
};

NationalId.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  doc: {
    value: PropTypes.string,
  },
  onOK: PropTypes.func,
  loading: PropTypes.bool,
};

export default NationalId;
