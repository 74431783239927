/* eslint-disable */
import React, { useEffect, useState } from 'react';
import iconClose from '../assets/close-window-xxl.png';
import { DropDownSearchable } from '_FUTURE_LIBS_/@ali-ui';
import { BankAgencyField, BankAccountField } from '_FUTURE_LIBS_/@ali-forms';
import { Modal, CloseButton, ModalTitle, ButtonOk, TextWrapper } from './styles';
import banks from './banks.json';
import PropTypes from 'prop-types';

export const validateBankAccount = (data) => {
	const invalid = !data.code || !data.name || !data.agency || !data.accountNumber || !data.accountDigit;

	return !invalid;
};

export const getBankAccountFormData = (data) => {
	const formData = new FormData();
	formData.append('code', data.code);
	formData.append('name', data.name);
	formData.append('agency', data.agency);
	formData.append('accountNumber', data.accountNumber);
	formData.append('accountDigit', data.accountDigit);

	return formData;
};

const BANK_LIST = banks.items;

const BankAccount = ({ isOpen, onClose, doc, onOK, loading, data = null }) => {
	const [code, setCode] = useState();
	const [name, setName] = useState();
	const [agency, setAgency] = useState();
	const [accountNumber, setAccountNumber] = useState();
	const [accountDigit, setAccountDigit] = useState();
	const [readOnly, setReadOnly] = useState(false);

	useEffect(() => {
		if (!data) {
			return;
		}

		const bank = BANK_LIST.find((bank) => bank.code === data?.code);

		if (!bank) {
			return;
		}

		setName(bank.name);
		setCode(bank.code);
		setAgency(data?.agency || '');
		setAccountNumber(data?.accountNumber || '');
		setAccountDigit(data?.accountDigit || '');
		setReadOnly(true);
	}, [data]);

	const handleOnClose = () => {
		if (onClose) onClose(doc);
	};

	const handleOnOK = () => {
		const data = {
			code,
			name,
			agency,
			accountNumber,
			accountDigit,
		};

		if (onOK) onOK(doc, data);
	};

	const handleOnChangeCode = async (value) => {
		if (!value.target.innerText) {
			setName('');
			setCode('');
			return;
		}

		const selectedBank = value.target.innerText.substr(0, 3);
		const bank = BANK_LIST.find((bank) => bank.code === selectedBank);

		if (bank) {
			setName(bank.name);
			setCode(bank.code);
		} else {
			setName('');
			setCode('');
		}
	};

	const handleOnChangeAgency = async (value) => {
		setAgency(value);
	};

	const handleOnChangeAccountNumber = async (value) => {
		setAccountNumber(value);
	};

	const handleOnChangeAccountDigit = async (value) => {
		setAccountDigit(value);
	};

	return (
		<Modal style={{ display: isOpen ? 'block' : 'none' }}>
			<ModalTitle>{doc.value}</ModalTitle>
			<CloseButton src={iconClose} onClick={handleOnClose} data-testid="bankAccount-closeModal" />
			<div>
				<DropDownSearchable
					label="Banco"
					placeholder="Digite aqui o seu banco"
					value={code}
					options={BANK_LIST.sort((a, b) => -b.name.localeCompare(a.name[0]))}
					groupBy={(option) => option.name[0]}
					getOptionLabel={(option) => `${option.code} - ${option.name}`}
					onChange={handleOnChangeCode}
					onSelect={handleOnChangeCode}
					maxVisibleLines={5}
					disabled={readOnly}
					fullWidth
				/>
				<TextWrapper>
					<BankAgencyField
						label="Agência (sem o dígito)"
						maxLength={4}
						placeholder=""
						value={agency}
						onChange={handleOnChangeAgency}
						disabled={readOnly}
					/>
				</TextWrapper>
				<TextWrapper>
					<BankAccountField
						label="Conta (sem o dígito)"
						placeholder=""
						value={accountNumber}
						onChange={handleOnChangeAccountNumber}
						disabled={readOnly}
					/>
				</TextWrapper>
				<TextWrapper>
					<BankAgencyField
						label="Dígito"
						placeholder=""
						value={accountDigit}
						maxLength={1}
						onChange={handleOnChangeAccountDigit}
						disabled={readOnly}
					/>
				</TextWrapper>
			</div>
			<ButtonOk
				disabled={loading || readOnly}
				variant="contained"
				color="primary"
				onClick={handleOnOK}
				style={{ marginTop: 0 }}
				data-testid={'bankAccount-okModal'}
			>
				{loading ? 'Enviando' : 'OK'}
			</ButtonOk>
		</Modal>
	);
};

BankAccount.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  doc: {
    value: PropTypes.string,
  },
  onOK: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
};

export default BankAccount;