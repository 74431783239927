const isValid = (value) => {
  const validChars = /^[0-9.-]+$/;
  if (!value.match(validChars)) {
    return false;
  }

  const cpf = value.replace(/\D/g, '');
  if (cpf.length !== 11) {
    return false;
  }

  // DOC: verifica se todos os algarismos são iguais
  const unique = new Set(cpf.split(''));
  if (unique.size === 1) {
    return false;
  }

  let sum = 0;
  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  let rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }
  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  return rest === parseInt(cpf.substring(10, 11), 10);
};

export default isValid;


