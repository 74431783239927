import React, { useState, createContext, useMemo } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import { Alerta } from './styles';

export const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [anchorOrigin, setAnchorOrigin] = useState({ vertical: 'top', horizontal: 'right' });
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(5000);
  const [severity, setSeverity] = useState('');
  const [message, setMessage] = useState('');

  const showSnackbar = (options) => {
    setAnchorOrigin(options.anchorOrigin ? options.anchorOrigin : { vertical: 'top', horizontal: 'right' });
    setOpen(true);
    setDuration(options.duration ? options.duration : 5000);
    setSeverity(options.severity ? options.severity : '');
    setMessage(options.message ? options.message : '...');
  };

  const contextValue = useMemo(() => [showSnackbar], [showSnackbar]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alerta severity={severity}>{message}</Alerta>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
