import React, { useState, useContext } from 'react';
import { SnackbarContext } from "contexts/SnackbarProvider";
import { Spacer } from '_FUTURE_LIBS_/@ali-ui';
import DocumentBox from './DocumentBox';
import SelectedProduct from './SelectedProduct';
import {
  Content,
  TableWrapper,
  Row,
  Td,
  TdLeft,
  TableTitle,
  NotInformed,
  StatusBox,
  StatusLabel,
  StatusTitle,
  Icon
} from './styles';
import iconWarning from './assets/iconWarning.svg';
import ReSendMail, { RESEND_MSG } from './ReSendMail';
import resendMail from 'api/alicore/resendMail';
import { statuses } from '../../helpers/statuses';
import PropTypes from "prop-types";

const ProposalSummary = ({ status, creditReason, shoppingCart, proposal, onUpdateDocOrRetry, loading, setLoading }) => {
  const [showSnackbar] = useContext(SnackbarContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = () => setIsOpen(true);

  const handleOnClose = () => setIsOpen(false);

  const handleOnOK = async () => {
    setLoading(true);
    const result = await resendMail(proposal.id);
    if (!result) {
      showSnackbar({
        message: `Houve algum problema ao reenviar o e-mail. Tente novamente e caso o problema persista contate o suporte.`,
        severity: "error",
        duration: 6000,
      });
    }

    setLoading(false);
    setIsOpen(false);
  };

  return (
    <TableWrapper>
      <Content>
        <TableTitle>{"Resumo da Proposta"}</TableTitle>
        <StatusBox>
          <StatusLabel>{"Status:"}</StatusLabel>
          <StatusTitle>
            {status ? statuses(status) : status}
            {status == "awaitingContractSign" && (
              <>
                <Icon src={iconWarning} onClick={handleOnClick} title={RESEND_MSG} />
                <ReSendMail isOpen={isOpen} onClose={handleOnClose} onOK={handleOnOK} loading={loading} />
              </>
            )}
          </StatusTitle>
        </StatusBox>
        <Row>
          <TdLeft>{"Motivo de Crédito:"}</TdLeft>
          <Td>{creditReason ? creditReason.FriendlyName : <NotInformed>{"(não informado)"}</NotInformed>}</Td>
        </Row>
      </Content>
      <Spacer width={32} />
      <Content>
        <SelectedProduct />
      </Content>
      <Spacer width={32} />
      <Content>
        <DocumentBox shoppingCart={shoppingCart} onUpdateDocOrRetry={onUpdateDocOrRetry} loading={loading} setLoading={setLoading} />
      </Content>
    </TableWrapper>
  );
};

ProposalSummary.propTypes = {
  status: PropTypes.string,
  creditReason: PropTypes.shape({
    FriendlyName: PropTypes.string,
  }),
  shoppingCart: PropTypes.object,
  proposal: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onUpdateDocOrRetry: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default ProposalSummary;