
import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import { ButtonStyled, ButtonBackStyled } from './styles';

const Button = ({ isBackButton, ...rest }) => {
  const theme = createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      {isBackButton ? (
        <ButtonBackStyled {...rest} />
      ) : (
        <ButtonStyled {...rest} />
      )}
    </MuiThemeProvider>
  );
};

Button.defaultProps = {
  isBackButton: false,
};

Button.propTypes = {
  isBackButton: PropTypes.bool,
};

export default Button;
