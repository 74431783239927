import styled from "styled-components";

export const HEADER_HEIGHT = "64px";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 24px;
	width: calc(100% - 48px);
	height: ${HEADER_HEIGHT};
	background-color: #ffffff;
	font-size: 24px;
	border-bottom: 1px solid #f0f0f0;
	font-weight: 600;
	color: #031e52;
`;

export const Logo = styled.img`
	position: absolute;
	left: 32px;
	width: auto;
	height: 30px;
`;

export const SignOutWrapper = styled.div`
	position: absolute;
	display: flex;
	right: 20px;
`;
