import { API } from "config";
import axios from "axios";
import { getToken } from "auth/user";

const generateSimplifiedOffer = async ({ nationalDocument, forceUpdate = false }) => {
  const accessToken = getToken();
  const response = await axios
    .post(
      API.SIMPLIFIED_OFFER,
      {
        nationalDocument,
        forceUpdate,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .catch((error) => {
      return { status: error?.response?.data?.statusCode, ...error?.response?.data };
    })
    .then((resp) => resp);

  if (response?.data) {
    return response.data;
  }

  return null;
};

export default generateSimplifiedOffer;
