import { API } from "config";
import axios from "axios";

const updateShoppingCart = async (id, monthlyIncome, creditReason) => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios.put(
    `${API.UPDATE_SHOPPING_CART_DATA}${id}`,
    {
      monthlyIncome,
      creditReason,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch(e => {});

  return response && response.status == 200;
};

export default updateShoppingCart;
