import React, { useContext, useState, useEffect } from "react";
import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";
import Paper from "@material-ui/core/Paper";
import getAgreementDetail from "../../api/alicore/getAgreementDetail";
import searchCpf from "api/alicore/searchCpf";
import { formatDisplayDate, formatDisplayCpf, formatDisplayPhone, formatDisplayCnpj } from "utils";
import { TableWrapper, Row, Td, TdLeft, TableTitle, NotInformed, Warning } from "./styles";

import AgreementNotFount from "../AgreementNotFound";

const AgreementDetail = () => {
  const [agreement, setAgreement] = useState({});

  const [client, setClient] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);

  const dataAgreement = [
    [1, "CNPJ da Empresa:", formatDisplayCnpj(agreement.companyDocumentNumber)],
    [2, "Nome da Empresa:", agreement.companyName],
    [3, "CPF do Colaborador:", formatDisplayCpf(agreement.employeeDocumentNumber)],
    [4, "Nome do Colaborador:", agreement.employeeFullName],
    [5, "E-mail:", agreement.email],
    [6, "Celular:", formatDisplayPhone(agreement.cellPhone)],
    [7, "Data de Nascimento:", formatDisplayDate(agreement.birthDate)],
    [8, "Cargo:", agreement.registeredPosition],
    [9, "CBO:", agreement.cbo],
    [10, "Opção do Sistema de Folha de Pagamento:", agreement.payrollSystemOption],
    [
      11,
      "Margem Livre:",
      agreement.consignableFreeMargin?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ],
    [
      12,
      "Salário:",
      agreement.monthlyIncome?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ],
    [13, "Restrição do RH:", agreement.hrRestriction ? "Sim" : "Não"],
    [
      14,
      "Valor do Desconto:",
      agreement.discountsAmount?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ],
    [15, "Data de Admissão:", formatDisplayDate(agreement.bondBeginDate)],
    [
      16,
      "Renda Variável:",
      agreement.variableIncome?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ],
    [
      17,
      "Limite Utilizado:",
      agreement.utilizedLimit?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
    ],
    [18, "Data de Utilização do Limite:", formatDisplayDate(agreement.utilizedLimitDate)],
    [19, "Rating:", agreement.rating],
    [20, "Tipo de Integração:", client.agreement ? client.agreement.payrollSystemOption : null],
    [21, "Mês de Referência:", formatDisplayDate(agreement ? agreement.monthReferenceDate : null)],
    [22, "Última Atualização:", formatDisplayDate(agreement ? agreement.lastPayrollUpdated_at : null)],
  ];

  const getData = async () => {
    const response = await getAgreementDetail(client.user.document);

    if (response) setAgreement(response);
    else if (client.agreement?.id) setAgreement(client.agreement);
  };

  const handleCreateData = async () => {
    const result = await searchCpf(sessionStorage.getItem("ALI_CURRENT_CUSTOMER"));
    if (result?.invalidNetwork) {
      showSnackbar({
        message: `Ocorreu um problema de rede e não foi possível acessar os dados desse cliente. Verifique a internet do seu computador e tente novamente. Caso não funcione, contate o suporte de infraestrutura.`,
        severity: "error",
        duration: 10000,
      });
      return;
    }
    await getData();
    setClient(result);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Paper elevation={2}>
      <TableWrapper>
        <TableTitle>Informações Detalhadas de Convênio</TableTitle>
        {!agreement && <Warning>Carregando...</Warning>}
        {!agreement.employeeDocumentNumber && <AgreementNotFount onCreateData={handleCreateData} />}
        {agreement.employeeDocumentNumber &&
          dataAgreement.map(([id, fieldName, value]) => (
            <Row key={id}>
              <TdLeft>{fieldName}</TdLeft>
              <Td>{value || <NotInformed>(não informado)</NotInformed>}</Td>
            </Row>
          ))}
      </TableWrapper>
    </Paper>
  );
};

export default AgreementDetail;
