import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  width: 200px;
`;

export const Loading = styled(CircularProgress)`
  position: absolute;
  right: -80px;
  top: 18px;
`;
