import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isValid from './validation';

const ERROR_MSG = 'Verifique seu nome';

const FullnameField = ({
  label,
  placeholder,
  onChange,
  value,
  noValidation,
  autoFocus,
}) => {
  const [error, setError] = useState(false);

  const handleOnChange = (fullname) => {
    if (fullname === '' || (/^[a-záàâãäéèêíïóôõöúüçñ`'˜ˆ"\- ]+$/i).test(fullname)) {
      onChange(fullname);
    }
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && !isValid(value);
      setError(invalid && ERROR_MSG);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  FullnameField.isValid = () => (value !== '' && isValid(value));

  return (
    <TextField
      data-testid="field-fullname"
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      error={error}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      autoFocus={autoFocus}
    />
  );
};

/* istanbul ignore next */
FullnameField.defaultProps = {
  label: 'Nome completo',
  placeholder: 'Digite aqui seu nome',
  onChange: () => {},
  value: '',
  noValidation: false,
  autoFocus: false,
};

FullnameField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default FullnameField;
