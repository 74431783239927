import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div`
`;

export const TableWrapper = styled.div`
  border: 1px solid ${({ noBorder }) => (noBorder ? 'transparent' : '#e0e0e0')};
  border-radius: 8px;
  padding: 8px 16px;
  height: fit-content;
`;

export const Td = styled.td`
  position: relative;
  padding: 2px 0;
  min-width: 270px;
`;

export const TdLeft = styled.td`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 16px;
  color: #666666;
`;

export const TableTitle = styled.td`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
`;

export const EditPhone = styled.div`
  position: absolute;
  right: 8px;
  margin-left: 12px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 0 4px;
  border-radius: 5px;
  cursor: pointer;
  color: #3646a8;
  background-color: #f0f0f0;
  font-size: 12px;
  height: 20px;

  ${({ color }) => color && `color: ${color === 'red' ? '#f43936' : '#00a016'};`}

  &:hover {
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);
    }
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const OkCancelWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 8px;
  margin-top: 12px;
`;

export const Input = styled.input`
  width: 120px;
  border: 1px solid #e0e0e0;
  padding: 2px;
  border-radius: 4px;
`;

export const PhoneWrapper = styled.div`
  width: 100%;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;

export const Title = styled.div`
  color: #b0b0b0;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const WarningBox = styled.div`
  display: flex;
  align-items:center;
  position: absolute;
  padding: 8px;
  color: #b28d1c;
  background-color: #ffecb3;
  border-radius: 8px;
  font-size: 14px;
  margin-right: 20px;
`;

export const ErrorBox = styled.div`
  display: flex;
  align-items:center;
  position: absolute;
  padding: 8px;
  color: #5c0011;
  background-color: #ffccc7;
  border-radius: 8px;
  font-size: 14px;
  margin-right: 20px;
`;

