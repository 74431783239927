import { API } from 'config';

const putPriorityPolice = async (id) => {
  try {
    const response = await fetch(API.PUT_PRIORITY_POLICE_URL.replace('{id}', id), {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return {
        success: true,
        failure: false,
        status: response.status,
      };
    }
    return {
      success: false,
      failure: true,
      status: response.status,
    };
  } catch (error) {
    return {
      success: false,
      failure: true,
      error,
    };
  }
};

export default putPriorityPolice;
