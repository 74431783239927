import { API } from "config";
import axios from "axios";

const simulateShoppingCart = async (id, amount) => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios
    .post(
      `${API.SIMULATE_SHOPPING_CART}${id}`,
      {
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .catch((e) => {});

  return response && response.status == 200 ? response.data : null;
};

export default simulateShoppingCart;
