/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import iconClose from '../assets/close-window-xxl.png';
import {
    FullnameField,
    BirthdateField,
    PhoneField,
    EmailField
} from '_FUTURE_LIBS_/@ali-forms';
import { takeMask } from 'utils';
import {
    Modal,
    CloseButton,
    ModalTitle,
    ButtonOk,
    TextWrapper
} from './styles';
import moment from 'moment';

export const validateUserEdition = (validationData) => {
    const invalid  = (!FullnameField.isValid(validationData.name)
                   || !BirthdateField.isValid(moment(validationData.birthDate,"YYYY-MM-DD").format('DD/MM/YYYY'))
                   || !PhoneField.isValid(validationData.phone.replace('+55', '').trim())
                   || !EmailField.isValid(validationData.email));

    return !invalid;        
}

const UserEdit = ( { data, loading, isOpen, onClose, onOK }) => {

    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [birthDate, setBirthDate] = useState();
    const [email, setEmail] = useState();
    const [confirming, setConfirming] = useState(false);

    const handleOnClose = () => {
        setConfirming(false);

        if (onClose) onClose();
    }

    const handleOnOK = () => {
        if (confirming) {
            setConfirming(false);
            const internalData = {
                name,
                birthDate: moment(birthDate, 'DD/MM/YYYY').format("YYYY-MM-DD"),
                phone: phone? `+55${phone}`: phone,
                email
            }
    
            if (onOK) onOK({...internalData, phone: takeMask(phone)});
        } else {
            setConfirming(true);
        }
    }

    const handleOnChangeName = async (value) => {
        setName(value);
    }

    const handleOnChangeBirthDate = async (value) => {
        setBirthDate(value);
    }

    const handleOnChangePhone = async (value) => {
        setPhone(value);
    }

    const handleOnChangeEmail = async (value) => {
        setEmail(value);
    }

    const getButtonLabel = () => {
      if (loading) return "Enviando";
      if (confirming) return "Clique novamente para confirmar";
      return "OK";
    }

    useEffect(() => {
        if (data) {
            if (data.name) setName(data.name);
            if (data.birthDate) {
                setBirthDate(moment(data.birthDate,"YYYY-MM-DD").format('DD/MM/YYYY'))
            }
            if (data.phone){
                data.phone = data.phone.trim();
                setPhone(data.phone);
            }
            if (data.email) setEmail(data.email);
        }
    }, [])

    return (<Modal style={{display: isOpen ? 'block': 'none'}}>
        <ModalTitle>Editar registro do Cliente</ModalTitle>
        <CloseButton src={iconClose} onClick={handleOnClose} />
        <div>
            <TextWrapper>
                <FullnameField 
                    label="Nome"
                    placeholder=""
                    value={name}
                    onChange={handleOnChangeName}
                    />
            </TextWrapper>
            <TextWrapper>
                <BirthdateField 
                    label="Data nascimento"
                    placeholder=""
                    errorInvalid="Verifique a data de nascimento"
                    value={birthDate}
                    onChange={handleOnChangeBirthDate}
                    />
            </TextWrapper>
            <TextWrapper>
                <PhoneField 
                    label="Telefone"
                    placeholder=""
                    value={phone}
                    onChange={handleOnChangePhone}
                    />
            </TextWrapper>
            <TextWrapper>
                <EmailField 
                    label="E-mail"
                    placeholder=""
                    value={email}
                    onChange={handleOnChangeEmail}
                    />
            </TextWrapper>
        </div>
        <ButtonOk
            disabled={loading}
            variant="contained"
            color={!confirming? "primary" : "secondary"}
            onClick={handleOnOK}
        >
            {getButtonLabel()}
        </ButtonOk>
    </Modal>);
};

UserEdit.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOK: PropTypes.func,
};

export default UserEdit;
