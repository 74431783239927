import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import { numericFormatter } from '_FUTURE_LIBS_/@ali-forms/utils/formatters';

const PERCENT_SYMBOL = '%';
const ERROR_MSG = 'Valor inválido';

const PercentField = ({
  noValidation,
  value,
  onChange,
  label,
  ...rest
}) => {
  const ref = useRef();
  const [error, setError] = useState('');

  const handleOnBlur = () => {
    const inputValue = ref.current.value;
    if (!noValidation) {
      const formatedValue = numericFormatter(inputValue, true);
      if (!formatedValue) {
        setError(ERROR_MSG);
      } else {
        onChange(formatedValue);
      }
    }
  };

  const handleOnChange = (valueChanged) => {
    const valueFormatted = numericFormatter(valueChanged);
    if (valueFormatted !== false) {
      onChange(valueFormatted);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  return (
    <TextField
      inputRef={ref}
      label={label}
      onChange={handleOnChange}
      value={value === '' ? value : `${value} ${PERCENT_SYMBOL}`}
      inputMode="decimal"
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      error={error}
      {...rest}
    />
  );
};

PercentField.defaultProps = {
  label: 'Qual o percentual?',
  onChange: () => {},
  value: '',
  noValidation: false,
};

PercentField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
};

export default PercentField;
