import styled from 'styled-components';

export const Alerta = styled.div`
  color: white;
  background-color: ${(props) => {
    if (!props.severity) {
      return 'grey';
    }
    switch (props.severity) {
      case 'error': return '#f44336';
      case 'warning': return '#ff9800';
      case 'info': return '#2196f3';
      case 'success': return '#4caf50';
      default: return 'grey';
    }
  }};
  padding: 1rem;
  border-radius: 5px;
  margin-left: 0 !important;
  position: absolute !important;
  width: 33em !important;
  margin-top: 15em !important;
`;
