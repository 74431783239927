import React, { useContext, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../auth/authConfig";
import { MicrosoftButton } from "pages/Login/styles";
import microsoftLogo from "../../../../assets/microsoft.svg";
import getInternalUser from "api/getInternalUser";
import { saveUserData } from "auth/user";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "contexts/SnackbarProvider";
import { tracker } from "libs/open-replay";

export const SignInButton = ({ setLoading }) => {
	const [showSnackbar] = useContext(SnackbarContext);

	const { instance } = useMsal();
	const history = useHistory();

	const handleLogin = useCallback(() => {
		setLoading(true);
		instance
			.loginPopup(loginRequest)
			.then((res) => {
				getInternalUser(res.accessToken)
					.then(({ value }) => {
						saveUserData(value.jwt, value.user, [value.user.role]);
						history.replace("/");
						setLoading(false);
            tracker.setUserID(value.user.email);
					})
					.catch((error) => {
						showSnackbar({
							message: `Houve algum problema ao autenticar com a Ali`,
							severity: "error",
							duration: 6000,
						});
						setLoading(false);
					});
			})
			.catch((e) => {
				showSnackbar({
					message: `Houve algum problema ao entrar com a conta Microsoft.`,
					severity: "error",
					duration: 6000,
				});
				setLoading(false);
			});
	}, [instance]);

	return (
		<MicrosoftButton onClick={handleLogin}>
			<img src={microsoftLogo} /> Entrar com Microsoft
		</MicrosoftButton>
	);
};
