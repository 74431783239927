import styled from 'styled-components';

export const SpacerStyled = styled.div`
  ${({ width }) => (width ? `min-width: ${width}px;` : '')}
  ${({ height }) => (height ? `min-height: ${height}px;` : '')}
  padding: 0;
  margin: 0;
  border: 0;

  ${({ onlyDesktop }) => (onlyDesktop ? `
    display: none;
  ` : '')
}
`;
