import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import getMaxOfferInformation from "api/alicore/getMaxOfferInformation";
import { ClientContext } from "contexts/ClientProvider";
import { currencyToString } from "helpers/formaters";
import { useDownloadScreenShot } from "hooks/useDownloadScreenShot";
import { InstallmentsOptions } from "./InstallmentsOptions";
import SimulationHistory from "./SimulationHistory";
import PropTypes from "prop-types";

import * as S from "./styles";

import logo from "../../assets/logo.svg";

function Simulation({ installmentsOptions, total, selectedProduct }) {
  const history = useHistory();
  const [client] = useContext(ClientContext);
  const [loading, setLoading] = useState(true);
  const [maxOfferData, setMaxOfferData] = useState(null);

  const today = format(new Date(), "dd-MM-yyyy_hh-mm-ss");

  const { ref, getImage } = useDownloadScreenShot({
    fileName: client?.user?.name ? `${client.user.name}-${today}` : `simulacao-${today}`,
    extension: "png",
  });

  useEffect(() => {
    (async () => {
      if (!client?.customer) {
        history.replace("/user");
        history.go();
        return;
      }

      setLoading(true);

      const maxOffer = await getMaxOfferInformation(client.customer.id);

      setMaxOfferData(maxOffer);
      setLoading(false);
    })();
  }, [client?.customer?.id]);

  useEffect(() => {
    if (ref.current && !loading && maxOfferData && client) {
      getImage();
    }
  }, [loading]);

  const firstInstallmentDate = installmentsOptions?.[0]?.firstInstallmentDate;

  const maxOffer = maxOfferData?.offers.reduce(
    (acc, offer) => ({
      maxEconomizometro: Math.max(acc.maxEconomizometro, offer.maxEconomizometro),
      maxLimitNewContract: Math.max(acc.maxLimitNewContract, offer.maxLimitNewContract),
      maxLimitPortability: Math.max(acc.maxLimitPortability, offer.maxLimitPortability),
      maxRefin: Math.max(acc.maxRefin, offer.maxRefin),
    }),
    {
      maxEconomizometro: 0,
      maxLimitNewContract: 0,
      maxLimitPortability: 0,
      maxRefin: 0,
    }
  );
  return (
    <S.Wrapper ref={ref}>
      <S.HeaderContainer>
        <S.Header>
          <img src={logo} alt="Ali logo" />
        </S.Header>
      </S.HeaderContainer>

      <S.Content>
        <S.Main>
          <S.Container>
            <S.SubTitle>Liberdade financeira, de verdade</S.SubTitle>
            <S.Title>A Ali tem o melhor para você!</S.Title>

            <S.ModalitiesWrapper>
              <S.Column>
                <SimulationHistory loading={loading} maxOfferData={maxOfferData} />

                <S.Section>
                  <S.ModalTitle>Modalidades disponíveis</S.ModalTitle>

                  <S.ModalitiesContainer>
                    <S.ModalitiesHeader>Exclusividade para você</S.ModalitiesHeader>

                    {maxOffer && (
                      <S.ModalitiesItemContainer key={maxOffer.maxEconomizometro}>
                        <S.ModalitiesItem>
                          <p>Economizômetro</p>
                          <S.ModalitiesItemValue>{currencyToString(maxOffer.maxEconomizometro)}</S.ModalitiesItemValue>
                        </S.ModalitiesItem>

                        <S.ModalitiesItem>
                          <p>Novo Contrato</p>
                          <S.ModalitiesItemValue>{currencyToString(maxOffer.maxLimitNewContract)}</S.ModalitiesItemValue>
                        </S.ModalitiesItem>

                        <S.ModalitiesItem>
                          <p>Portabilidade</p>
                          <S.ModalitiesItemValue>{currencyToString(maxOffer.maxLimitPortability)}</S.ModalitiesItemValue>
                        </S.ModalitiesItem>

                        <S.ModalitiesItem>
                          <p>Refinanciamento</p>
                          <S.ModalitiesItemValue>{currencyToString(maxOffer.maxRefin)}</S.ModalitiesItemValue>
                        </S.ModalitiesItem>
                      </S.ModalitiesItemContainer>
                    )}
                  </S.ModalitiesContainer>
                </S.Section>
              </S.Column>

              <S.Column>
                <S.Modal>
                  <S.Section>
                    <S.ModalTitle>Modalidade escolhida</S.ModalTitle>

                    <S.MiniModal>
                      <header>
                        <h3>{selectedProduct}</h3>
                      </header>

                      <p>{currencyToString(total)}</p>
                    </S.MiniModal>

                    <h4>Parcelas exclusivas Ali</h4>

                    <InstallmentsOptions installmentOptions={installmentsOptions} />

                    {firstInstallmentDate && (
                      <>
                        <S.FirstParcelDate>Data da primeira parcela:</S.FirstParcelDate>
                        <S.ParcelDate>{format(new Date(firstInstallmentDate), "dd/MM/yyyy")}</S.ParcelDate>
                      </>
                    )}
                  </S.Section>

                  <S.ModalFooter>
                    <p>
                      Simulação realizada em: <span>{format(new Date(), "dd/MM/yyyy")}</span>
                    </p>
                    <p>Proposta válida por 3 dias.</p>
                  </S.ModalFooter>
                </S.Modal>
              </S.Column>
            </S.ModalitiesWrapper>
          </S.Container>

          <S.Footer>
            <p>
              Sujeito a análise de crédito no momento que for efetivada a contratação, podendo acarretar em mudança no valor de limite
              disponível e condições.
            </p>
          </S.Footer>
        </S.Main>
      </S.Content>
    </S.Wrapper>
  );
}

Simulation.propTypes = {
  total: PropTypes.number,
  selectedProduct: PropTypes.string,
  installmentsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfPayableInstallments: PropTypes.number,
      installmentAmount: PropTypes.number,
      firstInstallmentDate: PropTypes.string,
    })
  ),
};

export default Simulation;
