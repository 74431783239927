import { API } from 'config';
import axios from 'axios';

const getDebts = async (userId) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');
	const response = await axios
		.get(`${API.LIST_DEBTS}${userId}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	if (!response?.data) {
		return null;
	}

	return response.data;
};

export default getDebts;
