import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isValid from './validation';

const ERROR_MSG_LANDLINE = 'Verifique seu telefone';
const ERROR_MSG_MOBILE = 'Verifique seu celular';

const PhoneField = ({
  label,
  placeholder,
  landline,
  onChange,
  value,
  noValidation,
  errorMessage,
  noBorderChangeOnHover,
}) => {
  const [error, setError] = useState(false);

  const handleOnChange = (phone) => {
    if (!noValidation && !landline) {
      const onlyNumbers = phone.replace(/\D/g, '');
      const invalid = (onlyNumbers.length < 2 && onlyNumbers.charAt(4) !== '9');
      setError(invalid && (errorMessage || (landline ? ERROR_MSG_LANDLINE : ERROR_MSG_MOBILE)));
    }
    onChange(phone);
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && !isValid(value);
      setError(invalid && (errorMessage || (landline ? ERROR_MSG_LANDLINE : ERROR_MSG_MOBILE)));
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  PhoneField.isValid = (data = value) => (data !== '' && isValid(data));

  return (
    <TextField
      data-testid="field-phone"
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      inputMode="tel"
      format={{
        delimiters: ['+', ' (', ') ', '-'],
        blocks: [0, 2, 2, landline ? 4 : 5, 4],
        numericOnly: true,
        delimiterLazyShow: true,
      }}
      error={error}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      noBorderChangeOnHover={noBorderChangeOnHover}
    />
  );
};

/* istanbul ignore next */
PhoneField.defaultProps = {
  label: 'Telefone',
  placeholder: 'Digite aqui seu telefone',
  onChange: () => {},
  landline: false,
  value: '',
  noValidation: false,
  errorMessage: null,
  noBorderChangeOnHover: false,
};

PhoneField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  landline: PropTypes.bool,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
  errorMessage: PropTypes.string,
  noBorderChangeOnHover: PropTypes.bool,
};

export default PhoneField;
