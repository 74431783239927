import axios from "axios";
import { API } from "config";
import loginAdmin from "./alicore/loginAdmin";

const getInternalUser = async (accessToken) => {
	try {
		await loginAdmin();

		return axios
			.post(API.GET_INTERNALUSER, { accessToken }, { headers: { aliauth: `Bearer ${sessionStorage.getItem("ALI_ACCESS_TOKEN")}` } })
			.then((response) => {
				return {
					success: true,
					failure: false,
					status: response.statusCode,
					value: response.data,
				};
			})
			.catch((error) => {
				return {
					success: false,
					failure: true,
					status: error.response.status,
				};
			});
	} catch (error) {
		return {
			success: false,
			failure: true,
			error,
		};
	}
};

export default getInternalUser;
