import styled from 'styled-components';

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
  padding-bottom: 24px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TdLeft = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 16px;
  color: #666666;
`;

export const Td = styled.div`
  padding: 2px 0;
  min-width: 270px;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;
