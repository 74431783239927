import { currencyToString } from 'helpers/formaters';
import PropTypes from 'prop-types';

import * as S from './styles';

const SimulationHistory = ({ loading, maxOfferData }) => {
	return (
		<S.History>
			<header>
				<h2>Meu histórico</h2>
			</header>

			<S.HistoryInformation>
				{!loading && maxOfferData && (
					<S.Content>
						<div>
							<p>Salário</p>
							<S.ContentValue>{maxOfferData.salary ? currencyToString(maxOfferData.salary) : 'Nulo'}</S.ContentValue>
						</div>

						<div>
							<p>Limite utilizado</p>
							<S.ContentValue>{currencyToString(maxOfferData.offersMax.limitUtilized)}</S.ContentValue>
						</div>

						<div>
							<p>Limite disponível</p>
							<S.ContentValue>{currencyToString(maxOfferData.offersMax.limitAli)}</S.ContentValue>
						</div>
					</S.Content>
				)}
			</S.HistoryInformation>

			<S.HistoryFooter>
				{!loading && maxOfferData && (
					<>
						<p>Margem disponível</p>
						<h5>{maxOfferData.salaryMargin ? currencyToString(maxOfferData.salaryMargin) : 'Nulo'}</h5>
					</>
				)}
			</S.HistoryFooter>
		</S.History>
	);
};

SimulationHistory.propTypes = {
  loading: PropTypes.bool,
  maxOfferData: PropTypes.objectOf(
		PropTypes.shape({
			salary: PropTypes.number,
			salaryMargin: PropTypes.number,
			offersMax: PropTypes.shape({limitAli: PropTypes.number, limitUtilized: PropTypes.number})
		})
	),
}

export default SimulationHistory