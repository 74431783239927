import styled from 'styled-components';
import { Button as ButtonMaterial } from '@material-ui/core';

const next = {
  color: '#ffffff',
  backgroundColor: '#3772FF',
};

const back = {
  color: '#031E52',
  backgroundColor: '#EAECEE',
};

const ButtonCommon = `
  border-radius: 8px;
  color: ${next.color};
  font-weight: bold;
`;

export const ButtonStyled = styled(ButtonMaterial)`
  && {
    ${ButtonCommon}

    color: ${next.color};
    background-color: ${next.backgroundColor};
    &:hover {
      background-color: ${next.backgroundColor};
    }
  }
`;

export const ButtonBackStyled = styled(ButtonMaterial)`
  && {
    ${ButtonCommon}

    color: ${back.color};
    background-color: ${back.backgroundColor};
    &:hover {
      background-color: ${back.backgroundColor};
    }
  }
`;
