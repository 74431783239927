import { API } from 'config';
import axios from 'axios';
import { getToken } from 'auth/user';

const getMaxOfferInformation = async (customerId) => {
	const accessToken = getToken();
	const response = await axios
		.get(`${API.MAX_OFFER_INFORMATION}${customerId}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((error) => {
			return { status: error?.response?.data?.statusCode, ...error?.response?.data };
		});

	if (response?.data) {
		return response.data;
	}

	return null;
};

export default getMaxOfferInformation;
