import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ClientContext } from 'contexts/ClientProvider';
import { SnackbarContext } from 'contexts/SnackbarProvider';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Button } from '@material-ui/core';
import {
  convertToDecimal,
} from 'utils';
import putPriorityPolice from 'api/putPriorityPolice';
import {
  Container,
  Content,
  Title,
  TableWrapper,
  Th,
  Td,
  Tr,
  StatusDisable,
  StatusEnable,
  Icon,
} from './styles';

const creditReasonsTranslator = (displayName) => {
  const Dictionary = {
    ExpensiveDebtSwap: 'Troca de dívidas mais caras',
    HouseImprovements: 'Melhorias na minha casa',
    ImportantPurchase: 'Compra importante',
    CarSwap: 'Troca de carro',
    BusinessOrCompany: 'Negócios ou empresa',
    Trip: 'Viagem',
    MedicalBills: 'Despesas médicas',
    UnforeseenEvents: 'Imprevistos',
    Other: 'Outro',
    Portability: 'Portabilidade',
    SalaryAnticipation: 'Antecipação de salario',
  };
  if (!displayName) {
    return undefined;
  }
  return Dictionary[displayName] ? Dictionary[displayName] : displayName;
};

const PoliceHistory = ({ getProposalsCallBack }) => {
  const [client] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);
  const [polices, setPolices] = useState();

  const handleDisable = async (id) => {
    const response = await putPriorityPolice(id);
    if (response.failure) {
      showSnackbar({
        message: `Falha ao desabilitar política, motivo: ${response.status || response.error}`,
        severity: 'error',
      });
    }
    showSnackbar({
      message: 'Política desabilitada com sucesso',
      severity: 'success',
    });
    getProposalsCallBack();
  };

  useEffect(() => {
    setPolices(client.PriorityPolice);
  }, []);

  return (
    <Container>
      <Content>
        <Title>
          Histórico de Políticas
        </Title>
      </Content>
      <Content>
        <TableWrapper>
          {!polices && (<div>Nenhuma política encontrada.</div>)}
          {!!polices && (
            <table>
              <tbody>
                <tr>
                  <Th>Fator Mult. Min.</Th>
                  <Th>Fator Mult. Máx.</Th>
                  <Th>Taxa de Juros</Th>
                  <Th>Parcelas Min. </Th>
                  <Th>Parcelas Máx. </Th>
                  <Th>Motivo do Crédito</Th>
                  <Th>Status</Th>
                </tr>
                {
              polices.map(({
                id,
                minSalaryPercentage,
                maxSalaryPercentage,
                interestRate,
                minNumberOfInstallments,
                maxNumberOfInstallments,
                creditReasonName,
                enabled,
              }) => (
                <Tr key={`${id}`}>
                  <Td>{convertToDecimal(minSalaryPercentage)} X </Td>
                  <Td>{convertToDecimal(maxSalaryPercentage)} X</Td>
                  <Td>{convertToDecimal(interestRate)}%</Td>
                  <Td>{minNumberOfInstallments === 1 ? `${minNumberOfInstallments} parcela` : `${minNumberOfInstallments} parcelas`}</Td>
                  <Td>{maxNumberOfInstallments === 1 ? `${maxNumberOfInstallments} parcela` : `${maxNumberOfInstallments} parcelas`}</Td>
                  <Td>{creditReasonsTranslator(creditReasonName)}</Td>
                  {enabled === true && (
                    <>
                      <Td>
                        <StatusEnable>
                          <Icon><DoneIcon color="inherit" fontSize="small" /></Icon>
                          Em vigor
                        </StatusEnable>
                      </Td>
                      <Td>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDisable(id)}
                          fullWidth
                        >
                          Desabilitar
                        </Button>
                      </Td>
                    </>
                  )}
                  {enabled === false && (
                    <Td>
                      <StatusDisable>
                        <Icon><NotInterestedIcon color="secondary" fontSize="small" /></Icon>
                        Desabilitada
                      </StatusDisable>
                    </Td>
                  )}
                </Tr>
              ))
            }
              </tbody>
            </table>
          )}
        </TableWrapper>
      </Content>
    </Container>
  );
};

export default PoliceHistory;

PoliceHistory.propTypes = {
  getProposalsCallBack: PropTypes.func.isRequired,
};
