import React from 'react';
import Header from 'components/Header';
import { Container, Content } from './styles';

const PageNotFound = () => (
  <Container>
    <Header />
    <Content>
      Página não encontrada.
    </Content>
  </Container>
);

export default PageNotFound;
