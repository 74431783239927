import React, { useContext, useEffect, useCallback } from "react";
import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";
import getPriorityPolice from "api/getPriorityPolice";
import SendPolice from "components/SendPolice";
import PoliceHistory from "components/PoliceHistory";
import { Container, Content, Title } from "./styles";

const PriorityPolice = () => {
  const [client, setClient] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);

  const getProposalsCallBack = useCallback(async () => {
    const response = await getPriorityPolice(client?.user?.document);
    if (response.failure && response.status !== 404) {
      showSnackbar({
        message: `Falha ao obter políticas, motivo: ${response.status || response.error}`,
        severity: "error",
      });
    }
    await setClient((preState) => ({ ...preState, PriorityPolice: response.value }));
  }, []);

  useEffect(() => {
    getProposalsCallBack();
  }, [getProposalsCallBack]);

  return (
    <Container>
      <Content>
        <Title>Política de Prioridades</Title>
      </Content>
      <Content>
        <SendPolice />
      </Content>
      <Content>
        <PoliceHistory getProposalsCallBack={getProposalsCallBack} />
      </Content>
    </Container>
  );
};

export default PriorityPolice;
