import { API } from 'config';
import axios from 'axios';

const createProposal = async (userId, product, debtInstallmentAmount, debtToRefin, creditReason, offersData = {}) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');
	const result = {};
	const response = await axios
		.post(
			`${API.CREATE_PROPOSAL}${userId}`,
			{
				product,
				debtInstallmentAmount,
				limitAddAmount: debtToRefin,
				creditReason,
				offersData,
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
		.catch((e) => {
			result.message = e.response.data.message;
		});

	if (response?.data) {
		if (response.data.success) {
			result.data = response.data;
		} else if (!response.data.shoppingCarts || response.data.shoppingCarts.length === 0) {
			result.message = 'Cliente não possui os parâmetros de elegibilidade requeridos pela empresa';
			result.eligibilityError = true;
		}
	}

	return result;
};

export default createProposal;
