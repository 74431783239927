import styled from 'styled-components';
import { fonts } from '../../styles/fonts';
import { textColors } from '../../styles/colors';

export const Label = styled.div`
  ${fonts.fontFamily}
  ${textColors.placeholder}
  font-size: 16px;
  white-space: nowrap;
  padding-bottom: 2px;
`;
