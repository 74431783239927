import styled from 'styled-components';
import { Button } from '@material-ui/core';

const ICON_SIZE = 20;

export const CPFWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 2px;
`;

export const TextWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 2px;
`;

export const DropDownWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 2px;
`;

export const UploadWrapper = styled.div`
	margin-top: 15px;
	margin-bottom: 2px;
`;

export const Modal = styled.div`
	display: flex;
	inset: 50% auto auto 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 500px;
	border: 1px solid rgb(153, 153, 153);
	position: fixed;
	z-index: 999;
	border-radius: 12px;
	box-shadow: rgb(0 0 0 / 20%) 3px 3px 5px 5px;
	padding: 15px;
`;

export const CloseButton = styled.img`
	height: 20px;
	position: absolute;
	right: 6px;
	top: 6px;
	cursor: pointer;
`;

export const ModalTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #031e52;
	padding-bottom: 10px;
`;

export const ButtonOk = styled(Button)`
	float: right;
	margin-top: -40px;
`;

export const InvalidDoc = styled.span`
	color: red;
	font-size: 11px;
	margin-left: 5px;
	padding: 4px;
	border-color: #ccc !important;
	border: 1px solid;
`;

export const Container = styled.div`
	position: relative;
	display: flex-end;
	flex-direction: column;
`;

export const Title = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	font-size: 14px;
	color: #666666;
`;

export const TitleDisabled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  color: #adadad !important;
`;

export const MagicLinkBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;

  button {
    height: 30px;
  }

  button svg {
    margin-right: 0.6rem;
  }
`;

export const IconWrapper = styled.div`
	display: flex;
`;

export const Icon = styled.img`
	margin-right: 5px;
	padding: 5px;
	margin-top: auto;
	margin-bottom: auto;
	width: ${ICON_SIZE}px;
	height: ${ICON_SIZE}px;
`;

export const TableTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #031e52;
`;

export const TableSubTitle = styled.div`
	color: red;
	font-size: 11px;
	padding-bottom: 24px;
`;

export const SubTitleObs = styled.div`
	color: #2c64f9;
`;

export const StatusTag = styled.div`
	background: #11d78f44;
	margin: auto 0.5rem;
	display: flex;
	font-size: 0.7rem;
	height: 1rem;
	color: #11d78f;
	border-radius: 0.5rem;
	padding: 0 0.5rem;
`;

export const PayslipImage = styled.img`
	max-width: 500px;
	max-height: 600px;
	width: 100%;
	height: 100%;
	object-fit: contain;
	position: absolute;
	top: 0;
	left: 0;
	user-select: none;
`;

export const WatermarkContainer = styled.div`
	position: relative;
	width: 500px;
	height: 600px;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		background-image: url(/watermark-logo.png);
		background-position: 30px 30px;
		background-repeat: repeat;
		opacity: 0.3;
	}
`;
