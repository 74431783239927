/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import iconClose from '../assets/close-window-xxl.png';
import { Button } from '@material-ui/core';
import {
    Modal,
    CloseButton,
    ModalTitle,
    ButtonOk,
    UploadWrapper
} from './styles';

import { compressImage } from './uploadUtils';

export const validateSelfie = (data) => {
    const invalid  = (!data.picture);

    return !invalid;
}

export const getSelfieFormData = (data) => {
    const formData = new FormData();
    formData.append("picture", data.picture.content, data.picture.name);

    return formData;
}

const Selfie = ( { isOpen, onClose, doc, onOK, loading }) => {

    const [picture, setPicture] = useState();
    const [processingFile, setProcessingFile] = useState(false);

    const handleOnClose = () => {
        if (onClose) onClose(doc);
    }

    const handleOnOK = () => {
        const data = {
            picture
        }

        if (onOK) onOK(doc, data);
    }

    const handleOnChangePictureFile = async (e) => {
        let file = null;
        if (e.target.files && e.target.files.length > 0) {
            setProcessingFile(true);
            file = {content: e.target.files[0], name: e.target.files[0].name};
            file.content = await compressImage(file, true);
            const pos = file.name.lastIndexOf(".");
            file.name = file.name.substr(0, pos < 0 ? file.name.length : pos) + ".png";
            setProcessingFile(false);
        }
        setPicture(file);
    }

    const handleOnClickPictureFile = () => {
        document.getElementById('picture-selfie').click()
    }

    useEffect(() => {

    }, [])

    return (<Modal style={{display: isOpen ? 'block': 'none'}}>
        <ModalTitle>{doc.value}</ModalTitle>
        <CloseButton src={iconClose} onClick={handleOnClose} data-testid={"selfie-closeModal"} />
        <UploadWrapper>
            <Button variant="contained" color={!picture? "default" : "primary"} disabled={processingFile} onClick={handleOnClickPictureFile}>{processingFile? "Processando" : "Foto"}</Button>
            <input id="picture-selfie" type="file" onChange={handleOnChangePictureFile} accept="image/*" style={{display: 'none'}} />
        </UploadWrapper>
        <ButtonOk
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleOnOK}
            data-testid={"selfie-okModal"}
        >
            {loading? "Enviando" : "OK"}
        </ButtonOk>
    </Modal>);
};

Selfie.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  doc: {
    value: PropTypes.string,
  },
  onOK: PropTypes.func,
  loading: PropTypes.bool,
};

export default Selfie;
