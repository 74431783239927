import styled from "styled-components";
import { Button } from "@material-ui/core";

export const TabContent = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 32px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Content = styled.div`
	padding-bottom: 16px;
`;

export const TableWrapper = styled.div`
	border: 1px solid ${({ noBorder }) => (noBorder ? "transparent" : "#e0e0e0")};
	border-radius: 8px;
	padding: 8px 16px;
	height: fit-content;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Td = styled.div`
	padding: 2px 0;
	min-width: 270px;
	background-color: "green";
`;

export const TdLeft = styled.div`
	vertical-align: top;
	white-space: nowrap;
	padding-right: 16px;
	color: #666666;
`;

export const TableTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #031e52;
	padding-bottom: 24px;
`;
export const TeblaAlert = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #f50057;
	padding-bottom: 24px;
`;

export const EditPhone = styled.div`
	position: absolute;
	right: 8px;
	margin-left: 12px;
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	padding: 0 4px;
	border-radius: 5px;
	cursor: pointer;
	color: #3646a8;
	background-color: #f0f0f0;
	font-size: 12px;
	height: 20px;
`;

export const OkCancelWrapper = styled.div`
	display: flex;
	position: absolute;
	right: 8px;
	margin-top: 12px;
`;

export const Input = styled.input`
	width: 120px;
	border: 1px solid #e0e0e0;
	padding: 2px;
	border-radius: 4px;
`;

export const PhoneWrapper = styled.div`
	width: 100%;
`;

export const NotInformed = styled.div`
	color: #b0b0b0;
	font-size: 12px;
`;

export const Modal = styled.div`
	inset: 50% auto auto 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 500px;
	border: 1px solid rgb(153, 153, 153);
	position: fixed;
	z-index: 999;
	border-radius: 12px;
	box-shadow: rgb(0 0 0 / 20%) 3px 3px 5px 5px;
	padding: 15px;
`;

export const CloseButton = styled.img`
	height: 20px;
	position: absolute;
	right: 6px;
	top: 6px;
	cursor: pointer;
`;

export const ModalTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #031e52;
`;

export const ButtonOk = styled(Button)`
	float: right;
	margin-top: 10px;
	margin-left: 15px;
`;

export const ButtonCancell = styled(Button)`
	float: right;
	margin-top: 10px;
`;

export const TextWrapper = styled.div``;

export const Validation = styled.div`
	font-size: 10px;
	color: #f50057;
`;
