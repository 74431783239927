/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import iconClose from '../assets/close-window-xxl.png';
import { Button } from '@material-ui/core';
import { Modal, CloseButton, ModalTitle, ButtonOk, UploadWrapper, PayslipImage, WatermarkContainer } from './styles';

import { pdf2Image, compressImage } from './uploadUtils';

export const validatePayslip = (data) => {
	if (!data) {
		return false;
	}

	return !!data?.picture || data?.alreadyHasPicture;
};

export const getPayslipFormData = (data) => {
	const formData = new FormData();

	formData.append('picture', data.picture.content, data.picture.name);

	return formData;
};

const Payslip = ({ isOpen, onClose, doc, onOK, loading, data = null }) => {
	const [picture, setPicture] = useState();
	const [processingFile, setProcessingFile] = useState(false);
	const [currentPayslip, setCurrentPayslip] = useState(null);

	useEffect(() => {
		if (!data) {
			return;
		}

		const payslipURL = data?.picture?.[0]?.url;

		if (!payslipURL) {
			return;
		}

		setCurrentPayslip(payslipURL);
	}, [data]);

	const handleOnClose = () => {
		if (onClose) onClose(doc);
	};

	const handleOnOK = () => {
		const data = {
			picture,
		};

		if (onOK) onOK(doc, data);
	};

	const handleOnChangePictureFile = async (e) => {
		if (!e.target?.files || e.target?.files?.length <= 0) {
			setPicture(null);
		}

		const imageFile = { content: e.target.files[0], name: e.target.files[0].name };

		setProcessingFile(true);

		const image = await pdf2Image(imageFile, true, true);

		if (!image) {
			imageFile.content = await compressImage(imageFile, true);
			const pos = imageFile.name.lastIndexOf('.');
			imageFile.name = imageFile.name.substr(0, pos < 0 ? imageFile.name.length : pos) + '.png';
		} else {
			imageFile.content = image[0];
			imageFile.name = imageFile.name.toLowerCase().replace('.pdf', '.png');
		}

		setProcessingFile(false);
		setPicture(imageFile);
	};

	const handleOnClickPictureFile = () => {
		document.getElementById('picture-payslip').click();
	};

	useEffect(() => {}, []);

	return (
		<Modal style={{ display: isOpen ? 'block' : 'none' }} onContextMenu={(e) => e.preventDefault()}>
			<ModalTitle>{doc.value}</ModalTitle>
			<CloseButton src={iconClose} onClick={handleOnClose} data-testid={'payslip-closeModal'} />
			{currentPayslip ? (
				<WatermarkContainer>
					<PayslipImage src={currentPayslip} alt="Comprovante" />
				</WatermarkContainer>
			) : (
				<>
					<UploadWrapper>
						<Button
							variant="contained"
							color={!picture ? 'default' : 'primary'}
							disabled={processingFile}
							onClick={handleOnClickPictureFile}
						>
							{processingFile ? 'Processando' : 'Comprovante'}
						</Button>
						<input
							id="picture-payslip"
							data-testid="picture-payslip"
							type="file"
							onChange={handleOnChangePictureFile}
							accept="image/*,application/pdf"
							style={{ display: 'none' }}
						/>
					</UploadWrapper>
					<ButtonOk disabled={loading} variant="contained" color="primary" onClick={handleOnOK} data-testid={'payslip-okModal'}>
						{loading ? 'Enviando' : 'OK'}
					</ButtonOk>
				</>
			)}
		</Modal>
	);
};

Payslip.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  doc: {
    value: PropTypes.string,
  },
  onOK: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
};

export default Payslip;