import React, { useContext, useEffect, useState, useCallback } from "react";
import Alert, { ALERT_TYPES } from "components/Alert";
import { Container, CollapseButton, Collapsible, MaxOfferCard, RefreshButton } from "./styles";
import getMaxOfferInformation from "api/alicore/getMaxOfferInformation";
import generateSimplifiedOffer from "api/alicore/generateSimplifiedOffer";
import { ClientContext } from "contexts/ClientProvider";
import { currencyToString } from "helpers/formaters";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { SnackbarContext } from "contexts/SnackbarProvider";
import { useHistory } from 'react-router-dom';

function UserMaxLimits() {
	const [client] = useContext(ClientContext);
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [maxOfferData, setMaxOfferData] = useState(null);
  const [showSnackbar] = useContext(SnackbarContext);
  const history = useHistory();

	const toggleOpen = useCallback(() => setOpen(!open), [open]);
	const loadMaxOfferInformation = useCallback(async () => {
		setLoading(true);
		await getMaxOfferInformation(client?.customer?.id).then(setMaxOfferData);
		setLoading(false);
	}, [client?.customer?.id]);

	const refreshPage = useCallback(async () => {
		try {
			setLoading(true);

			await generateSimplifiedOffer({
				nationalDocument: client?.user?.document,
				forceUpdate: true,
			});

      history.replace('/user');
			history.go();
		} catch (error) {
      showSnackbar({
        message: "Houve algum problema. Tente novamente e caso não funcione contate o suporte.",
        severity: "error",
        duration: 6000,
      })
			console.log({
				error,
			});
		} finally {
			setLoading(false);
		}
	}, [client?.user?.document, client?.customer?.id]);

	useEffect(() => {
		(async () => {
			await loadMaxOfferInformation();
		})();
	}, [loadMaxOfferInformation]);

	return (
		<Container>
			<Alert visible type={ALERT_TYPES.WARNING}>
				{loading && <p className="loading">Carregando detalhes...</p>}
				{!loading && maxOfferData && (
					<>
						<h4>
							<strong>Informações gerais:</strong>
						</h4>

						<p>+ Limite máximo Ali: {currencyToString(maxOfferData.offersMax.limitAli)}</p>
						<p>- Contratos Ativos: {currencyToString(maxOfferData.offersMax.limitUtilized)}</p>
						<p>= Limite Disponível: {currencyToString(maxOfferData.offersMax.limitFree)}</p>
						<br />
						<p>Salário utilizado: {maxOfferData.salary ? currencyToString(maxOfferData.salary) : "Nulo"}</p>
						<p>Margem consignável utilizada: {maxOfferData.salaryMargin ? currencyToString(maxOfferData.salaryMargin) : "Nulo"}</p>
						<CollapseButton onClick={toggleOpen} data-testid="toggle-button">
							Informações detalhadas <ArrowDropDown className="collapse-icon" />
						</CollapseButton>
						<Collapsible className={`collapsible ${open ? "open" : ""}`}>
							{maxOfferData.offers.map((offer, index) => (
								<MaxOfferCard key={String(offer.limitAli) + String(offer.salary)}>
									<h5>
										Política {index + 1}
										{offer.name && `: ${offer.name}`}
									</h5>

									{offer.description && (
										<p className="error">
											<strong>{offer.description}</strong>
										</p>
									)}
									<h6>Limites Máximos</h6>
									<p>
										Ali: <span>{currencyToString(offer.limitAli)}</span>
									</p>
									<p>
										Livre: <span>{currencyToString(offer.limitFree)}</span>
									</p>
									<p>
										Novo Contrato: <span>{currencyToString(offer.maxLimitNewContract)}</span>
									</p>
									<p>
										Portabilidade: <span>{currencyToString(offer.maxLimitPortability)}</span>
									</p>
									<p>
										Refinanciamento: <span>{currencyToString(offer.maxRefin)}</span>
									</p>
									<p>
										Troco para refinanciamento: <span> {currencyToString(offer.maxRefinChange)}</span>
									</p>
									<p>
										Troca de dívidas: <span>{currencyToString(offer.maxDebtChanging)}</span>
									</p>
									<p>
										Economizômetro: <span>{currencyToString(offer.maxEconomizometro)}</span>
									</p>
								</MaxOfferCard>
							))}
						</Collapsible>
					</>
				)}
				<RefreshButton type="button" onClick={refreshPage}>
					Atualização de Ofertas
				</RefreshButton>
			</Alert>
		</Container>
	);
}

export default UserMaxLimits;
