import PropTypes from 'prop-types';

const Loading = ({ text }) => (
  <div
    style={{
      position: 'absolute',
      top: '0px',
      left: '0px',
      borderRadius: '12px',
      width: '100%',
      height: '100%',
      zIndex: 1,
      backgroundColor: 'rgb(255 255 255 / 80%)',
    }}
  >
    <div
      style={{
        fontSize: 'large',
        color: '#1a62ef',
        display: 'flex',
        paddingTop: '50%',
        justifyContent: 'center',
      }}
    >
      {text}
    </div>
  </div>
);

Loading.propTypes = {
  text: PropTypes.string,
};

export default Loading;