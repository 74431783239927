import styled from 'styled-components';

export const Container = styled.div`
  max-width: 680px;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  font-size: 14px;
  border: 1px solid ${({ noBorder }) => (noBorder ? 'transparent' : '#e0e0e0')};
  border-radius: 8px;
  padding: 16px 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 60%;
`;

export const TableWrapper = styled.div`
  border: 1px solid ${({ noBorder }) => (noBorder ? 'transparent' : '#e0e0e0')};
  border-radius: 8px;
  padding: 8px 16px;
  height: fit-content;
`;

export const LoadTag = styled.div`
  margin-top: 8px;
  color: #d0d0d0;
  font-size: 12px;
  text-align: center;
`;

export const Description = styled.div`
  display: flex;
  vertical-align: top;
  padding-bottom: 8px;
  color: #666666;
`;

export const Value = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-left: 8px;
  color: black;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;
