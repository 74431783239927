import { useEffect, useState } from 'react';
import {
	INSTALLMENTS_MULTIPLICATIONS_VALUES
} from 'helpers/constants';
import { currencyToString } from 'helpers/formaters';
import { getMultiplierByDiff } from 'utils';
import PropTypes from 'prop-types';

import * as S from './styles';

export const InstallmentsOptions = ({ installmentOptions }) => {
	const [installmentsToShow, setInstallmentsToShow] = useState([]);

  useEffect(() => {
    if (!installmentOptions?.length) {
      return;
    }
    
    const minInstallment = installmentOptions?.[0]?.numberOfPayableInstallments;
    const maxInstallment = installmentOptions?.[installmentOptions.length - 1]?.numberOfPayableInstallments;

    if (!Number.isInteger(minInstallment) || !Number.isInteger(maxInstallment)) {
      return;
    }

    const difference = maxInstallment - minInstallment;
    const multiplier = getMultiplierByDiff(difference);

    if (multiplier === -1) {
      return;
    }

    const listOfValidInstallmentsNumber = INSTALLMENTS_MULTIPLICATIONS_VALUES.map((value) => value * multiplier);

    const installments = installmentOptions.filter((installment, index) => {
      if (index === 0 || index === installmentOptions.length - 1) {
        return true;
      }

      return listOfValidInstallmentsNumber.includes(installment.numberOfPayableInstallments);
    })

    setInstallmentsToShow(installments);
  }, [installmentOptions])

	return (
		<S.ItemsContainer>
			{installmentsToShow.map((installment) => (
				<S.Item key={installment.numberOfPayableInstallments}>
					<p data-testid="installment">{`${installment.numberOfPayableInstallments}x de ${currencyToString(installment.installmentAmount)}`}</p>
				</S.Item>
			))}
		</S.ItemsContainer>
	);
};

InstallmentsOptions.propTypes = {
  installmentOptions: PropTypes.arrayOf(
		PropTypes.shape({
			numberOfPayableInstallments: PropTypes.number,
			installmentAmount: PropTypes.number,
		})
	),
}