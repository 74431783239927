import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
`;
