const isValid = (fullName) => {
  const names = fullName.trim().split(" ");

  if (names.length < 2) {
    return false;
  }

  for (const name of names) {
    if (name.length < 2) {
      return false;
    }
  }

  return true;
};

export default isValid;
