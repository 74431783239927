import styled from "styled-components";

export const AlertContainer = styled.div`
	position: relative;
	padding: 0.5rem 1rem;
	margin-bottom: 1rem;
	overflow: hidden;
	margin-top: 1rem;
	z-index: 1;
	&::after,
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 0.25rem;
		box-sizing: border-box;
		z-index: -1;
	}
	&::before {
		background-color: currentColor;
		opacity: 0.15;
	}
	&::after {
		border: 1px solid currentColor;
		opacity: 0.3;
	}
	&.error {
		color: #842029;
	}

	&.success {
		color: #0f5132;
	}

	&.warning {
		color: #664d03;
	}
	&.primary {
		color: #084298;
	}

	&.secondary {
		color: #41464b;
	}

	&.info {
		color: #055160;
	}
	&.hide {
		display: none;
	}
`;

export const CloseButton = styled.span`
	margin-left: 15px;
	color: currentColor;
	float: right;
	font-size: 1.5rem;
	line-height: 1.25rem;
	cursor: pointer;
	transition: 0.3s;
	&:hover {
		color: black;
	}
`;
