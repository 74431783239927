import styled from 'styled-components';

export const History = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #0043ce;
	border-radius: 10px;
	overflow: hidden;
	background: #0f62fe;

	header {
		background: #0f62fe;
		border-radius: 10px 10px 0 0;
		padding: 20px 15px;
		display: flex;
		align-items: center;

		h2 {
			font-weight: 500;
			font-size: 18px;
			line-height: 20px;
			color: #ffffff;
			margin: 0;
		}
	}
`;

export const HistoryInformation = styled.section`
	padding: 16px 8px 0 8px;
	background: #fcfcfd;

	p {
		margin-bottom: 8px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 0.5px solid #7e7e7e;

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 18px;
	}

	p {
		margin: 0;
		display: block;
		line-height: 16px;
	}
`;

export const ContentValue = styled.p`
	min-width: 100px;
	text-align: left;
`;

export const HistoryFooter = styled.footer`
	padding: 12px 0 16px 0;
	display: flex;
	flex-direction: column;
	background: #fcfcfd;

	p {
		font-weight: 600;
		font-size: 11px;
		line-height: 12px;
		text-align: center;
		margin: 0 0 4px 0;
	}

	h5 {
		font-weight: 900;
		font-size: 16px;
		line-height: 18px;
		text-align: center;
		color: #1e1e1e;
		margin: 0;
	}
`;
