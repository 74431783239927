import { API } from 'config';
import axios from 'axios';

const shoppingCartDeal = async (id, amount, installmentsQuantity) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');

	const response = await axios
		.post(
			`${API.SHOPPING_CART_DEAL}${id}`,
			{
				amount,
				installmentsQuantity,
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
		.catch((e) => {});

	if (!response?.data) {
		return null;
	}

	return response.data;
};

export default shoppingCartDeal;
