import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { clearUserData } from "auth/user";
import { Container, Content } from "./styles";
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { LoadingBar } from "_FUTURE_LIBS_/@ali-ui";

const Login = () => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		clearUserData();
	}, []);

	return (
		<Container>
			<Header noLogout />
			<Content>
				<div>
					{loading && <LoadingBar />}
					<UnauthenticatedTemplate>{!loading && <SignInButton setLoading={setLoading} />}</UnauthenticatedTemplate>
					<AuthenticatedTemplate>{!loading && <SignOutButton />}</AuthenticatedTemplate>
				</div>
			</Content>
		</Container>
	);
};

export default Login;
