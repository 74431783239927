import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isInvalid, { isPartialInvalid, ERRORS_DATE } from './validation';

const DateField = ({
  label,
  placeholder,
  onChange,
  value,
  noValidation,
  minAge,
  errorMinAge,
  errorInvalid,
  disabled,
  'data-testid': dataTestid,
}) => {
  const [error, setError] = useState(false);

  const ERROR_MSGS = {
    [ERRORS_DATE.INVALID]: errorInvalid,
    [ERRORS_DATE.MIN_AGE]: errorMinAge,
  };

  const handleOnChange = (date) => {
    if (!noValidation) {
      if (date.length >= 10) {
        const invalidMsg = isInvalid(date, minAge);
        setError(invalidMsg);
        onChange(date);
        return;
      }
      setError(isPartialInvalid(date));
    }
    onChange(date);
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && isInvalid(value, minAge);
      setError(invalid);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  DateField.isValid = () => (value !== '' && !isInvalid(value, minAge));

  return (
    <TextField
      data-testid={dataTestid}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleOnChange}
      value={value}
      inputMode="decimal"
      format={{
        delimiters: ['/', '/'],
        blocks: [2, 2, 4],
        numericOnly: true,
        delimiterLazyShow: true,
      }}
      error={error && ERROR_MSGS[error]}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    />
  );
};

/* istanbul ignore next */
DateField.defaultProps = {
  label: 'Data',
  placeholder: '',
  onChange: () => {},
  value: '',
  noValidation: false,
  minAge: null,
  disabled: false,
  errorMinAge: 'Abaixo da idade mínima',
  errorInvalid: 'Data inválida',
  'data-testid': 'field-date',
};

DateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
  minAge: PropTypes.number,
  errorMinAge: PropTypes.string,
  errorInvalid: PropTypes.string,
  disabled: PropTypes.bool,
  'data-testid': PropTypes.string,
};

export default DateField;
