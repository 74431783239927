import styled from 'styled-components';

export const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 9px;
`;

export const Item = styled.div`
	background: #dbe5ff;
	border: 1px solid #0f62fe;
	border-radius: 5px;

	p {
		font-weight: 600;
		font-size: 15px;
		line-height: 17px;
		text-align: center;
		color: #0f62fe;
	}
`;
