import React, { useState, useContext, useEffect, useRef } from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { LoadTag, ErrorText } from "./styles";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { ClientContext } from "contexts/ClientProvider";

import { CurrencyField } from "_FUTURE_LIBS_/@ali-forms";
import { Spacer } from "_FUTURE_LIBS_/@ali-ui";

import getProducts from "api/alicore/getProducts";
import getDebts from "api/alicore/getDebts";
import getExternalDebts from "api/alicore/getExternalDebts";
import { Radio } from "@material-ui/core";
import Alert, { ALERT_TYPES } from "components/Alert";
import PropTypes from "prop-types";

const DropdownButton = ({ loading, onClick }) => {
	const [loadingProducts, setLoadingProducts] = useState(true);
	const [productOptions, setProductOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [debtInstallmentAmount, setDebtInstallmentAmount] = useState();
	const [askForDebtInstallmentAmount, setAskForDebtInstallmentAmount] = useState(false);
	const [askForDebtSelection, setAskForDebtSelection] = useState(false);
	const [errors, setErrors] = useState([]);
	const [selectedDebts, setSelectedDebts] = useState([]);
	const [debtOptions, setDebtOptions] = useState([]);
	const [portabilityDebtOptions, setPortabilityDebtOptions] = useState([]);
	const [loadingPortabilityDebtOptions, setLoadingPortabilityDebtOptions] = useState(false);
	const [client] = useContext(ClientContext);

	const handleClick = () => {
		if (errors.length > 0 || !productOptions[selectedIndex]) {
			return;
		}
		if (productOptions[selectedIndex].requireDebtInstallmentAmount) {
			if (!debtInstallmentAmount) {
				return handleMenuItemClick(null, selectedIndex);
			}
			const debtInstallmentAmountFloat = parseFloat(debtInstallmentAmount?.replace("R$", "").replace(".", "").replace(",", ".").trim());
      
      if (portabilityDebtOptions.length > 0) {
        const debt = portabilityDebtOptions.find((d) => d.installmentAmount === debtInstallmentAmountFloat);
        if (!debt) return handleMenuItemClick(null, selectedIndex);

        onClick(productOptions[selectedIndex].internalName, debtInstallmentAmountFloat, 0, debt);
      } else {
        onClick(productOptions[selectedIndex].internalName, debtInstallmentAmountFloat);
      }
		} else if (productOptions[selectedIndex].requireDebtSelection) {
			const debts = debtOptions.filter((el) => {
				return selectedDebts.some((f) => {
					return f.titleNumber === el.titleNumber && f.checked;
				});
			});
			const marginSum = debts.reduce((a, b) => +a + +b.nominalValue, 0);
			const debtToRefin = debts.reduce((a, b) => +a + +b.debitBalance, 0);
			onClick(productOptions[selectedIndex].internalName, marginSum, debtToRefin, debts);
		} else {
			onClick(productOptions[selectedIndex].internalName);
		}
	};

	const onChangeDebtInstallmentAmount = (value) => {
		setDebtInstallmentAmount(value);
	};
	const onBlurDebtInstallmentAmount = (value) => {
		setDebtInstallmentAmount(value);
	};

	const handleMenuItemClick = (event, index) => {
		setDebtInstallmentAmount();
		setSelectedIndex(index);
		setErrors([]);
		setAskForDebtInstallmentAmount(productOptions[index].requireDebtInstallmentAmount);
		setAskForDebtSelection(productOptions[index].requireDebtSelection);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef?.current?.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const checkErrors = () => {
		const errorsList = [];
		if (askForDebtInstallmentAmount) {
			if (!debtInstallmentAmount || debtInstallmentAmount.trim() == "" || debtInstallmentAmount == "R$") {
				errorsList.push("Valor da parcela é obrigatório.");
			}

			if (parseFloat(debtInstallmentAmount?.replace("R$", "").replace(".", "").replace(",", ".").trim()) <= 0) {
				errorsList.push("Valor da parcela deve ser maior que R$ 0,00.");
			}
		}

		if (askForDebtSelection) {
			if (selectedDebts.length === 0) {
				errorsList.push("Favor selecionar pelo menos um dos débitos para refinanciar.");
			}
		}
		setErrors(errorsList);
	};

	const handleChangeDebt = (event) => {
		let selectedDebtsBefore = [...selectedDebts];
		selectedDebtsBefore = selectedDebtsBefore.filter((d) => d.titleNumber != event.target.name);
		if (event.target.checked) selectedDebtsBefore.push({ checked: event.target.checked, titleNumber: event.target.name });
		setSelectedDebts(selectedDebtsBefore);
	};

	useEffect(async () => {
		if (productOptions.length <= 0) {
			setLoadingProducts(true);
			let products = await getProducts(client?.user?.document);
			setProductOptions(products);
			setLoadingProducts(false);
		}
		if (askForDebtSelection && debtOptions.length === 0) {
			let debts;
			try {
				debts = await getDebts(client.user.id);
				if (!debts) {
					debts = [];
				}
			} catch (e) {
				debts = [];
			} finally {
				setDebtOptions(debts);
				checkErrors();
			}
		}
	}, [askForDebtSelection]);

	useEffect(() => {
		if (askForDebtInstallmentAmount) checkErrors();
		if (askForDebtSelection) checkErrors();
	}, [askForDebtInstallmentAmount, askForDebtSelection, debtInstallmentAmount, debtOptions, selectedDebts]);

	useEffect(() => {
		if (askForDebtInstallmentAmount) {
			setLoadingPortabilityDebtOptions(true);
			getExternalDebts(client.user.id)
				.then((res) => {
					setPortabilityDebtOptions(res.data);
				})
				.finally(() => {
					setLoadingPortabilityDebtOptions(false);
				});
		}
	}, [askForDebtInstallmentAmount]);

	return (
		<Grid container direction="column" alignItems="center">
			<Grid item xs={12}>
				{!productOptions?.length && !loadingProducts && (
					<Alert visible type={ALERT_TYPES.WARNING}>
						No momento, não existem ofertas e contratos disponíveis para esse cliente.
					</Alert>
				)}
				{loadingProducts && <LoadTag>Carregando produtos...</LoadTag>}
				{!!productOptions?.length && (
					<ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
						<Button onClick={handleClick} data-testid="add-button" disabled={errors.length > 0}>
							<AddIcon />
							<b>{productOptions[selectedIndex]?.friendlyName}</b>
						</Button>
						<Button
							color="primary"
							size="small"
							aria-controls={open ? "split-button-menu" : undefined}
							aria-expanded={open ? "true" : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							onClick={handleToggle}
							data-testid="toggle-button"
						>
							<ArrowDropDownIcon />
						</Button>
					</ButtonGroup>
				)}
				{loading && <LoadTag>Criando nova proposta...</LoadTag>}
				<Popper open={open} anchorEl={anchorRef.current} role={undefined} style={{ zIndex: 999 }} transition disablePortal>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === "bottom" ? "center top" : "center bottom",
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu">
										{productOptions?.map((product, index) => (
											<MenuItem key={product.id} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
												{product.friendlyName}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
				{askForDebtInstallmentAmount ? (
					<>
						<Spacer height={10} />
						<FormControl component="fieldset">
							{loadingPortabilityDebtOptions && <LoadTag>Carregando ofertas...</LoadTag>}
							{portabilityDebtOptions?.map((debt) => (
								<FormControlLabel
									key={debt.friendlyName}
									control={
										<Radio
											color="primary"
											data-testid={"radio-" + debt.friendlyName}
											checked={debt.installmentAmount.toLocaleString("pt-br") === debtInstallmentAmount}
											onChange={() => onChangeDebtInstallmentAmount(debt.installmentAmount.toLocaleString("pt-br"))}
											name={debt.institution}
										/>
									}
									style={{ margin: "5px 0px", paddingBottom: "0px" }}
									label={
										<Card>
											<CardContent>
												<Typography variant="body1" display="block">
													{`Data: ${debt.institution || debt.friendlyName}`}
												</Typography>
												<Typography variant="caption" display="block">
													{`Divida restante: ${parseFloat(debt.curveAmount).toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}`}
												</Typography>
												<Typography variant="caption" display="block">
													{`Parcela: ${parseFloat(debt.installmentAmount).toLocaleString("pt-br", {
														style: "currency",
														currency: "BRL",
													})}`}
												</Typography>
												<Typography variant="caption" display="block">
													{`Parcelas restantes: ${debt.openedInstallments}`}
												</Typography>
											</CardContent>
										</Card>
									}
								/>
							))}
						</FormControl>
						<Spacer height={10} />
						{!loadingPortabilityDebtOptions && !portabilityDebtOptions.length && (
							<CurrencyField
								id="debt-installment-amount"
								label="Valor de parcela da dívida"
								value={debtInstallmentAmount}
								onChange={onChangeDebtInstallmentAmount}
								onBlur={onBlurDebtInstallmentAmount}
								fullWidth
								disabled={loading}
							/>
						)}
					</>
				) : (
					""
				)}
				{askForDebtSelection ? (
					<>
						<Spacer height={10} />
						<FormControl component="fieldset">
							<FormLabel component="legend">
								{debtOptions.length <= 0 ? "Não há débitos refinanciáveis." : "Débitos refinanciáveis:"}
							</FormLabel>
							<FormGroup>
								{debtOptions?.map((debt) => (
									<FormControlLabel
										key={debt.titleNumber}
										control={<Checkbox color="primary" onChange={handleChangeDebt} name={debt.titleNumber} />}
										style={{ margin: "5px 0px", paddingBottom: "0px" }}
										label={
											<Card>
												<CardContent>
													<Typography variant="body1" display="block">
														{`Data: ${new Date(debt.acquisitionDate).toLocaleDateString("pt-BR", { timeZone: "UTC" })}`}
													</Typography>
													<Typography variant="caption" display="block">
														{`Saldo: ${parseFloat(debt.debitBalance).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`}
													</Typography>
													<Typography variant="caption" display="block">
														{`Parcela: ${parseFloat(debt.nominalValue).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}`}
													</Typography>
												</CardContent>
											</Card>
										}
									/>
								))}
							</FormGroup>
						</FormControl>
					</>
				) : (
					""
				)}
				{errors.map((error) => (
					<ErrorText key={error}> {error} </ErrorText>
				))}
			</Grid>
		</Grid>
	);
};

DropdownButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
}

export default DropdownButton;
