import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Header from "components/Header";
import { Button } from "@material-ui/core";
import CPFField from "_FUTURE_LIBS_/@ali-forms/FormFields/CPFField";
import isValidCPF from "_FUTURE_LIBS_/@ali-forms/FormFields/CPFField/validation";

import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";

import loginAdmin from "api/alicore/loginAdmin";
import getCustomer from "api/alicore/getCustomer";

import { Container, Content, Loading } from "./styles";

const Home = () => {
  const [showSnackbar] = useContext(SnackbarContext);
  const [, setClient] = useContext(ClientContext);

  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);

  const [cpf, setCpf] = useState("");
  const [isValid, setIsValid] = useState();

  const history = useHistory();

  useEffect(async () => {
    const result = await loginAdmin();
    if (result) {
      setLogged(result);
      const nationalDocument = sessionStorage.getItem("ALI_REGISTER_CUSTOMER");
      if (nationalDocument) {
        setCpf(nationalDocument);
        handleOnChange(nationalDocument);
        sessionStorage.removeItem("ALI_REGISTER_CUSTOMER");
      }

      setLoading(false);
    } else {
      setLoading(false);
      showSnackbar({
        message: `Falha na autenticação`,
        severity: "error",
      });
    }
  }, []);

  const handleOnChange = (value) => {
    setIsValid(isValidCPF(value));
    setCpf(value);
  };

  const handleSearch = async () => {
    if (!isValid) return;

    setLoading(true);
    const result = await getCustomer(cpf);
    if (!result || result?.invalidNetwork) {
      setLoading(false);
      showSnackbar({
        message: `Ocorreu um problema de rede e não foi possível acessar os dados desse cliente. Verifique a internet do seu computador e tente novamente. Caso não funcione, contate o suporte de infraestrutura.`,
        severity: "error",
        duration: 10000,
      });
      return;
    }

    if (result && result.goTo == "home") {
      sessionStorage.setItem("HAS_SELFIE_BIOMETRY_INTEGRATION", result.customer.hasSelfieBiometryIntegration)
      sessionStorage.setItem("ALI_CURRENT_CUSTOMER", cpf);
      setClient(result.customer);
      history.replace("/user");
    } else if (result && result.goTo == "signup") {
      sessionStorage.setItem("ALI_REGISTER_CUSTOMER", cpf);
      showSnackbar({
        message: `O CPF informado não foi encontrado na base. É necessário realizar o cadastro básico do usuário para que possamos tentar identificar o seu convênio.`,
        severity: "warning",
        duration: 10000,
      });
      history.replace("/register");
    }
  };

  return (
    <Container>
      <Header />
      <Content>
        {loading && <Loading />}
        {!loading && logged && (
          <>
            <CPFField
              label="CPF do Cliente"
              placeholder=""
              value={cpf}
              onChange={handleOnChange}
              onEnterPressed={handleSearch}
              disabled={loading}
              autoFocus
            />
            <Button variant="contained" color="primary" disabled={loading || !isValid} onClick={handleSearch}>
              Pesquisar
            </Button>
          </>
        )}
      </Content>
    </Container>
  );
};

export default Home;
