/* eslint-disable */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import iconClose from '../assets/close-window-xxl.png';
import {
    StreetField,
    StreetNumberField,
    StreetComplementField,
    CepField,
    StreetNeighborhoodField,
    StreetCityField,
} from '_FUTURE_LIBS_/@ali-forms';
import { DropDownSearchable, Select } from '_FUTURE_LIBS_/@ali-ui';
import { Button } from '@material-ui/core';
import {
    Modal,
    CloseButton,
    ModalTitle,
    ButtonOk,
    TextWrapper,
    UploadWrapper,
    DropDownWrapper
} from './styles';
import { pdf2Image, compressImage } from './uploadUtils';
import ufs from './ufs.json';
import searchAddressByCEP from '../../../api/alicore/searchAddressByCEP';
import { SnackbarContext } from 'contexts/SnackbarProvider';
import Loading from './Loading';

export const validatePostalInformation = (data) => {
	if (!data) return false;

	const validPicture = data.alreadyHasPicture || data.picture;

	let invalid =
		data.zipCode === "00000-000" ||
		!data.street ||
		!data.number ||
		!(data.district || data.neighborhood) ||
		!data.city ||
		!data.state ||
		!data.zipCode;

	if (!invalid) {
		invalid = !validPicture;
	}

	return !invalid;
};

export const getPostalInformationFormData = (data) => {
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("street", data.street);
    formData.append("number", data.number);
    formData.append("complement", data.complement);
    formData.append("zipCode", data.zipCode);
    formData.append("neighborhood", data.neighborhood);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("picture", data.picture.content, data.picture.name);

    return formData;
}

const PostalInformation = ( { isOpen, onClose, doc, onOK, loading }) => {
    const [showSnackbar] = useContext(SnackbarContext);

    const [loadingCep, setLoadingCep] = useState(false);
    const [type, setType] = useState();
    const [street, setStreet] = useState();
    const [number, setNumber] = useState();
    const [complement, setComplement] = useState();
    const [zipCode, setZipCode] = useState();
    const [neighborhood, setNeighborhood] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [picture, setPicture] = useState();
    const [processingFile, setProcessingFile] = useState(false);

    const ufList = ufs.items

    const HOME = 'Home';
    const PAID_HOME = 'PaidHome';
    const FINANCED_HOME = 'FinancedHome';
    const RENTED_HOME = 'RentedHome';
    const types = [
        { text: "Casa de Família", value: HOME },
        { text: "Própria Quitada", value: PAID_HOME },
        { text: "Própria Financiada", value: FINANCED_HOME },
        { text: "Alugado", value: RENTED_HOME }
    ];

    const handleOnClose = () => {
        if (onClose) onClose(doc);
    }

    const handleOnOK = () => {
        const data = {
            type,
            street,
            number,
            complement,
            zipCode,
            neighborhood,
            city,
            state,
            picture
        }

        if (onOK) onOK(doc, data);
    }

    const handleOnChangeType = async (value) => {
        setType(value);
    }

    const handleOnChangeStreet = async (value) => {
        setStreet(value);
    };

    const handleOnChangeNumber = async (value) => {
        setNumber(value);
    }

    const handleOnChangeComplement = async (value) => {
        setComplement(value);
    }

    const handleOnChangeZipCode = async (value) => {
        setZipCode(value);
    }

    const handleOnChangeNeighborhood = async (value) => {
        setNeighborhood(value);
    }

    const handleOnChangeCity = async (value) => {
        setCity(value);
    }

    const handleOnChangeState = async (value) => {
        if (!value.target.innerText) {
            setState('')
            return;
        }

        const selectedUf = value.target.innerText.substr(0, 2);
        const uf = ufList.find(i => i.code === selectedUf);
        
        if (uf) { 
            setState(uf.code) 
        } else {
            setState('')
        }
    }

    const handleOnChangePictureFile = async (e) => {
        let file = null;
        if (e.target.files && e.target.files.length > 0) {
            file = {content: e.target.files[0], name: e.target.files[0].name};
            setProcessingFile(true);
            const pdfImage = await pdf2Image(file, true, true);
            if (pdfImage) {
                file.content = pdfImage[0];
                file.name = file.name.toLowerCase().replace('.pdf', '.png');
            } else {
                file.content = await compressImage(file, true);
                const pos = file.name.lastIndexOf(".");
                file.name = file.name.substr(0, pos < 0 ? file.name.length : pos) + ".png";
            }
            setProcessingFile(false);
        }
        setPicture(file);
    }

    const handleOnClickPictureFile = () => {
        document.getElementById('picture-postal-information').click()
    }

    const searchCEP = async (postalCode) => {
        setLoadingCep(true);
        const response = await searchAddressByCEP(postalCode);
        if (response) {
            if (response.cep) setZipCode(response.cep);
            if (response.city) setCity(response.city);
            if (response.neighborhood) setNeighborhood(response.neighborhood);
            if (response.state) setState(response.state);
            if (response.street) setStreet(response.street);
        } else {
            showSnackbar({
                message: 'Não consegui encontrar esse CEP, preciso que você digite os campos manualmente.',
                severity: 'error',
                duration: 6000,
            });
        }
        setLoadingCep(false);
    };

    return (
    <Modal style={{display: isOpen ? 'block': 'none'}}>
        {loadingCep && <Loading text="Carregando dados do CEP..."/>}
        <ModalTitle>{doc.value}</ModalTitle>
        <CloseButton src={iconClose} onClick={handleOnClose} data-testid={"postalInformation-closeModal"} />
        <div>
        <Select
            label="Tipo"
            options={types}
            value={type}
            onChange={handleOnChangeType}
            fullWidth
            />
        <TextWrapper>
            <CepField
                label="CEP"
                placeholder=""
                value={zipCode}
                onChange={handleOnChangeZipCode}
                searchCEP={searchCEP}
            />
        </TextWrapper>
        <TextWrapper>
            <StreetField 
                label="Logradouro"
                placeholder=""
                value={street}
                onChange={handleOnChangeStreet}
                />
        </TextWrapper>
        <TextWrapper>
            <StreetNumberField 
                label="Número"
                placeholder=""
                value={number}
                onChange={handleOnChangeNumber}
                />
        </TextWrapper>
        <TextWrapper>
            <StreetComplementField 
                label="Complemento"
                placeholder=""
                value={complement}
                onChange={handleOnChangeComplement}
                />
        </TextWrapper>
        <TextWrapper>
            <StreetNeighborhoodField 
                label="Bairro"
                placeholder=""
                value={neighborhood}
                onChange={handleOnChangeNeighborhood}
                />
        </TextWrapper>
        <TextWrapper>
            <StreetCityField 
                label="Cidade"
                placeholder=""
                value={city}
                onChange={handleOnChangeCity}
                />
        </TextWrapper>
        <DropDownWrapper>
            <DropDownSearchable
                label="Estado"
                placeholder=""
                value={state}
                options={ufList.sort((a, b) => -b.name.localeCompare(a.name[0]))}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                onChange={handleOnChangeState}
                onSelect={handleOnChangeState}
                maxVisibleLines={5}
                fullWidth
            />
        </DropDownWrapper>
        </div>
        <UploadWrapper>
            <Button variant="contained" color={!picture? "default" : "primary"} disabled={processingFile} onClick={handleOnClickPictureFile}>{processingFile? "Processando" : "Comprovante"}</Button>
            <input id="picture-postal-information" type="file" onChange={handleOnChangePictureFile} accept="image/*,application/pdf" style={{display: 'none'}} />
        </UploadWrapper>
        <ButtonOk
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleOnOK}
            data-testid={"postalInformation-okModal"}
        >
            {loading? "Enviando" : "OK"}
        </ButtonOk>
    </Modal>);
};

PostalInformation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  doc: {
    value: PropTypes.string,
  },
  onOK: PropTypes.func,
  loading: PropTypes.bool,
};

export default PostalInformation;