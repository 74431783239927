import React, { useCallback, useContext, useEffect, useState } from "react";
import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";
import { formatDisplayDate, formatDisplayPhone, formatDisplayCpf, dataValidation } from "utils";
import { TableWrapper, Row, Td, TdLeft, TableTitle, NotInformed, Validation } from "./styles";

import UserEdit, { validateUserEdition } from "./UserEdit";
import EditButton from "../EditButton";
import ThreePointsMenu from "./ThreePointsMenu";
import ModalIrReport from "./ModalIRReport";

import updateCustomer from "api/alicore/updateCustomer";
import updateHrRestriction from "api/alicore/updateHrRestriction";
import getAgreementDetail from "api/alicore/getAgreementDetail";
import generateIRReportPDF from "api/alicore/generateIRReportPDF";
import PropTypes from 'prop-types';

const UserRecord = ({ onUpdateData }) => {
  const [client, setClient] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);

  const [editIsOpen, setEditIsOpen] = useState(false);
  const [iRReportIsOpen, setIRReportOpen] = useState(false);
  const [yearIRReport, setYearIRReport] = useState([]);
  const [yearIRReportSelected, setYearIRReportSelected] = useState();
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataRestriction, setDataRestriction] = useState([]);

  const { id, name, birthDate, phone, email } = client.user;

  const dataUser = [
    [1, "Nome:", `${name}`],
    [2, "CPF:", formatDisplayCpf(client.user.document)],
    [3, "Data nascimento:", formatDisplayDate(birthDate)],
    [4, "Telefone:", formatDisplayPhone(phone)],
    [5, "E-mail:", email],
  ];

  const handleEditOnClick = () => {
    setEditIsOpen(true);
  };

  const handleEditOnClose = () => {
    setEditIsOpen(false);
  };

  const handleIRReportOnClick = () => {
    setIRReportOpen(true);
  };

  const handleIRReportOnClose = () => {
    setIRReportOpen(false);
  };

  const getData = useCallback(async () => {
    if (client.agreement) {
      const result = await getAgreementDetail(client.user.document);
      if (result && !result.isDisabled) {
        setDataRestriction({ id: result?.id, hrRestriction: result?.hrRestriction });
      } else if (!result || result.error) {
        return;
      }
      setClient({
        ...client,
        agreement: { ...client.agreement, hrRestriction: result?.hrRestriction },
        riskAuditMessage: result.riskAuditMessage,
      });
    }
  }, [client.user.document]);

  const handleEditOnOK = async (data) => {
    setLoading(true);
    if (validateUserEdition(data)) {
      data = { id, ...data };
      const result = await updateCustomer(data.id, data.name, data.birthDate, `+${data.phone}`, data.email);
      if (result) {
        if (onUpdateData) onUpdateData();
      } else {
        setLoading(false);

        showSnackbar({
          message: `Houve algum problema. Tente novamente e caso não funcione contate o suporte.`,
          severity: "error",
          duration: 6000,
        });
      }
    } else {
      showSnackbar({
        message: `Os dados preenchidos são inválidos e não serão enviados. Clique no botão de edição para que possam ser corrigidos.`,
        severity: "error",
        duration: 6000,
      });
    }
    setEditIsOpen(false);
    setLoading(false);
  };

  const downloadIRReport = useCallback(
    async (cpf, year, name) => {
      setModalLoading(true);
      if (!year) {
        setModalLoading(false);

        showSnackbar({
          message: "Por favor, selecione algum Ano para Informe de IR",
          severity: "warning",
          duration: 6000,
        });

        return;
      }
      const date = `${year}-12-31`;
      const response = await generateIRReportPDF(cpf, date);
      if (!response) {
        setModalLoading(false);

        showSnackbar({
          message: "Não foi possível realizar a operação. Tente novamente e caso o erro persista, entre em contato com o suporte.",
          severity: "error",
          duration: 6000,
        });
      } else {
        const tempLink = document.createElement("a");
        tempLink.href = response.data.pdfBase64;
        tempLink.setAttribute("target", "_blank");
        tempLink.setAttribute("download", `Informe de IR -${name}.pdf`);
        tempLink.click();

        setModalLoading(false);

        showSnackbar({
          message: "Operação realizada com sucesso.",
          severty: "success",
          duration: 3000,
        });

        setIRReportOpen(false);
      }
    },
    [client?.user?.document, yearIRReportSelected, client.user.name]
  );

  const restrictionOnClick = async () => {
    const updateRestriction = dataRestriction?.hrRestriction !== true;

    const response = await updateHrRestriction(dataRestriction?.id, updateRestriction);

    if (!response.data) {
      showSnackbar({
        message: `Houve algum problema. Tente novamente e caso não funcione contate o suporte.`,
        severity: "error",
        duration: 6000,
      });
    } else {
      setClient({ ...client, agreement: { ...client.agreement, hrRestriction: response?.data?.hrRestriction } });
      setDataRestriction({ id: client?.agreement?.id, hrRestriction: client?.agreement?.hrRestriction });

      showSnackbar({
        message: `Operação realizada com sucesso.`,
        severity: "success",
        duration: 6000,
      });

      getData();
    }
  };

  useEffect(() => {
    getData();

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);

    let year = today.getFullYear();
    let yearList = [];
    for (let i = 0; i < year; i++) {
      if (yearList.length < 4) {
        yearList.push({ value: (year - i).toString() });
      }
    }

    setYearIRReport(yearList);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [dataRestriction]);

  return (
    <TableWrapper>
      <TableTitle>
        Registro do Cliente
        {!client.agreement ? (
          <EditButton onClick={handleEditOnClick} />
        ) : (
          <ThreePointsMenu
            handleEditOnClick={handleEditOnClick}
            restrictionOnClick={restrictionOnClick}
            dataRestriction={{ dataRestriction }}
            handleIRReportOnClick={handleIRReportOnClick}
          />
        )}
      </TableTitle>
      <UserEdit
        data={{ name, birthDate, phone, email }}
        loading={loading}
        isOpen={editIsOpen}
        onClose={handleEditOnClose}
        onOK={handleEditOnOK}
      />
      <ModalIrReport
        loading={loading}
        isOpen={iRReportIsOpen}
        handleIRReportOnClose={handleIRReportOnClose}
        downloadIRReport={() => {
          downloadIRReport(client?.user?.document, yearIRReportSelected, client.user.name);
        }}
        yearIRReport={yearIRReport}
        modalLoading={modalLoading}
        yearIRReportSelected={yearIRReportSelected}
        setYearIRReportSelected={setYearIRReportSelected}
        handleDownloadIRReport={handleIRReportOnClick}
      />
      {dataUser.map(([id, fieldName, value]) => (
        <div key={id}>
          <Row>
            <Validation>{dataValidation(fieldName, value)}</Validation>
          </Row>
          <Row>
            <TdLeft>{fieldName}</TdLeft>
            <Td>{value || <NotInformed>(não informado)</NotInformed>}</Td>
          </Row>
        </div>
      ))}
    </TableWrapper>
  );
};

UserRecord.propTypes = {
  onUpdateData: PropTypes.func,
};

export default UserRecord;
