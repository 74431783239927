import React from 'react';
import { getUserData } from 'auth/user';


const RequireAuth = (Module) => ({ history }) => {
  const user = getUserData();

  if (!user) {
    history.replace('/login');
    return null;
  }
  return <Module />;
};

export default RequireAuth;
