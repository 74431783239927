import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const Container = styled.div`
  width: 100%;
`;

export const LabelWrapper = styled.div`
  white-space: nowrap;
`;

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #bcbcbc;
    },
    ${({ noborderchangeonhover }) => noborderchangeonhover === 'true' && `
    &:hover fieldset {
      border-color: #bcbcbc;
    },`}
    &.Mui-focused fieldset {
      border-color: #3646a8;
    }
  }
`;
