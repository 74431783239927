import { API } from "config";
import axios from "axios";

const retryFirstOffer = async (shoppingCartId, cpf, product, debtInstallmentAmount) => {
  const result = {};
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios
    .post(
      `${API.FIRST_OFFER}`,
      {
        cpf,
        shoppingCart: shoppingCartId,
        isRetry: true,
        product,
        debtInstallmentAmount
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .catch((e) => {
      result.message = e.response.data.message;
    });
    if(response && response.status == 200){
      result.data = response.data;
    }
    return result;
};

export default retryFirstOffer;
