import styled from 'styled-components';

export const Content = styled.div`
  padding: ${({ noMargins }) => (noMargins ? 0 : '0 24px 24px 24px')};
  color: #031E52;
`;

export const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
`;
