import React from 'react';

import iconClose from '../assets/close-window-xxl.png';
import { Select, LoadingBar, Spacer } from '_FUTURE_LIBS_/@ali-ui';
import PropTypes from 'prop-types';


import {
    Modal,
    CloseButton,
    ModalTitle,
    ButtonOk,
    ButtonCancell,
} from './styles';


const ModalIrReport = (
  {
    isOpen,
    handleIRReportOnClose,
    downloadIRReport,
    yearIRReport,
    yearIRReportSelected,
    setYearIRReportSelected,
    modalLoading
  }) => {
  
    const handleYearIRReport = (value) => {
        setYearIRReportSelected(value)
      };
    

    return (
      <Modal style={{display: isOpen ? 'block': 'none', textAlign: 'center', width: '27%'}} >
        <ModalTitle>Download do Informe de IR</ModalTitle>
        <CloseButton src={iconClose} onClick={handleIRReportOnClose} />
        <Spacer height={10} />
        { !modalLoading ? (
          <>
            <Select
                id="year-ir-report"
                placeholder='Ano'
                label="Qual Ano Calendário do Informe?'"
                options={yearIRReport}
                value={yearIRReportSelected}
                onChange={handleYearIRReport}
                fullWidth
            />
            <ButtonOk
                variant="contained"
                onClick={downloadIRReport}
                color={"primary"}
            >
                Download
            </ButtonOk>
            <ButtonCancell
                variant="contained"
                onClick={handleIRReportOnClose}
                color={"secondary"}
            >
                Cancelar
            </ButtonCancell>
          </>
        ) : (
          <>
            <Spacer height={10} />
            <p style={{ fontWeight: "bold", color: "#031E52" }}>Gerando PDF</p>
            <LoadingBar />
          </>
        )}
    </Modal>
    );
};

ModalIrReport.propTypes = {
  isOpen: PropTypes.bool,
  handleIRReportOnClose: PropTypes.func,
  downloadIRReport: PropTypes.func,
  yearIRReport: PropTypes.number,
  yearIRReportSelected: PropTypes.number,
  setYearIRReportSelected: PropTypes.func,
  modalLoading: PropTypes.bool,
};


export default ModalIrReport;
