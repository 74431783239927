import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { ClientContext } from 'contexts/ClientProvider';
import { SnackbarContext } from 'contexts/SnackbarProvider';
import getCreditReasons from 'api/getCreditReasons';
import postPriorityPolice from 'api/postPriorityPolice';
import getPriorityPolice from 'api/getPriorityPolice';
import PercentField from '_FUTURE_LIBS_/@ali-forms/FormFields/PercentField';
import MultiplierFactorField from '_FUTURE_LIBS_/@ali-forms/FormFields/MultiplerFactorField';
import ParcelField from '_FUTURE_LIBS_/@ali-forms/FormFields/ParcelField';
import {
  Spacer,
  Hr,
  Select,
  Flex,
  FlexSpacer,
} from '_FUTURE_LIBS_/@ali-ui';
import {
  formatDisplayCpf,
  formatDisplayCnpj,
  convertStringToNumber,
} from 'utils';
import { Button } from '@material-ui/core';

import {
  Container,
  Content,
  ButtonWrapper,
  LoadTag,
  Description,
  Value,
} from './styles';

const SendPolice = () => {
  const [client, setClient] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);
  const {
    firstName,
    lastName,
    document,
  } = client.user;

  const {
    companyDocumentNumber,
    companyName,
  } = client.agreement;

  const [minSalaryPercentage, setMinSalaryPercentage] = useState();
  const [maxSalaryPercentage, setMaxSalaryPercentage] = useState();
  const [interestRate, setInterestRate] = useState();
  const [minNumberOfInstallments, setMinNumberOfInstallments] = useState();
  const [maxNumberOfInstallments, setMaxNumberOfInstallments] = useState();
  const [creditReasons, setCreditReasons] = useState([]);
  const [reasonSelected, setReasonSelected] = useState();
  const [reasonValue, setReasonValue] = useState();
  const [body, setBody] = useState();
  const [loadingSend, setLoadingSend] = useState(false);

  const handleOnChangeReason = (value) => {
    setReasonSelected(value);
    const result = JSON.parse(value);
    setReasonValue(result.type);
  };

  const handleInsert = async () => {
    setLoadingSend(true);
    const response = await postPriorityPolice(body);
    if (response.failure) {
      showSnackbar({
        message: `Não foi possível inserir esta política: ${response.status}`,
        severity: 'error',
        duration: 6000,
      });
      setMinSalaryPercentage('');
      setMaxSalaryPercentage('');
      setInterestRate('');
      setMinNumberOfInstallments('');
      setMaxNumberOfInstallments('');
      setReasonSelected('');
      setLoadingSend(false);
    } else {
      const list = await getPriorityPolice(client.user.document);
      setClient((preState) => ({ ...preState, PriorityPolice: list.value }));
      showSnackbar({
        message: 'Política inserida com sucesso.',
        severity: 'success',
        duration: 6000,
      });

      setMinSalaryPercentage('');
      setMaxSalaryPercentage('');
      setInterestRate('');
      setMinNumberOfInstallments('');
      setMaxNumberOfInstallments('');
      setReasonSelected('');
      setLoadingSend(false);
    }
  };

  useEffect(() => {
    setBody({
      priorityPoliceType: 1,
      companyName: companyName || '(não informado)',
      companyDocumentNumber,
      employeeName: `${firstName} ${lastName}`,
      employeeDocumentNumber: document,
      creditReason: reasonValue,
      minNumberOfInstallments,
      maxNumberOfInstallments,
      minSalaryPercentage: minSalaryPercentage
      && convertStringToNumber(minSalaryPercentage),
      maxSalaryPercentage: minSalaryPercentage
      && convertStringToNumber(maxSalaryPercentage),
      interestRate: interestRate
      && convertStringToNumber(interestRate),
    });
  }, [reasonSelected]);

  useEffect(() => {
    getCreditReasons().then((response) => {
      setCreditReasons(response.map((e) => ({
        text: e.friendlyName,
        value: JSON.stringify({
          text: e.friendlyName,
          type: e.type,
        }),
      })));
    });
  }, []);

  return (
    <Container>
      <Content>
        <Flex>
          <Description>Tipo de Política de Prioridade: <Value>1</Value></Description>
        </Flex>
        <Flex>
          <Description>Nome: <Value>{firstName} {lastName}</Value></Description>
          <FlexSpacer />
          <Description>CPF: <Value>{formatDisplayCpf(document)}</Value></Description>
        </Flex>
        <Flex>
          <Description>Empresa: <Value>{companyName || '(não informado)'}</Value></Description>
          <FlexSpacer />
          <Description>CNPJ: <Value>{formatDisplayCnpj(companyDocumentNumber)}</Value></Description>
        </Flex>
        <Spacer height={20} />
        <Hr />
        <Spacer height={20} />
        <Flex>
          <MultiplierFactorField
            label="Fator multiplicador minimo"
            placeholder="Quantas vezes o salário?"
            onChange={setMinSalaryPercentage}
            value={minSalaryPercentage}
          />
          <FlexSpacer />
          <MultiplierFactorField
            label="Fator multiplicador máximo"
            placeholder="Quantas vezes o salário?"
            onChange={setMaxSalaryPercentage}
            value={maxSalaryPercentage}
          />
          <FlexSpacer />
          <PercentField
            label="Taxa de juros (%)"
            placeholder="Juros?"
            onChange={setInterestRate}
            value={interestRate}
          />
        </Flex>
        <Flex>
          <ParcelField
            label="Minimo de parcelas"
            value={minNumberOfInstallments}
            onChange={setMinNumberOfInstallments}
            placeholder="Qual o minimo de parcelas?"
            fullWidth
          />
          <FlexSpacer />
          <ParcelField
            label="Máximo de parcelas"
            value={maxNumberOfInstallments}
            onChange={setMaxNumberOfInstallments}
            placeholder="Qual o máximo de parcelas?"
            fullWidth
          />
        </Flex>
        <Flex>
          <Select
            label="Motivo do crédito"
            options={creditReasons}
            value={reasonSelected}
            onChange={handleOnChangeReason}
            fullWidth
          />
          <FlexSpacer />
          <ButtonWrapper>
            <Button
              disabled={loadingSend}
              variant="contained"
              color="primary"
              onClick={handleInsert}
              fullWidth
            >
              Adicionar
            </Button>
            {loadingSend && (<LoadTag>Enviando...</LoadTag>)}
          </ButtonWrapper>
        </Flex>
      </Content>
    </Container>
  );
};

export default SendPolice;
