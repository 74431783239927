import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import createProposal from "api/alicore/createProposal";
import updateShoppingCart from "api/alicore/updateShoppingCart";
import searchCpf from "api/alicore/searchCpf";

import WaitingSurvey from "components/WaitingSurvey";
import InSettingUp from "components/InSettingUp";

import { Spacer, Hr } from "_FUTURE_LIBS_/@ali-ui";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import UserRecord from "components/UserRecord";
import AgreementRecord from "components/AgreementRecord";
import DropdownButton from "components/DropdownButton";
import ProposalSummary from "components/ProposalSummary";
import CancelProposal from "components/CancelProposal";
import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";
import { TabContent } from "../styles";
import { Container, Content, Title, WarningBox, ErrorBox } from "./styles";
import UserMaxLimits from "components/UserMaxLimits";

const STATUSES_GETTING_OFFERS = ["created", "gettingOffers"];
const STATUSES_DEALING = ["noPolicy", "noOffer", "offered", "dealing"];
const STATUSES_POST_DEAL = ["deal", "documenting"];
const STATUSES_CANCELABLE = [
  "created",
  "awaitingCreditValidation",
  "awaitingOnboardingBtg",
  "awaitingDocumentIssuesFix",
  "awaitingDocumentValidation",
  "recalculating",
  "awaitingFinancialIntegration",
  "awaitingCcbNumber",
  "awaitingContractGeneration",
  "awaitingContractSign",
  "awaitingHrApproval",
  "awaitingLoanManagerHrApproval",
];

export const useUserInfos = () => {
  const PORTABILITY_PRODUCT = "portability";
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(false);

  const [client, setClient] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);
  const [product, setProduct] = useState();
  const [debtInstallmentAmount, setDebtInstallmentAmount] = useState(0);
  const [debtToRefin, setDebtToRefin] = useState(0);
  const [debtsBeingRefinanced, setDebtsBeingRefinanced] = useState([]);
  const [debtsBeingTransferred, setDebtsBeingTransferred] = useState([]);
  const [eligibilityError, setEligibilityError] = useState(false);
  const [leaveDate, setLeaveDate] = useState(false);

  const handleOpenProposal = (product, debtInstallmentAmount = 0, debtToRefin = 0, selectedDebts = []) => {
    setLoading(!loading);
    setStart(true);
    setProduct(product);
    setDebtInstallmentAmount(debtInstallmentAmount);
    setDebtToRefin(debtToRefin);

    if (product === PORTABILITY_PRODUCT) {
      setDebtsBeingTransferred(selectedDebts);
    } else {
      setDebtsBeingRefinanced(selectedDebts);
    }
  };

  const handleCreateProposal = async (creditReason = "Other") => {
    setLoading(true);

    const offersData = product === PORTABILITY_PRODUCT ? { debtsBeingTransferred } : { debtsBeingRefinanced };

    const responseProposal = await createProposal(client.user.id, product, debtInstallmentAmount, debtToRefin, creditReason, offersData);
    const shoppingCart = responseProposal?.data?.shoppingCarts[0] || null;

    if (responseProposal?.message) {
      setLoading(false);
      setEligibilityError(responseProposal.eligibilityError);
      showSnackbar({
        message: responseProposal.message,
        severity: "error",
        duration: 10000,
      });
      return;
    }

    if (shoppingCart?.riskAuditMessage) {
      showSnackbar({
        message: shoppingCart.riskAuditMessage.operationMessage,
        severity: "error",
        duration: 6000,
      });
    }

    const result = await searchCpf(sessionStorage.getItem("ALI_CURRENT_CUSTOMER"));
    if (result?.invalidNetwork) {
      setLoading(false);
      showSnackbar({
        message: `Ocorreu um problema de rede e não foi possível acessar os dados desse cliente. Verifique a internet do seu computador e tente novamente. Caso não funcione, contate o suporte de infraestrutura.`,
        severity: "error",
        duration: 10000,
      });
      return;
    }

    setClient(result);
    setLoading(false);
  };

  const handleOnUpdateDocOrRetry = async () => {
    history.go(0);
  };

  const handleUpdateData = async (shoppingCartId, monthlyIncome, creditReason) => {
    setLoading(true);
    if (shoppingCartId && monthlyIncome && creditReason) {
      const resultUpdate = await updateShoppingCart(shoppingCartId, monthlyIncome, creditReason);
      if (!resultUpdate) {
        showSnackbar({
          message: `Houve algum problema. Tente novamente e caso não funcione contate o suporte.`,
          severity: "error",
          duration: 6000,
        });
      }
    }

    const result = await searchCpf(sessionStorage.getItem("ALI_CURRENT_CUSTOMER"));
    if (result?.invalidNetwork) {
      setLoading(false);
      showSnackbar({
        message: `Ocorreu um problema de rede e não foi possível acessar os dados desse cliente. Verifique a internet do seu computador e tente novamente. Caso não funcione, contate o suporte de infraestrutura.`,
        severity: "error",
        duration: 10000,
      });
      return;
    }

    setLoading(false);
    setClient(result);
  };

  const handleCreateData = async () => {
    setLoading(true);
    const result = await searchCpf(sessionStorage.getItem("ALI_CURRENT_CUSTOMER"));
    if (result?.invalidNetwork) {
      setLoading(false);
      showSnackbar({
        message: `Ocorreu um problema de rede e não foi possível acessar os dados desse cliente. Verifique a internet do seu computador e tente novamente. Caso não funcione, contate o suporte de infraestrutura.`,
        severity: "error",
        duration: 10000,
      });
      return;
    }

    setLoading(false);
    setClient(result);
  };

  useEffect(async () => {
    if (!client) {
      setLoading(true);

      const cpf = sessionStorage.getItem("ALI_CURRENT_CUSTOMER");
      const result = await searchCpf(cpf);
      if (result?.invalidNetwork) {
        setLoading(false);
        showSnackbar({
          message: `Ocorreu um problema de rede e não foi possível acessar os dados desse cliente. Verifique a internet do seu computador e tente novamente. Caso não funcione, contate o suporte de infraestrutura.`,
          severity: "error",
          duration: 10000,
        });
        return;
      }

      setLoading(false);
      setClient(result);
    }
  }, []);

  useEffect(() => {
    if (client?.agreement) {
      setLoading(true);

      if (client?.agreement?.leaveStartDate && !client?.agreement?.leaveFinishDate) {
        setLeaveDate(true);
      } else {
        const atualDate = moment().startOf("day");
        const startLeave = moment(client?.agreement?.leaveStartDate).utc();
        const finishLeave = moment(client?.agreement?.leaveFinishDate).utc();

        setLeaveDate(atualDate.isBetween(startLeave, finishLeave));
      }

      setLoading(false);
    }

    if (client?.shoppingCart?.riskAuditMessage) {
      showSnackbar({
        message: client?.shoppingCart?.riskAuditMessage.operationMessage,
        severity: "error",
        duration: 6000,
      });
    }
  }, [client]);

  return {
    handleUpdateData,
    handleCreateData,
    client,
    start,
    leaveDate,
    loading,
    setLoading,
    handleOpenProposal,
    handleOnUpdateDocOrRetry,
    handleCreateProposal,
    eligibilityError,
  };
};

const UserInfos = () => {
  const {
    handleUpdateData,
    handleCreateData,
    client,
    start,
    leaveDate,
    loading,
    setLoading,
    handleOpenProposal,
    handleOnUpdateDocOrRetry,
    handleCreateProposal,
    eligibilityError,
  } = useUserInfos();

  return (
    <TabContent>
      {client && (
        <>
          <Container>
            <UserRecord onUpdateData={handleUpdateData} />
            <Spacer height={24} />
            <Hr />
            <Spacer height={20} />
            <AgreementRecord onCreateData={handleCreateData} />
          </Container>
          <Spacer width={32} />
          <Container>
            {client?.hasAgreementLeave && !start && (
              <Content>
                <ErrorBox>
                  <AnnouncementIcon style={{ fontSize: "48px", paddingRight: "12px" }} />
                  <p>
                    {" "}
                    <b>Cliente afastado</b>.
                  </p>
                </ErrorBox>
              </Content>
            )}
            {(!client.agreement || client.agreement.isDisabled) && !client?.hasAgreementLeave && !start && (
              <Content>
                <ErrorBox>
                  <AnnouncementIcon style={{ fontSize: "48px", paddingRight: "12px" }} />
                  <p>
                    {" "}
                    Este colaborador não possui <b>convênio ativo</b> ou <b>não há convênio cadastrado</b>.
                  </p>
                </ErrorBox>
              </Content>
            )}
            {client.agreement && (leaveDate || client.agreement.isDisabled) && !client?.hasAgreementLeave && !start && (
              <Content>
                <ErrorBox>
                  <AnnouncementIcon style={{ fontSize: "48px", paddingRight: "12px" }} />
                  <p>
                    {" "}
                    Este colaborador <b>não tem autorização</b> para <b>simular empréstimo.</b> Ele está <b>afastado</b> das suas atividades
                    na empresa.
                  </p>
                </ErrorBox>
              </Content>
            )}
            {!leaveDate && client.agreement && client.agreement.hrRestriction && !start && (
              <Content>
                <WarningBox>
                  <AnnouncementIcon style={{ fontSize: "48px", paddingRight: "12px" }} />
                  <p>
                    {" "}
                    Este colaborador encontra-se <b>bloqueado</b> para simular empréstimo. Somente usuário com permissão têm acesso ao menu
                    no box de <b>Registro do Cliente</b> para desbloqueá-lo.{" "}
                    <b>
                      Não se deve avisar ao cliente que ele está bloqueado pelo RH, apenas que não temos oferta no momento e seguir o script
                      do atendimento.
                    </b>
                  </p>
                </WarningBox>
              </Content>
            )}

            {client?.riskAuditMessage && (
              <Content>
                <WarningBox>
                  <AnnouncementIcon style={{ fontSize: "48px", paddingRight: "12px" }} />
                  <p>{client.riskAuditMessage.operationMessage}</p>
                </WarningBox>
              </Content>
            )}
            {!leaveDate &&
              (!client.proposals || client.proposals.length == 0) &&
              !client.shoppingCart &&
              client.agreement &&
              !client.agreement.isDisabled &&
              !client.agreement.hrRestriction &&
              !client.riskAuditMessage &&
              !start && (
                <Content>
                  <Title>Tipo de proposta</Title>
                  <DropdownButton loading={loading} onClick={handleOpenProposal} />
                </Content>
              )}
            {!leaveDate &&
              client.proposals &&
              client.proposals.length > 0 &&
              client.agreement &&
              !client.agreement.isDisabled &&
              !client.riskAuditMessage &&
              !client.agreement.hrRestriction && (
                <>
                  <ProposalSummary
                    loading={loading}
                    setLoading={setLoading}
                    status={client.proposals[0].status}
                    creditReason={client.proposals[0].shoppingCart.creditReason}
                    shoppingCart={client.proposals[0].shoppingCart}
                    proposal={client.proposals[0]}
                    onUpdateDocOrRetry={handleOnUpdateDocOrRetry}
                  />
                  {STATUSES_CANCELABLE.some((s) => s == client.proposals[0].status) && (
                    <CancelProposal shoppingCart={client.proposals[0].shoppingCart} />
                  )}
                </>
              )}
            {!leaveDate &&
              start &&
              !client.shoppingCart &&
              client.agreement &&
              !client.agreement.isDisabled &&
              !client.riskAuditMessage &&
              !client.agreement.hrRestriction && (
                <Content>
                  <WaitingSurvey
                    loading={loading}
                    setLoading={setLoading}
                    onCreateProposal={handleCreateProposal}
                    onUpdateData={handleUpdateData}
                    eligibilityError={eligibilityError}
                  />
                </Content>
              )}
            {!leaveDate &&
              client.shoppingCart &&
              client.agreement &&
              !client.agreement.isDisabled &&
              !client.agreement.hrRestriction &&
              !client.riskAuditMessage && (
                <Content>
                  {(STATUSES_GETTING_OFFERS.some((s) => s == client.shoppingCart?.status) ||
                    STATUSES_DEALING.some((s) => s == client.shoppingCart?.status)) && (
                      <InSettingUp onUpdateData={handleUpdateData} onUpdateDocOrRetry={handleOnUpdateDocOrRetry} />
                  )}
                  {STATUSES_POST_DEAL.some((s) => s == client.shoppingCart?.status) && (
                    <>
                      <ProposalSummary
                        loading={loading}
                        setLoading={setLoading}
                        status={client.shoppingCart?.status}
                        creditReason={client.shoppingCart?.creditReason}
                        shoppingCart={client.shoppingCart}
                        onUpdateDocOrRetry={handleOnUpdateDocOrRetry}
                      />
                      <CancelProposal shoppingCart={client.shoppingCart} />
                    </>
                  )}
                </Content>
              )}

            {!leaveDate &&
              client.agreement &&
              !client.agreement.isDisabled &&
              !client.agreement.hrRestriction &&
              !client.riskAuditMessage && <UserMaxLimits />}
          </Container>
        </>
      )}
    </TabContent>
  );
};

export default UserInfos;
