export const statuses = (status) => {
  const listStatus = {
    created: "Proposta criada",
    gettingOffers: "Preparando a primeira oferta",
    offered: "Primeira oferta feita",
    dealing: "Ajustando oferta",
    deal: "Oferta selecionada",
    documenting: "Enviando de documentos",
    closed: "Fechado",
    noOffer: "Sem ofertas disponíveis",
    awaitingDocumentValidation: "Aguardando validação dos documentos",
    awaitingCreditValidation: "Aguardando análise operacional",
    awaitingOnboardingBtg: "Aguardando Onboarding do BTG",
    awaitingDocumentIssuesFix: "Aguardando correção dos documentos",
    recalculating: "Recalculando taxas",
    awaitingFinancialIntegration: "Aguardando integração financeira",
    awaitingCcbNumber: "Aguardando geração da CCB",
    awaitingContractGeneration: "Aguardando geração do contrato",
    awaitingContractSign: "Aguardando assinatura de contrato",
    awaitingHrApproval: "Aguardando aprovação do RH",
    awaitingLoanManagerHrApproval: "Aguardando aprovação de reserva externa",
    awaitingPayment: "Aguardando pagamento",
    awaitingEndorsement: "Aguardando endosso",
    finished: "Finalizada",
    declined: "Declinada",
    canceled: "Cancelada",
    expired: "Expirada",
  };

  return listStatus[status] ? listStatus[status] : status;
};
