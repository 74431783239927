import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HEADER_HEIGHT } from 'components/Header/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT});
`;

export const TabContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
`;

export const Loading = styled(CircularProgress)`
  position: absolute;
  right: -80px;
  top: 18px;
`;
