import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ClientProvider from 'contexts/ClientProvider';
import SnackbarProvider from 'contexts/SnackbarProvider';
import RequireAuth from 'auth/RequireAuth';
import { tracker } from 'libs/open-replay';

import Home from 'pages/Home';
import User from 'pages/User';
import Login from 'pages/Login';
import PageNotFound from 'pages/PageNotFound';
import Register from 'pages/Register';

const App = () => {
	useEffect(() => {
    tracker.start();
  }, []);

	return (
		<ClientProvider>
			<SnackbarProvider>
				<Router history={createBrowserHistory()}>
					<Switch>
						<Route path="/" exact component={RequireAuth(Home)} />
						<Route path="/user" component={RequireAuth(User)} />
						<Route path="/login" component={Login} />
						<Route path="/register" component={Register} />
						<Route component={PageNotFound} />
					</Switch>
				</Router>
			</SnackbarProvider>
		</ClientProvider>
	);
};

export default App;
