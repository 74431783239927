import React, { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { MicrosoftButton } from "pages/Login/styles";
import microsoftLogo from "../../../../assets/microsoft.svg";
import { clearUserData } from "auth/user";
import { useHistory } from "react-router-dom";

export const SignOutButton = () => {
	const { instance } = useMsal();
	const history = useHistory();
	const handleLogout = useCallback(() => {
		instance
			.logoutPopup({
				postLogoutRedirectUri: "/",
				mainWindowRedirectUri: "/", // redirects the top level app after logout
			})
			.then(() => {
				clearUserData();
				history.push("/login");
			});
	}, [instance]);

	return (
		<MicrosoftButton onClick={handleLogout}>
			<img src={microsoftLogo} /> Sair
		</MicrosoftButton>
	);
};
