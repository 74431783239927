import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 50px;
	width: 300px;
`;

export const Loading = styled(CircularProgress)`
	position: absolute;
	right: -80px;
	top: 18px;
`;

export const HiddenElement = styled.input`
	position: absolute;
	top: -200px;
	opacity: 0;
`;

export const MicrosoftButton = styled.button`
	width: 100%;
	height: 41px;
	padding: 0.75rem;
	background: #ffffff;
	font-family: "Segoe UI";
	color: #5e5e5e;
	font-size: 15px;
	border: 1px solid #8c8c8c;
	font-weight: 600;
	display: flex;
	align-items: center;
	cursor: pointer;

	img {
		margin-right: 0.75rem;
	}
`;
