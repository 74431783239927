import moment from "moment";

// Currency

export const currencyToString = (string) => {
  let number = string;
  if (!number) {
    return "R$ 0,00";
  }
  if (Number(number)) {
    number = Number(number).toFixed(2);
  }
  number = String(number);
  if (!number.includes(",") && !number.includes(".")) {
    number += ",00";
  }
  number = number.split(",").join("");
  number = number.split(".").join("");
  if (Number(number)) {
    return (Number(number) / 100).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
  }
  return "R$ 0,00";
};

export const stringToCurrency = (string) => {
  let number = string;
  if (!number) {
    return 0;
  }
  number = number.toString().replace("R$", "").replace(".", "").replace(",", ".");
  if (Number(number)) {
    number = Number(number).toFixed(2);
  }
  return parseFloat(number);
};

// Percent

export const stringToPercent = (string, decimals = 2) => {
  let number = string;
  if (!number) {
    return "0,00%";
  }
  if (Number(number)) {
    number = Number(number).toFixed(2);
  }
  number = String(number);
  if (!number.includes(",") && !number.includes(".")) {
    number += ",00";
  }

  const percent = parseFloat((parseFloat(number) / 100).toFixed(decimals + 2));
  return percent.toLocaleString("pt-br", {
    style: "percent",
    minimumSignificantDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

// Phones
export const numberToPhone = (number, countryCode = "+55") => {
  let phoneNumber = number;
  if (phoneNumber) phoneNumber = phoneNumber.replace(countryCode, "").trim();

  if (phoneNumber?.length === 11) {
    return `${countryCode} (${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7, 11)}`;
  }
  if (phoneNumber?.length === 10) {
    return `${countryCode} (${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 6)}-${phoneNumber.substring(6, 10)}`;
  }
  if (phoneNumber?.length === 9) {
    return `${countryCode} ${phoneNumber.substring(0, 5)}-${phoneNumber.substring(5, 9)}`;
  }
  if (phoneNumber?.length === 8) {
    return `${countryCode} ${phoneNumber.substring(0, 4)}-${phoneNumber.substring(4, 8)}`;
  }
  return `${countryCode} (00) 00000-0000`;
};

export const cleanPhone = (phone) => {
  if (!phone) return null;

  return phone.replace(" ", "").replace(" ", "").replace("(", "").replace(")", "").replace("-", "");
};

// CPF
export const stringToCPF = (string) => {
  const cpf = String(string).split("-").join("");
  if (cpf.length === 11) {
    return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9, 11)}`;
  }
  return string;
};

export const stringToCNPJ = (value) => {
  const obj = String(value).split(".").join("").split("/").join("").split("-").join("");
  if (obj.length === 14) {
    return `${obj.substring(0, 2)}.${obj.substring(2, 5)}.${obj.substring(5, 8)}/${obj.substring(8, 12)}-${obj.substring(12, 14)}`;
  }
  return value;
};

export const cleanCPF = (string) => {
  if (!string) return null;

  return string.replace(".", "").replace(".", "").replace("-", "");
};

//CNPJ
export const cleanCNPJ = (string) => {
  if (!string) return null;
  return string.replace(/\./g, "").replace("/", "").replace("-", "");
};

export const printDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const printDateFull = (date) => {
  return moment(date).format("DD/MM/YYYY HH:MM:SS");
};

export const replaceAll = (stringValue, find, replace) => {
  return stringValue.split(find).join(replace);
};

export const convertToKebabCase = (value) => {
  const toLowerCase = value.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
  return toLowerCase.replace(/\s/g, "-");
};

export const kebabToCamelCase = (value) => value.replace(/-./g, (x) => x[1].toUpperCase());

export const kebabCaseToPascalCase = (value) => kebabToCamelCase(value).replace(/^./, (x) => x.toUpperCase());

/**
 *
 * @param {Array} array
 * @returns {Array}
 */
export const sanitizeArray = (array) => {
  return array
    ?.filter((x) => x)
    ?.map((item) => {
      if (typeof item === "object") {
        return sanitizeObject(item);
      }
      return item;
    });
};

export const sanitizeObject = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (!data[key]) return acc;
    if (typeof data[key] === "object") {
      acc[key] = sanitizeObject(data[key]);
    } else {
      acc[key] = data[key];
    }
    return acc;
  }, {});
};

export const getFloatValue = (value) => {
  return value ? Number(parseFloat(value.replace(/\./g, "").replace(/,/g, ".")).toFixed(2)) : 0;
};
