import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const TableWrapper = styled.div`
  padding: 16px 16px;
  height: fit-content;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Td = styled.div`
  padding: 2px 0;
  min-width: 270px;
  background-color: 'green';
`;

export const TdLeft = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 8px;
  color: #666666;
`;

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
  padding: 0px 8px 24px 8px;
  text-align: center;
`;

export const TeblaAlert = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #f50057;
  padding-bottom: 24px;
  text-align: center;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;

export const Modal = styled.div`
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 500px;
  border: 1px solid rgb(153, 153, 153);
  position: fixed;
  z-index: 999;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 20%) 3px 3px 5px 5px;
  padding: 15px;
`;

export const CloseButton = styled.img`
  height: 20px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
  padding-bottom: 24px;
`;

export const ButtonOk = styled(Button)`
  float: right;
`;

export const TextWrapper = styled.div`
`;

export const Warning = styled.div`
  color: red;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`;