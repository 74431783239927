import { API } from "config";
import axios from "axios";
import loginAdmin from "./loginAdmin";

const createUser = async (payload) => {
	await loginAdmin();

	const result = await axios
		.post(API.CREATE_USER, payload, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("ALI_ACCESS_TOKEN")}`,
			},
		})
		.catch((error) => {
			return { status: error?.response?.data?.statusCode, ...error?.response?.data };
		});

	return result;
};

export default createUser;
