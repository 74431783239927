import { API } from "config";
import axios from "axios";

const updateHrRestriction = async (id, hrRestriction) => {
	const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
	const response = await axios
		.put(
			`${API.UPDATE_HR_RESTRICTION}${id}`,
			{
				id,
				hrRestriction,
			},
			{
				headers: {
					aliauth: `Bearer ${accessToken}`,
				},
			}
		)
		.catch((e) => {
			console.error(e);
		});

	if (!response?.data) {
		return null;
	}

	return response.data;
};

export default updateHrRestriction;
