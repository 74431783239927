import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Clickable,
} from './styles';

const ClickHere = ({
  justify,
  onClick,
  children,
  'data-testid': dataTestid,
}) => (
  <Container justify={justify}>
    <Clickable data-testid={dataTestid} onClick={onClick}>
      {children}
    </Clickable>
  </Container>
);

ClickHere.defaultProps = {
  justify: null,
  'data-testid': null,
};

ClickHere.propTypes = {
  justify: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
};

export default ClickHere;
