export const textColors = {
  primary: 'color: #031E52;',
  secundary: 'color: #818D9C;',
  primaryReverse: 'color: #ffffff',

  link: 'color: #3772FF;',
  linkRed: 'color: #F53F5E;',
  label: 'color: #919AA7;',

  placeholder: 'color: #919AA7;',

  highlight: 'color: #3772FF;',
  highlightGreen: 'color: #11D78F;',
  highlightRed: 'color: #FF4762;',

  error: 'color: #FF4762;',
};

export const buttonsColors = {
  primary: `
    background-color: #3772FF;
    color: #ffffff;
  `,
  secondary: `
    background-color: #EAECEE;
    color: #031E52;
  `,
};

export const backgoundColors = {
  header: 'background-color: #ffffff;',
  body: 'background-color: #ffffff;',
  green: 'background-color: #26a626;',
  orange: 'background-color: #fcce74;',
};

export const bordersColors = {
  primary: 'border-color: #E7EFF7;',
  secundary: 'border-color: #3772FF;',
  // DOC: a mesma cor de input  no Material UI
  input: 'border-color: #babcbd;',
  // DOC: a mesma cor de focus para inputs no Material UI
  focus: 'border-color: #3646a8;',
};
