import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import { numericFormatter } from '_FUTURE_LIBS_/@ali-forms/utils/formatters';

const MULTIPLE_SYMBOL = 'X';
const ERROR_MSG = 'Valor inválido';

const MultiplerFactorField = ({
  label,
  onChange,
  value,
  noValidation,
  ...rest
}) => {
  const [error, setError] = useState('');
  const ref = useRef();

  const handleOnBlur = () => {
    const inputValue = ref.current.value;
    if (!noValidation) {
      const formatedValue = numericFormatter(inputValue, true);
      if (!formatedValue) {
        setError(ERROR_MSG);
      } else {
        onChange(formatedValue);
      }
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  const handleOnChange = (valueChanged) => {
    const valueFormatted = numericFormatter(valueChanged);
    if (valueFormatted !== false) {
      onChange(valueFormatted);
    }
  };

  return (
    <TextField
      inputRef={ref}
      label={label}
      onChange={handleOnChange}
      value={value === '' ? value : `${value} ${MULTIPLE_SYMBOL}`}
      inputMode="decimal"
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      error={error}
      {...rest}
    />
  );
};

MultiplerFactorField.defaultProps = {
  label: 'Qual o percentual?',
  onChange: () => {},
  value: '',
  noValidation: false,
};

MultiplerFactorField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
};

export default MultiplerFactorField;
