import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "_FUTURE_LIBS_/@ali-ui";
import isValid from "./validation";

export const ERROR_MSG = "CEP inválido";

const CepField = ({ label, placeholder, onChange, noValidation, value, searchCEP }) => {
  const [error, setError] = useState(false);

  const handleOnChange = (cep) => {
    if (!noValidation) {
      const invalid = cep.length === 9 && !isValid(cep);
      setError(invalid && ERROR_MSG);
    }
    onChange(cep);
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== "" && !isValid(value);
      setError(invalid && ERROR_MSG);
    }
    if (value && value.replace("-", "").length === 8) {
      const cep = value.replace("-", "");
      searchCEP(cep);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  CepField.isValid = () => isValid(value);

  CepField.setError = (errorMessage) => {
    setError(errorMessage);
  };

  return (
    <TextField
      data-testid="field-cep"
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      value={value}
      inputMode="decimal"
      format={{
        delimiters: ["-"],
        blocks: [5, 3],
        numericOnly: true,
        delimiterLazyShow: true,
      }}
      error={error}
    />
  );
};

/* istanbul ignore next */
CepField.defaultProps = {
  label: "CEP",
  placeholder: "Digite aqui seu CEP",
  onChange: () => {},
  value: "",
  noValidation: false,
  searchCEP: () => {},
};

CepField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
  searchCEP: PropTypes.func,
};

export default CepField;
