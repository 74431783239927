/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { fonts } from "../../styles/fonts";
import { textColors } from "../../styles/colors";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Label } from "./styles";

const [, inputColor] = textColors.primary.replace(/;/g, "").split(": ");
const [, fontFamily] = fonts.fontFamily.replace(/;/g, "").split(": ");

const DropDownSearchable = ({
	label,
	options,
	groupBy,
	findValueFromProperty,
	getOptionLabel,
	placeholder,
	onChange,
	fontSize,
	letterSpacing,
	autoFocus,
	value,
  disabled,
}) => (
	<>
		<InputLabel shrink>
			<Label>{label}</Label>
		</InputLabel>
		<Autocomplete
			data-testid="test-autocomplete-input-component"
			value={value === "" ? null : options.find((e) => e[findValueFromProperty || "code"] === value) || null}
			options={options}
			groupBy={groupBy}
			getOptionLabel={getOptionLabel}
			fullWidth
			onChange={onChange}
			popupIcon={null}
			noOptionsText={`${label} não encontrado(a)`.trim()}
      disabled={disabled}
			renderInput={(params) => (
				<TextField
					{...params}
					data-testid="test-dropdownsearchable-input-component"
					placeholder={placeholder}
					variant="outlined"
					autoFocus={autoFocus}
					fullWidth
					InputProps={{
						style: {
							fontSize,
							letterSpacing,
							marginBottom: "-2px",
							height: "42px",
							color: inputColor,
							fontFamily,
							paddingTop: 0,
							paddingBottom: 0,
						},
						...params.InputProps,
					}}
				/>
			)}
		/>
	</>
);

DropDownSearchable.defaultProps = {
	label: "",
	placeholder: "",
	autoFocus: false,
	options: {
		value: "",
		label: "",
		notSeachable: false,
	},
	onChange: null,
	fontSize: "15px",
	letterSpacing: "inherit",
	groupBy: () => {},
	getOptionLabel: () => {},
  disabled: false,
};

DropDownSearchable.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	autoFocus: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
			notSeachable: PropTypes.bool,
		})
	),
	onChange: PropTypes.func,
	fontSize: PropTypes.string,
	letterSpacing: PropTypes.string,
	groupBy: PropTypes.func,
	getOptionLabel: PropTypes.func,
  disabled: PropTypes.bool,
  findValueFromProperty: PropTypes.string,
  value: PropTypes.string,
};

export default DropDownSearchable;
