import React, { useContext, useState, useEffect } from 'react';
import { TableTitle, Container, Row, TdLeft, Td, NotInformed } from './styles';
import { ClientContext } from '../../../contexts/ClientProvider';
import { toStringBrlCurrency } from '../../../utils';

const SelectedProduct = () => {
  const [client] = useContext(ClientContext);
  
  const [availableLimit, setAvailableLimit] = useState(null);
  const [requestedLoanAmount, setRequestedLoanAmount] = useState(null);
  const [amountOfInstallmentsRequested, setAmountOfInstallmentsRequested] = useState(null);
  const [interestRate, setInterestRate] = useState(null);

  const selectedProductList = [
    [1, 'Limite Disponível:', availableLimit],
    [2, 'Valor Solicitado:', requestedLoanAmount],
    [3, 'Parcelas Solicitadas:', amountOfInstallmentsRequested],
    [4, 'Taxa de Juros:', interestRate],
  ];

  useEffect(() => {
    if (!client?.currentValidOffer) return;
    const result = Array.isArray(client.currentValidOffer.result) ? client.currentValidOffer.result[0] : client.currentValidOffer.result;
    if (!result?.installmentOptions.length) return;
    
    let installmentsQuantity = null;
    if (client?.proposals?.length) {
      installmentsQuantity = client.proposals[0].installmentsQuantity ? client.proposals[0].installmentsQuantity : client.proposals[0].shoppingCart?.installmentsQuantity;
    } else {
      installmentsQuantity = client?.shoppingCart?.installmentsQuantity;
    }
    
    if (!installmentsQuantity) return;
    const data = result.installmentOptions.find(i => i.numberOfPayableInstallments == installmentsQuantity);
    if (!data) return;

    setAvailableLimit(data?.totalCreditAmount ? toStringBrlCurrency(data.totalCreditAmount) : null);
    setRequestedLoanAmount(data?.releasedAmount ? toStringBrlCurrency(data.releasedAmount) : null);
    setAmountOfInstallmentsRequested(!!installmentsQuantity && data?.installmentAmount  ? `${installmentsQuantity}x de ${toStringBrlCurrency(data?.installmentAmount)}` : null);
    setInterestRate(data?.interestRatePerMonth ? `${parseFloat(data.interestRatePerMonth).toFixed(2)}%` : null);
  }, [])

  return (
    <>
      <TableTitle>{"Produto Selecionad"}o</TableTitle>
      <Container>
        {selectedProductList.map(([id, fieldName, value]) => (
          <Row key={id}>
            <TdLeft>{fieldName}</TdLeft>
            <Td>{value || <NotInformed>{"(não informado)"}</NotInformed>}</Td>
          </Row>
        ))}
      </Container>
    </>
  );
};

export default SelectedProduct;
