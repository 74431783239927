import { API } from 'config';
import axios from 'axios';

const getAgreementDetail = async (cpf) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');

	const response = await axios
		.get(`${API.AGREEMENT_DETAIL}${cpf}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	if (!response?.data) {
		return null;
	}

	return response.data;
};

export default getAgreementDetail;
