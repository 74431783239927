

import React, { useEffect, useState } from "react";
import editIcon from "../assets/edit-icon.png";
import { ImageButton } from "./styles";
import { getUserRoles } from "../../auth/user";
import PropTypes from "prop-types";

const EditButton = ({ loading, onClick }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const handleClick = () => {
    if (onClick && !loading) {
      onClick();
    }
  };

  useEffect(async () => {
    const userRoles = getUserRoles();
    const hasAdminRole = userRoles.find((role) => role.toLowerCase() == "admin");
    setIsAdmin(hasAdminRole);
  }, []);

  return (
    <ImageButton
      title="Editar"
      style={{ backgroundImage: `url(${editIcon})`, display: isAdmin ? "block" : "none" }}
      onClick={handleClick}
    />
  );
};

EditButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EditButton;
