import { API } from 'config';
import axios from 'axios';

const getProposalResume = async (proposalId) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');

	const response = await axios
		.get(`${API.PROPOSAL_RESUME}${proposalId}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	if (!response?.data) {
		return null;
	}
	return response.data;
};

export default getProposalResume;
