import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isValid from './validation';

export const ERROR_MSG = 'Naturalidade inválida';

const NaturalnessField = ({
  label,
  placeholder,
  onChange,
  value,
  noValidation,
}) => {
  const [error, setError] = useState(false);

  const handleOnChange = (naturalness) => {
    onChange(naturalness);
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && !isValid(value);
      setError(invalid && ERROR_MSG);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  NaturalnessField.isValid = () => (value !== '' && isValid(value));

  return (
    <TextField
      data-testid="test-naturalness"
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      error={error}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    />
  );
};

/* istanbul ignore next */
NaturalnessField.defaultProps = {
  label: 'Naturalidade',
  placeholder: 'Digite aqui a sua naturalidade',
  onChange: () => {},
  value: '',
  noValidation: false,
};

NaturalnessField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
};

export default NaturalnessField;
