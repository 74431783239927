import { API } from 'config';
import axios from 'axios';

const createShoppingCartByPromotion = async (cpf, id) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');
	const cleanCpf = cpf.replace(/[^\d]+/g, '');
	const response = await axios
		.post(
			`${API.CREATE_SHOP_BY_PROMO}`,
			{
				cpf: cleanCpf,
				uniquePromotionId: id,
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
		.catch((e) => {});

	if (response?.data) {
		return [response?.data];
	}

	return [];
};

export default createShoppingCartByPromotion;
