import { getData } from 'api';
import { API } from 'config';

const getCreditReasons = async () => {
  const ret = await getData(API.CREDIT_REASON_URL);

  if (!Array.isArray(ret)) {
    const out = [];
    Object.values(ret).forEach((value) => {
      if (typeof value === 'object') {
        out.push(value);
      }
    });
    return out;
  }

  return ret;
};

export default getCreditReasons;
