import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isValid from './validation';

const ERROR_MSG = 'Verifique seu e-mail';

const EmailField = ({
  label,
  placeholder,
  onChange,
  value,
  noValidation,
}) => {
  const [error, setError] = useState(false);

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && !isValid(value);
      setError(invalid && ERROR_MSG);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  EmailField.isValid = () => (value !== '' && isValid(value));

  return (
    <TextField
      data-testid="field-email"
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      inputMode="email"
      autoCapitalize="none"
      error={error}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    />
  );
};

/* istanbul ignore next */
EmailField.defaultProps = {
  label: 'E-mail',
  placeholder: 'Digite aqui seu email',
  onChange: () => {},
  value: '',
  noValidation: false,
};

EmailField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
};

export default EmailField;
