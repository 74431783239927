import React, { useContext, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Warning } from "./styles";

import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";

import { getUserRoles } from "../../auth/user";
import createAgreement from "api/alicore/createAgreement";

import AgreementCreate from "./AgreementCreate";
import PropTypes from "prop-types";

const AgreementNotFount = ({ onCreateData }) => {
	const [client] = useContext(ClientContext);
	const [showSnackbar] = useContext(SnackbarContext);
	const [editIsOpen, setEditIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	const handleOnClick = () => {
		setEditIsOpen(true);
	};

	const handleOnClose = () => {
		setEditIsOpen(false);
	};

	const handleOnOK = async (data) => {
		if (data) {
			setLoading(true);
			const result = await createAgreement(data);
			if (result) {
				if (onCreateData) await onCreateData();
			} else {
				showSnackbar({
					message: `Houve algum problema. Tente novamente e caso não funcione contate o suporte.`,
					severity: "error",
					duration: 6000,
				});
			}
			setLoading(false);
		}
		setEditIsOpen(false);
	};

	useEffect(() => {
		const userRoles = getUserRoles();
		const hasAdminRole = userRoles.find((role) => role.toLowerCase() == "admin");
		setIsAdmin(hasAdminRole);
	}, []);

	return (
		<>
			<Warning>Convênio não encontrado!</Warning>
			<Button
				variant="contained"
				color="primary"
				onClick={handleOnClick}
				style={{ display: isAdmin ? "block" : "none" }}
				disabled={client.hasAgreementLeave}
			>
				CRIAR CONVÊNIO
			</Button>
			<AgreementCreate data={{ client }} loading={loading} isOpen={editIsOpen} onClose={handleOnClose} onOK={handleOnOK} />
		</>
	);
};

AgreementNotFount.propTypes = {
  onCreateData: PropTypes.func,
};

export default AgreementNotFount;