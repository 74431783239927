export const ERRORS_DATE = {
  INVALID: 1,
  MIN_AGE: 2,
};

const isInvalid = (value, minAge) => {
  const [day, mon, year] = value.split('/');

  const dayInt = parseInt(day, 10);
  const monInt = parseInt(mon, 10);
  const yearInt = parseInt(year, 10);

  if (yearInt < 1900) {
    return ERRORS_DATE.INVALID;
  }

  const date = new Date(yearInt, monInt - 1, dayInt);

  if (dayInt !== date.getDate()
    || monInt !== date.getMonth() + 1
    || yearInt !== date.getFullYear()
  ) {
    return ERRORS_DATE.INVALID;
  }

  if (minAge) {
    const today = new Date();
    const birthDate = date;
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    if (age < minAge) {
      return ERRORS_DATE.MIN_AGE;
    }
  }

  return 0;
};

export const isPartialInvalid = (date) => {
  const [day, mon, year] = date.split('/');
  const dayInt = parseInt(day, 10);
  const monInt = parseInt(mon, 10);

  let invalidMsg = 0;

  const isDayInvalid = day && (day === '00' || dayInt > 31);
  const isMonthInvalid = mon && (mon === '00' || monInt > 12);
  const isYearInvalid = year && parseInt(year.charAt(0), 10) > 2;

  if (isDayInvalid || isMonthInvalid || isYearInvalid) {
    invalidMsg = ERRORS_DATE.INVALID;
  }

  return invalidMsg;
};

export default isInvalid;
