import React, { useState, useEffect, useContext } from 'react';
import { SnackbarContext } from 'contexts/SnackbarProvider';
import getProposalResume from 'api/alicore/getProposalResume';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Paper from '@material-ui/core/Paper';
import { statuses } from '../../helpers/statuses';
import {
  formatDisplayDate,
} from 'utils';
import {
  TableWrapper,
  TableTitle,
  Warning,
  Row,
  Td,
  TdLeft,
  NotInformed,
  ButtonBack,
} from './styles';
import PropTypes from "prop-types";

const ProposalListDetail = ({ data, idProposal, setVisible }) => {
  const [showSnackbar] = useContext(SnackbarContext);
  const [proposal, setProposal] = useState();

  const dataProposal = [
    [1, "Status da Proposta:", statuses(proposal?.status)],
    [2, "Número do CCB:", proposal?.CCBNumber],
    [3, "Data de Emissão:", formatDisplayDate(proposal?.emissionDate)],
    [4, "Valor da Proposta:", proposal?.amount?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })],
    [5, "Parcelas:", data?.installmentsQuantity ? `${data?.installmentsQuantity} x` : null],
    [6, "Valor da Parcela:", data?.simulation?.installmentAmount?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })],
    [7, "CET:", data?.simulation?.cet ? `${data?.simulation?.cet}%` : null],
    [8, "IOF:", data?.simulation?.totalIofAmount?.toLocaleString("pt-br", { style: "currency", currency: "BRL" })],
    [9, "Análise de Risco:", proposal?.riskAnalysisResponse],
    [10, "Análise de Crédito:", proposal?.creditAnalysisResponse],
    [11, "RH Aprovador:", proposal?.hrEmployeeWhoAnswered],
    [12, "Pago:", proposal?.paid === true ? "Sim" : "Não"],
    [13, "Garantido:", proposal?.granted === true ? "Sim" : "Não"],
    [14, "Endosso:", proposal?.endorsed === true ? "Sim" : "Não"],
  ];

  const handleClick = () => {
    setVisible(false);
  };

  useEffect(() => {
    getProposalResume(idProposal).then((response) => {
      if (response) {
        setProposal(response);
      } else {
        showSnackbar({
          message: `Houve algum problema ao buscar a proposta. Tente novamente e caso não funcione contate o suporte.`,
          severity: "error",
          duration: 6000,
        });
      }
    });
  }, []);

  return (
    <Paper elevation={2}>
      <TableWrapper>
        <TableTitle>Resumo da Proposta</TableTitle>
        {(proposal === null || proposal === undefined) && <Warning>Houve um erro ao carregar a proposta.</Warning>}
        {proposal &&
          dataProposal.map(([id, fieldName, value]) => (
            <Row key={id}>
              <TdLeft>{fieldName}</TdLeft>
              <Td>{value || <NotInformed>(não informado)</NotInformed>}</Td>
            </Row>
          ))}
        <ButtonBack onClick={handleClick}>
          <Row>
            <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
          </Row>
          <Row>VOLTAR</Row>
        </ButtonBack>
      </TableWrapper>
    </Paper>
  );
};

ProposalListDetail.propTypes = {
  data: PropTypes.object,
  idProposal: PropTypes.string,
  setVisible: PropTypes.bool,
};

export default ProposalListDetail;