import styled from 'styled-components';
import { bordersColors } from '_FUTURE_LIBS_/@ali-ui/styles/colors';
import arrowDown from './assets/arrow_down.png';

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.div`
  font-size: 12px;
  color: #919AA7;
`;

export const SelectStyled = styled.select`
  font-family: 'Poppins';
  font-size: 15px;
  height: 42px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid;
  ${bordersColors.input};
  border-radius: 4px;
  background: transparent;
  border-image: none;
  outline-color: #3646a8;
  padding: 0 36px 0 10px;
  color: ${({ hasSelection }) => (hasSelection ? '#011b47' : '#8895ac')};
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  box-sizing: border-box;
  appearance: none;
  background-image: url(${arrowDown});
  background-position:
    calc(100% - 16px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;

  box-sizing: border-box;
  appearance: none;
  background-image: url(${arrowDown});
  background-position:
    calc(100% - 16px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-repeat: no-repeat;

  :focus {
    ${bordersColors.focus};
  }
`;
