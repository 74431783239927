import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';

const BankAgencyField = ({
  label,
  onChange,
  value,
  placeholder,
  maxLength,
  disabled
}) => (
  <TextField
    data-testid="test-account-agency"
    label={label}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    inputMode="decimal"
    disabled={disabled}
    format={{
      delimiters: [],
      blocks: [maxLength],
      numericOnly: true,
      delimiterLazyShow: true,
    }}
  />
);

/* istanbul ignore next */
BankAgencyField.defaultProps = {
  label: 'Agência',
  onChange: () => {},
  value: '',
  placeholder: 'Agência',
  maxLength: 6,
  disabled: false,
};

BankAgencyField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};

export default BankAgencyField;
