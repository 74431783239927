
import React from 'react';
import PropTypes from 'prop-types';
import DateField from '../DateField';

const BirthdateField = ({ label, placeholder, ...rest }) => {
  BirthdateField.isValid = () => DateField.isValid();

  return (
    <DateField
      data-testid="field-birthday"
      label={label}
      placeholder={placeholder}
      {...rest}
    />
  );
};

BirthdateField.defaultProps = {
  label: 'Data de nascimento',
  placeholder: 'Digite aqui sua data de nascimento',
  errorInvalid: 'Verifique sua data de nascimento',
};

BirthdateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorInvalid: PropTypes.string,
};

export default BirthdateField;
