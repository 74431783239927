import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxField = ({
  label,
  checked,
  onChange,
}) => {
  /* istanbul ignore next */
  const handleOnChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          onChange={handleOnChange}
          color="primary"
          inputProps={{
            'data-testid': 'test-checkbox',
          }}
        />
      )}
      label={label}
    />
  );
};

/* istanbul ignore next */
CheckboxField.defaultProps = {
  label: '',
  onChange: () => {},
  checked: false,
};

CheckboxField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default CheckboxField;
