import { API } from "config";
import axios from "axios";

const shoppingCartCancel = async (id, abandonReason, abandonOperator) => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios.post(
    `${API.SHOPPING_CART_CANCEL}${id}`,
    {
      abandonReason,
      abandonOperator,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch(e => {});

  return response && response.status == 204;
};

export default shoppingCartCancel;
