import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const LoadTag = styled.div`
  margin-top: 8px;
  color: #d0d0d0;
  font-size: 12px;
  text-align: center;
`;

export const ErrorText = styled.div`
    max-width: 15vw;
    font-size: 10px;
    display: block;
    color: #e33;
`;