export const numericFormatter = (value, final, withDecimal = true) => {
  const onlyNumbers = value.toString().replace(/[^0-9,]/gi, '');
  if (onlyNumbers === '') {
    return onlyNumbers;
  }

  const [integer, decimal, error] = onlyNumbers.split(',');

  if (!integer || error !== undefined) {
    return false;
  }

  if (decimal && decimal.length > 2) {
    return false;
  }

  const integerArray = integer.split('');

  const out = [];
  for (let n = 0, i = integerArray.length - 1; i >= 0; n += 1, i -= 1) {
    if (n >= 3 && n % 3 === 0) {
      out.push('.');
    }
    out.push(integerArray[i]);
  }
  
  const reversedOut = [...out].reverse();

  if (!withDecimal) {
    return `${reversedOut.join('')}`;
  }

  let decimalFinal = decimal;
  if (final) {
    if (!decimal) {
      decimalFinal = '00';
    } else if (decimal.length === 1) {
      decimalFinal += '0';
    }
  }

  const decimalPart = decimalFinal !== undefined ? `,${decimalFinal}` : '';

  return `${reversedOut.join('')}${decimalPart}`;
};

