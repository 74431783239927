import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';

const BankAccountField = ({
  label,
  onChange,
  value,
  placeholder,
  disabled,
}) => (
  <TextField
    data-testid="test-account-number"
    label={label}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    inputMode="decimal"
    disabled={disabled}
    format={{
      delimiters: [],
      blocks: [10],
      numericOnly: true,
      delimiterLazyShow: true,
    }}
  />
);

/* istanbul ignore next */
BankAccountField.defaultProps = {
  label: 'Conta e Dígito',
  onChange: () => {},
  value: '',
  placeholder: 'Conta e Dígito',
  disabled: false,
};

BankAccountField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BankAccountField;
