import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';

const MaskedTextField = ({ format, inputRef, ...rest }) => (

  <Cleave {...rest} htmlRef={inputRef} options={format} />
);

MaskedTextField.propTypes = {
  format: PropTypes.objectOf(PropTypes.any).isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]).isRequired,
};

export default MaskedTextField;
