import React, { useState, useContext, useEffect } from "react";

import { ClientContext } from "contexts/ClientProvider";
import { SnackbarContext } from "contexts/SnackbarProvider";
import { useHistory } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails, Button } from "@material-ui/core";
import { CurrencyField } from "_FUTURE_LIBS_/@ali-forms";
import { Select, Spacer, Flex, FlexSpacer } from "_FUTURE_LIBS_/@ali-ui";

import getCreditReasons from "api/alicore/getCreditReasons";
import updateMonthlyIncome from "api/alicore/updateMonthlyIncome";

import { convertStringToNumber } from "utils";

import { Container, Content, ButtonWrapper, LoadTag } from "./styles";
import { getFloatValue } from "helpers/formaters";

const WaitingSurvey = ({ loading, setLoading, onCreateProposal, onUpdateData, eligibilityError }) => {
  const [client] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);

  const convertToBrl = (value) => {
    return value ? value.replace(/\./g, ",") : "0,00";
  };

  const [monthlyIncome, setMonthlyIncome] = useState(convertToBrl(client.agreement?.monthlyIncome?.toString()));

  const [creditReason, setCreditReason] = useState();
  const [creditReasons, setCreditReasons] = useState([]);
  const [internalLoading, setInternalLoading] = useState(false);
  const history = useHistory();

  const onChangeMonthlyIncome = (value) => {
    setMonthlyIncome(value);
  };

  const onSendClick = async () => {
    setInternalLoading(true);

    const resultUpdate = await updateMonthlyIncome(client.user.document, convertStringToNumber(monthlyIncome));
    if (resultUpdate) {
      showSnackbar({
        message: `Houve algum problema ao atualizar o salário. Tente novamente e caso não funcione contate o suporte.`,
        severity: "error",
        duration: 6000,
      });
    }

    await onCreateProposal(creditReason);

    await onUpdateData(client.shoppingCart?.id, creditReason);
    setInternalLoading(false);
  };

  const onSendOKClick = async () => {
    history.replace("/user");
    history.go();
  };

  useEffect(async () => {
    setLoading(true);
    let result = await getCreditReasons();
    result = result.map((e) => ({ text: e.name, value: e.internalName }));
    setCreditReasons(result);
    setLoading(false);
  }, []);

  return (
    <Container>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <b>CRIAR PROPOSTA: ETAPA 1/2 </b>
        </AccordionSummary>
        <AccordionDetails>
          <Content>
            <Flex>
              <CurrencyField label="Salário" value={monthlyIncome} onChange={onChangeMonthlyIncome} fullWidth />
              <FlexSpacer />
            </Flex>
            <Select
              label="Motivo do crédito"
              options={creditReasons}
              value={creditReason}
              onChange={setCreditReason}
              fullWidth
              data-testid="selectCredit"
            />
            <ButtonWrapper>
              {!eligibilityError && (
                <>
                  <Button
                    disabled={internalLoading || loading || getFloatValue(monthlyIncome) == 0 || !creditReason}
                    variant="contained"
                    color="primary"
                    onClick={onSendClick}
                    fullWidth
                  >
                    Atualizar dados
                  </Button>
                  {internalLoading && <LoadTag>Enviando dados...</LoadTag>}
                </>
              )}
              {eligibilityError && (
                <Button variant="contained" color="primary" onClick={onSendOKClick} fullWidth>
                  OK
                </Button>
              )}
            </ButtonWrapper>
            <Spacer height={24} />
          </Content>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default WaitingSurvey;
