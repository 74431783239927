import * as PDFJS from "pdfjs-dist/webpack";
import imageCompression from "browser-image-compression";
import mergeImages from "merge-images";

const compressOptions = {
	maxSizeMB: 0.5,
	useWebWorker: true,
};

export const base64toBlob = (b64Data, contentType, sliceSize) => {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	let byteCharacters = atob(b64Data);
	let byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		let slice = byteCharacters.slice(offset, offset + sliceSize);

		let byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		let byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	let blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const blobToBase64 = (blob) => {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
};

export const renderPage = async(page, canvas, canvasContext, viewport, resolve) => {
  await page
		.render({
		  canvasContext,
			viewport,
		})
		.promise.then(async (res) => {
		  const block = canvas.toDataURL().split(";");
			const contentType = block[0].split(":")[1];
			const realData = block[1].split(",")[1];
			const blob = base64toBlob(realData, contentType);
			const compressedFile = await imageCompression(blob, compressOptions).then(async (output) => {
				return await blobToBase64(output);
			});
		resolve({
			data: compressedFile,
			height: parseInt(canvas.height),
			width: parseInt(canvas.width),
		});
	});
}

export const getPage = (pdf, num) => {
	return new Promise((resolve, reject) => {
		return pdf.getPage(num).then(async (page) => {
			const viewport = page.getViewport({ scale: "1.5" });
			const canvas = document.createElement("canvas");
			const canvasContext = canvas.getContext("2d");

			canvas.height = viewport.height || viewport.viewBox[3];
			canvas.width = viewport.width || viewport.viewBox[2];

			await renderPage(page, canvas, canvasContext, viewport, resolve);
		});
	});
};

const getPages = async (pdf, mergePages) => {
	const pages = [];
	const mergeData = [];
	let mergeHeight = 0;

	for (let i = 0; i < pdf.numPages; i++) {
		await getPage(pdf, i + 1).then((page) => {
			if (mergePages) {
				const pageData = {
					src: page.data,
					x: 0,
					y: mergeData.length == 0 ? 0 : mergeData[mergeData.length - 1].y + page.height,
				};
				mergeHeight = pageData.y + page.height;
				mergeData.push(pageData);
			} else {
				pages.push(page.data);
			}
		});
	}

	if (mergePages) {
		await mergeImages(mergeData, { height: mergeHeight }).then(async (b64) => {
			const block = b64.split(";");
			const contentType = block[0].split(":")[1];
			const realData = block[1].split(",")[1];
			const blob = base64toBlob(realData, contentType);
			const compressedFile = await imageCompression(blob, compressOptions).then(async (output) => {
				return await blobToBase64(output);
			});

			pages.push(compressedFile);
		});
	}

	return pages;
};

export const pdf2Image = async (file, mergePages, getBlob) => {
	getBlob = !!getBlob;
	mergePages = !!mergePages;

	let pages = [];
	if (file.name.toLowerCase().endsWith(".pdf")) {
		await PDFJS.getDocument({ url: URL.createObjectURL(file.content) }).promise.then(async (pdf) => {
			pages = await getPages(pdf, mergePages);
			if (getBlob) {
				pages = pages.map((page) => {
					const block = page.split(";");
					const contentType = block[0].split(":")[1];
					const realData = block[1].split(",")[1];
					return base64toBlob(realData, contentType);
				});
			}
		});
	}

	return pages.length == 0 ? null : pages;
};

export const compressImage = async (file, getBlob) => {
	getBlob = !!getBlob;

	const compressedFile = await imageCompression(file.content, { ...compressOptions, fileType: "image/png" }).then(async (output) => {
		if (getBlob) return output;

		return await blobToBase64(output);
	});

	return compressedFile;
};
