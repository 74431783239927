const ENV_VARS = [
	"GET_USER_BY_CPF",
	"TOKEN_LOGIN_URL",
	"TOKEN_VERIFY_URL",
	"GETPROPOSAL_LIST_URL",
	"GETPROPOSAL_BYID_URL",
	"DECLINE_PROPOSAL_URL",
	"RESET_TOKEN_URL",
	"TOKEN_URL",
	"CREATE_PROPOSAL_URL",
	"CREDIT_REASON_URL",
	"JOB_TITLE_URL",
	"SURVEY_URL",
	"GETPROPOSITIONBYTARGET_URL",
	"PROPOSAL_PRODUCTS_URL",
	"PROPOSAL_SETUP",
	"MAGIC_LINK_GENERATOR",
	"COMPANY_AGREEMENT",
	"GETPROPOSAL_PRODUCTS_BYID_URL",
	"GETPROPOSAL_FULLDETAIL_BYID_URL",
	"GETPROPOSAL_IN_PROGRESS_BY_CPF",
	"POST_TICKET_URL",
	"PUT_TICKET_URL",
	"DELETE_TICKET_URL",
	"GET_TICKET_URL",
	"GET_PORTABILITYTYPE_URL",
	"POST_PRIORITY_POLICE_URL",
	"PUT_PRIORITY_POLICE_URL",
	"GET_PRIORITY_POLICE_URL",
	"GET_ACCESSROLES_URL",
	"LOGIN_CLIENT",
	"GET_USER_OVERVIEW",
	"CREATE_PROPOSAL",
	"LIST_CREDIT_REASONS",
	"LIST_PRODUCTS",
	"LIST_DEBTS",
	"UPDATE_SHOPPING_CART_DATA",
	"SIMULATE_SHOPPING_CART",
	"SHOPPING_CART_LAST_SIMULATION",
	"SHOPPING_CART_DEAL",
	"SHOPPING_CART_CANCEL",
	"SHOPPING_CART_UPLOAD",
	"CREATE_USER",
	"FIRST_OFFER",
	"CUSTOMER_UPDATE",
	"RESEND_MAIL",
	"GET_CUSTOMER",
	"AGREEMENT_DETAIL",
	"SHOPPING_CART_LIST_BY_CPF",
	"PROPOSAL_RESUME",
	"UPDATE_MONTHLY_INCOME",
	"LIST_COMPANIES",
	"AGREEMENT_CREATE",
	"SEARCH_ADDRESS_BY_CEP",
	"UPDATE_HR_RESTRICTION",
	"GENERATE_IR_REPORT_PDF",
	"LIST_PROMOTIONS",
	"CREATE_SHOP_BY_PROMO",
	"GET_INTERNALUSER",
	"EXTERNAL_DEBTS",
	"MAX_OFFER_INFORMATION",
	"SIMPLIFIED_OFFER",
	"GET_COUNTRIES",
	"CREATE_SELFIE_MAGIC_LINK",
];

export const API = {};
ENV_VARS.forEach((v) => {
	let value = process.env[`REACT_APP_API_${v}`];
	if (!value) value = "";

	API[v] = value;
});

export const AZURE_ACCESS_TOKEN = "HEADER_TOKEN_KEY_AUTH";
