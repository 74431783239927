import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 300px;
`;

export const FormLabel = styled.label`
  font-size: 14px;
  color: #000;
  margin-top: 10px;
`;
