import styled from 'styled-components';

export const ImageButton = styled.div`
  height: 30px;
  width: 30px;
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: -8px;
  cursor: pointer;
  vertical-align: bottom;
  float: right;
`;
