const DESKTOP_MIN_WIDTH = 768;

export const convertStringToNumber = (value) => {
	if (!value || typeof value !== "string") return 0;
	const sanitizedValue = value.replace(/\./g, "").replace(/,/g, ".");
	const parsedValue = parseFloat(sanitizedValue);
	return isNaN(parsedValue) ? 0 : parsedValue;
};

/**
 *
 * @param {string} value
 * @returns number
 */
export const convertStringToFloat = (value) => {
	if (!value) return 0;

	const cleanValue = value.replace(/[^0-9.\-,]/g, "");

	const valueWithPeriod = cleanValue.replace(/\./g, "").replace(",", ".");

	const numericValue = parseFloat(valueWithPeriod);

	return numericValue;
};

export const isInViewport = (element, hTolerance = 0) => {
	if (!element) return false;

	const rect = element.getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + hTolerance &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

export const onlyNumbersString = (value) => value?.replace(/\D/g, "") ?? "";

export const toDateISO = (value) => {
	const [dd, mm, yyyy] = value.split("/");

	const day = parseInt(dd, 10);
	const mon = parseInt(mm, 10);
	const year = parseInt(yyyy, 10);

	const date = new Date(year, mon - 1, day);

	return date.toISOString();
};

export const isMobile = () => window.innerWidth < DESKTOP_MIN_WIDTH;

export const formatDisplayDate = (isoDate) => {
	if (!isoDate) {
		return isoDate;
	}
	const [yyyy, mm, dd] = isoDate.split("T")[0].split("-");
	return `${dd}/${mm}/${yyyy}`;
};

export const formatDisplayPhone = (onlyNumbers) => {
	if (!onlyNumbers) {
		return null;
	}

	let phoneNumber = onlyNumbers.replace(/\D/g, "");

	if (phoneNumber.length !== 13 || phoneNumber.length === 12 || phoneNumber.length < 11) {
		return onlyNumbers;
	}

	if (phoneNumber.length === 11) {
		phoneNumber = "55" + phoneNumber;
	}

	phoneNumber = `+${phoneNumber.substr(0, 2)}(${phoneNumber.substr(2, 2)})${phoneNumber.substr(4, 5)}-${phoneNumber.substr(9, 4)}`;

	return phoneNumber;
};

export const formatDisplayCpf = (onlyNumbers) => {
	if (!onlyNumbers) {
		return onlyNumbers;
	}
	const first = onlyNumbers.substr(0, 3);
	const second = onlyNumbers.substr(3, 3);
	const third = onlyNumbers.substr(6, 3);
	const dv = onlyNumbers.substr(9);

	return `${first}.${second}.${third}-${dv}`;
};

export const formatDisplayCnpj = (onlyNumbers) => {
	if (!onlyNumbers) {
		return onlyNumbers;
	}
	const first = onlyNumbers.substr(0, 2);
	const second = onlyNumbers.substr(2, 3);
	const third = onlyNumbers.substr(5, 3);
	const fourth = onlyNumbers.substr(8, 4);
	const dv = onlyNumbers.substr(12);

	return `${first}.${second}.${third}/${fourth}-${dv}`;
};

export const normalizeName = (str) => {
	if (!str) {
		return str;
	}

	const maintainUpperCase = (value) =>
		[
			"SA",
			"II",
			"III",
			"IV",
			"VI",
			"VII",
			"VIII",
			"IX",
			"XI",
			"XII",
			"XIII",
			"XIV",
			"XV",
			"XVI",
			"XVII",
			"XVIII",
			"XIX",
			"XX",
			"XXI",
			"XXII",
			"XXIII",
			"XXIV",
			"XXV",
			"XXVI",
			"XXVII",
			"XXVIII",
			"XXIX",
			"XXX",
			"XXXI",
			"XXXII",
			"XXXIII",
			"XXXIV",
			"XXXV",
			"XXXVI",
			"XXXVII",
			"XXXVIII",
			"XXXIX",
			"XL",
			"XLI",
			"XLII",
			"XLIII",
			"XLIV",
			"XLV",
			"XLVI",
			"XLVII",
			"XLVIII",
			"XLIX",
			"LI",
			"LII",
			"LIII",
			"LIV",
			"LV",
			"LVI",
			"LVII",
			"LVIII",
			"LIX",
			"LX",
			"LXI",
			"LXII",
			"LXIII",
			"LXIV",
			"LXV",
			"LXVI",
			"LXVII",
			"LXVIII",
			"LXIX",
			"LXX",
			"LXXI",
			"LXXII",
			"LXXIII",
			"LXXIV",
			"LXXV",
			"LXXVI",
			"LXXVII",
			"LXXVIII",
			"LXXIX",
			"LXXX",
			"LXXXI",
			"LXXXII",
			"LXXXIII",
			"LXXXIV",
			"LXXXV",
			"LXXXVI",
			"LXXXVII",
			"LXXXVIII",
			"LXXXIX",
			"XC",
			"XCI",
			"XCII",
			"XCIII",
			"XCIV",
			"XCV",
			"XCVI",
			"XCVII",
			"XCVIII",
			"XCIX",
		].indexOf(value) !== -1;

	const alwaysLowerCase = (value) => ["de", "da", "das", "do", "dos", "e"].indexOf(value) !== -1;

	const strArray = str.split(" ");

	const out = strArray.map((value, index) => {
		if (maintainUpperCase(value)) {
			return value;
		}

		const lcValue = value.toLowerCase();

		if (index !== 0 && alwaysLowerCase(lcValue)) {
			return lcValue;
		}

		const wordArray = lcValue.split("");
		wordArray[0] = wordArray[0].toUpperCase();
		return wordArray.join("");
	});

	return out.join(" ");
};

export const formatMoney = (value) => {
	if (!value || isNaN(value)) return "";

	value = value.toString().replace(/[^0-9.\-]/g, "");

	if (value.includes(".") && value.split(".")[1].length > 2) {
		value = parseFloat(value).toFixed(2);
	}

	value = parseFloat(value)
		.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})
		.replace(" ", " ");

	return value;
};

export const converteMoneyFloat = (value) => {
	let number = value;
	if (number === "") {
		number = 0;
	} else {
		number = number.replace("R$", "");
		number = number.replace(".", "");
		number = number.replace(",", ".");
		number = parseFloat(number);
	}
	return number;
};

export const takeMask = (value) => {
	if (typeof value !== "string") return value;
	const sanitizedValue = value.replace(/\s/g, "").replace(/\D/g, "");
	return sanitizedValue;
};

export const convertToDecimal = (value) => {
	const number = value.toString().replace(".", ",");
	return number;
};

export const toStringBrlCurrency = (numberValue, errorValue = "-") => {
	if (!numberValue) return errorValue;
	return numberValue?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) || errorValue;
};

function ValidEmail(email) {
	const emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
	return emailPattern.test(email);
}

export const dataValidation = (label, value) => {
	switch (label) {
		case "Nome:":
			return validateName(value);
		case "CPF:":
			return validateCPF(value);
		case "Data nascimento:":
			return validateBirthdate(value);
		case "Telefone:":
			return validatePhoneNumber(value);
		case "E-mail:":
			return validateEmail(value);
		default:
			return "";
	}
};

const validateName = (value) => {
	if (value.length < 2) {
		return "Erro no campo Nome, nome muito curto.";
	}
	return "";
};

const validateCPF = (value) => {
	const cpf = value.replace(/\D/g, "");
	if (cpf.length !== 11) {
		return "Erro no campo CPF, não parecem números válidos.";
	}
	return "";
};

const validateBirthdate = (value) => {
	const birthYear = parseInt(value.slice(value.length - 4));
	const birthMonth = parseInt(value.substr(4, 2)) - 1;
	const birthDay = parseInt(value.substr(0, 2));

	const birthdate = new Date(birthYear, birthMonth, birthDay);
	const ageInMilliseconds = Date.now() - birthdate.getTime();
	const ageDate = new Date(ageInMilliseconds);
	const age = Math.abs(ageDate.getUTCFullYear() - 1970);

	if (age < 18) {
		return "Erro no campo Data nascimento, cliente menor de 18 anos.";
	}
	return "";
};

const validatePhoneNumber = (value) => {
	const phoneNumber = value.replace(/\D/g, "");
	if (phoneNumber.length < 11 || phoneNumber.length === 12 || phoneNumber.length > 13) {
		return "Erro no campo Telefone, não parecem números válidos.";
	}
	return "";
};

const validateEmail = (value) => {
	if (!ValidEmail(value)) {
		return "Erro no campo E-mail, não parece um e-mail válido.";
	}
	return "";
};

export const getMultiplierByDiff = (diff) => {
	if (diff >= 0 && diff <= 3) {
		return 1;
	} else if (diff >= 4 && diff <= 7) {
		return 2;
	} else if (diff >= 8 && diff <= 11) {
		return 3;
	} else if (diff >= 12 && diff <= 23) {
		return 6;
	} else if (diff >= 24) {
		return 12;
	} else {
		return -1;
	}
};

export const censorCPF = (cpf) => {
	const firstPart = cpf.substring(0, 3);
	const lastPart = cpf.substring(cpf.length - 2);

	return `${firstPart}.***.***-${lastPart}`;
};
