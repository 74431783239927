import { API } from "config";
import axios from "axios";

const updateMonthlyIncome = async (documentNumber, monthlyIncome) => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios
    .post(
      `${API.UPDATE_MONTHLY_INCOME}`,
      {
        documentNumber, 
        monthlyIncome,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .catch((e) => {});

  return (response && response.status == 204);
};

export default updateMonthlyIncome;