import { API } from "config";
import axios from "axios";

const loginAdmin = async () => {
	try {
		const response = await axios.post(API.LOGIN_CLIENT, {
			client_id: process.env.REACT_APP_CLIENT_ID,
			client_secret: process.env.REACT_APP_SECRET,
		});

		if (!response?.data) return false;

		sessionStorage.setItem("ALI_ACCESS_TOKEN", response.data.jwt.replace("Bearer ", ""));

		return true;
	} catch (error) {
		return false;
	}
};

export default loginAdmin;
