import { API } from "config";
import axios from "axios";

const createAgreement = async (data) => {
	const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
	const response = await axios
		.post(`${API.AGREEMENT_CREATE}`, data, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	return response && response.status == 200 ? response.data : null;
};

export default createAgreement;
