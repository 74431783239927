import styled from 'styled-components';

export const FlexSpacer = styled.div`
  display: none;
  display: block;
  min-width: 24px;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export default Flex;
