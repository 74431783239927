/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import iconClose from '../assets/close-window-xxl.png';
import {
    Modal,
    CloseButton,
    ModalTitle,
    ButtonOk
} from './styles';

export const RESEND_MSG = "O cliente não recebeu o contrato por e-mail para assinatura? Clique aqui e reenviaremos o e-mail para que ele possa tentar assinar novamente.";
export const CLICKSIGN_HELP_LINK = "https://ajuda.clicksign.com/article/122-como-assinar-um-documento";

const ReSendMail = ( { isOpen, onClose, onOK, loading }) => {

    const [confirming, setConfirming] = useState(false);

    const handleOnClose = () => {
        setConfirming(false);

        if (onClose) onClose();
    }

    const handleOnOK = () => {
        if (confirming) {
            setConfirming(false);
    
            if (onOK) onOK();
        } else {
            setConfirming(true);
        }
    }

    const getButtonLabel = () => {
      if (loading) return "Enviando";
      if (confirming) return "Clique novamente para confirmar";
      return "OK";
    }

    useEffect(() => {

    }, [])

    return (
      <Modal style={{ display: isOpen ? "block" : "none" }}>
        <ModalTitle>Reenvio de e-mail de assinatura de contrato.</ModalTitle>
        <CloseButton src={iconClose} onClick={handleOnClose} data-testid="close-button" />
        <>
          Antes de solicitar o reenvio do e-mail para assinatura do contrato, oriente o cliente para verificar se o e-mail da ClickSign não
          foi enviado para a <i>caixa de spam.</i>
          <br />
          <br />
          Caso ele tenha recebido o e-mail mas está com dificuldades para assinar, envie-o esse{" "}
          <a href={CLICKSIGN_HELP_LINK} target="_blank">
            link
          </a>{" "}
          onde a ClickSign orienta como deve ser feita a assinatura digital.
          <br />
          <br />
          Se ainda deseja reenviar o e-mail, clique no botão abaixo.
          <br />
          <br />
        </>
        <ButtonOk
          disabled={loading}
          variant="contained"
          color={!confirming ? "primary" : "secondary"}
          onClick={handleOnOK}
          data-testid="submit-modal"
        >
          {getButtonLabel()}
        </ButtonOk>
      </Modal>
    );
};

ReSendMail.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOK: PropTypes.func,
  loading: PropTypes.bool,
};

export default ReSendMail;