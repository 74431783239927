const isValid = (value, landline) => {
  const phone = value.replace(/\D/g, '');
  if (phone.length !== (landline ? 12 : 13)) {
    return false;
  }

  return landline || phone.charAt(4) === '9';
};

export default isValid;
