import { API } from "config";
import axios from "axios";

const getProducts = async (documentNumber) => {
	const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
	const response = await axios
		.get(`${API.LIST_PRODUCTS}${documentNumber}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	if (response?.data) {
		return response.data.filter((d) => d.isActive);
	}

	return null;
};

export default getProducts;
