/* eslint-disable */

import React, { useEffect } from 'react';

import {
    OptionDetails,
    DetailsResult
} from './styles';

import PropTypes from "prop-types";

const CreditOptionDetails = ( { creditOptionDetails, isMax }) => {

    useEffect(() => {

    }, [])

    const limit = isMax? creditOptionDetails.maxLimit : creditOptionDetails.limit;
    const usedLimit = !creditOptionDetails?.utilizedLimit ? 0 : creditOptionDetails.utilizedLimit;
    const readyLimit = limit - usedLimit;
    const readyMargin = !creditOptionDetails?.margin ? 0 : creditOptionDetails.margin;

    return (<OptionDetails>
        + Limite {isMax? <b>máximo </b> : ''}Ali: {limit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br />
        - Contratos Ativos: {usedLimit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br />
        = Limite Disponível: {readyLimit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br />
        <DetailsResult />
        Margem consignável: {readyMargin.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        {!isMax ? <><br />Limite Máximo Pela Margem: {limit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</> : ''}
    </OptionDetails>);
};

CreditOptionDetails.propTypes = {
  creditOptionDetails: PropTypes.any,
  isMax: PropTypes.any
}

export default CreditOptionDetails;
