import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  max-width: 500px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
`;

export const LoadTag = styled.div`
  margin-top: 8px;
  color: #d0d0d0;
  font-size: 12px;
  text-align: center;
`;

export const NoOffers = styled.div`
  padding-top: 5px;
  width: 100%;
  font-size: 12px;
  color: #FF0000;
  font-weight: bolder;
  text-align: center; 
`;

export const Retry = styled(Link)`
  width: 100%;
  font-size: 10px;
  display: inline-block;
  text-align: center;
`;

export const ChangeProduct = styled(Link)`
  width: 100%;
  font-size: 10px;
  display: inline-block;
  text-align: center;
`;

export const OptionDetails = styled.div`
  padding-top: 5px;
  width: 100%;
  font-size: 10px;
  color: #919AA7;
`;

export const DetailsResult = styled.div`
  padding-top: 5px;
  width: 100%;
  border-bottom: 1px solid #919AA7;
  margin-bottom: 5px;
`;

export const SimpleText = styled.div`
    width: 100%;
    font-size: 10px;
    display: inline-block;
    text-align: center;
`;

export const CancelShoppingCart = styled(Link)`
  color: #FF6347;
  width: 100%;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #FF6347;
  border-radius: 5px;
  &:hover {
    color: white;
    background-color: #FF6347;
  }
`;

export const ConfirmShoppingCartCancelling = styled(Link)`
  color: #ff2700;
  width: 100%;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ff2700;
  border-radius: 5px;
  &:hover {
    color: white;
    background-color: #ff2700;
  }
`;