import React from "react";
import PropTypes from "prop-types";
import logo from "./assets/logo.png";

import { Container, Logo, SignOutWrapper } from "./styles";
import { SignOutButton } from "pages/Login/components/SignOutButton";

const Header = ({ noLogout }) => (
	<Container>
		Portal de Operações
		<Logo src={logo} alt="logo" />
		{!noLogout && (
			<SignOutWrapper>
				<SignOutButton />
			</SignOutWrapper>
		)}
	</Container>
);

Header.defaultProps = {
	noLogout: false,
};

Header.propTypes = {
	noLogout: PropTypes.bool,
};

export default Header;
