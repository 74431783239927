import React from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';

const NavTab = ({ index, ...rest }) => (
  <Tab
    id={`vertical-tab-${index}`}
    aria-controls={`vertical-tabpanel-${index}`}
    {...rest}
  />
);

NavTab.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default NavTab;
