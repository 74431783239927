import React, { useEffect, useState } from "react";
import "date-fns";
import { FormGroup, FormControl, Input, InputLabel, FormHelperText, Button, Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Header from "components/Header";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { Container, Content, FormLabel } from "./styles";
import validator from "validator";
import { cpf } from "cpf-cnpj-validator";
import createUser from "../../api/alicore/createUser";
import Alert, { ALERT_TYPES } from "components/Alert";
import { cpfMask, getCleanNationalDocument, getCleanCellphone, getFormatedBirthDate } from "./helpers";

export const useRegisterState = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [alertState, setAlertState] = useState({ children: "", visible: false });
	// Validation States
	const [isCpfValid, setIsCpfValid] = useState();
	const [isNameValid, setIsNameValid] = useState();
	const [isBirthdayValid, setIsBirthdayValid] = useState();
	const [isEmailValid, setIsEmailValid] = useState();
	const [isCellphoneValid, setIsCellphoneValid] = useState();
	// Form States
	const [nationalDocument, setNationalDocument] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [birthDate, setBirthDate] = useState(new Date("01/01/1900"));
	const [cellphone, setCellphone] = useState("");
	const employeeTypes = ["Comum", "Sócio"];
	const [employeeType, setEmployeeType] = useState("Comum");

	const handleOnCpfChange = (value) => {
		const cpfValue = cpfMask(value.target ? value.target.value : value);
		setNationalDocument(cpfValue);
		setIsCpfValid(cpf.isValid(cpfValue));
	};

	const handleDateChange = (date) => {
		const newDate = new Date("01/01/1900");
		setBirthDate(date);
		setIsBirthdayValid(date !== null && date.getTime() !== newDate.getTime());
	};

	const handleNameChange = (value) => {
		const nameValue = value.target.value;
		setName(nameValue);
		setIsNameValid(nameValue.length > 4);
	};

	const handleEmailChange = (value) => {
		const emailValue = value.target.value;
		setEmail(emailValue);
		setIsEmailValid(validator.isEmail(emailValue));
	};

	const handleEmployeeTypeChange = (value) => {
		const employeeTypeValue = value.target.value;
		setEmployeeType(employeeTypeValue);
	};

	const handleCellphoneChange = (value) => {
		const cellphoneValue = value.target.value.replace(/\D/g, "");
		const celularFormatado = `(${cellphoneValue.substring(0, 2)}) ${cellphoneValue.substring(2, 7)}-${cellphoneValue.substring(7, 11)}`;
		setCellphone(celularFormatado);
		setIsCellphoneValid(cellphoneValue.length > 10);
	};

	const handleOnCloseAlert = () => {
		setAlertState({ ...alertState, visible: false });
	};

	const fullValidation = isCpfValid && isNameValid && isBirthdayValid && isEmailValid && isCellphoneValid;

	const tomatoColor = {
		color: "tomato",
	};

	const SendCreateRequests = async () => {
		try {
			setIsLoading(true);
			if (fullValidation) {
				const payload = {
					name: name,
					birthDate: getFormatedBirthDate(birthDate),
					nationalDocument: getCleanNationalDocument(nationalDocument),
					email: email,
					phoneNumber: getCleanCellphone(cellphone),
					employeeType: employeeType === "Sócio" ? "partner" : "common",
					avoidSmsAndMailSending: true,
					customer: {
						Consent: {
							browser: process.env.REACT_APP_DEFAULT_BROWSER,
							system: process.env.REACT_APP_DEFAULT_SYSTEM,
							ip: process.env.REACT_APP_DEFAULT_IP,
							answer: true,
						},
					},
				};

				const user = await createUser(payload);
				if (user?.status === 200 || user?.status === 201) {
					sessionStorage.setItem("ALI_REGISTER_CUSTOMER", payload.nationalDocument);
					history.replace("/");
					return;
				}
				if (user?.status === 403) {
					setAlertState({ type: ALERT_TYPES.ERROR, children: user.message, visible: true });
				}
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const cpf = sessionStorage.getItem("ALI_REGISTER_CUSTOMER");
		if (cpf) {
			setNationalDocument(cpf);
			handleOnCpfChange(cpf);
			sessionStorage.removeItem("ALI_REGISTER_CUSTOMER");
		}
	}, []);

	return {
		handleNameChange,
		isNameValid,
		name,
		tomatoColor,
		birthDate,
		handleDateChange,
		isBirthdayValid,
		nationalDocument,
		handleOnCpfChange,
		isCpfValid,
		handleEmailChange,
		email,
		isEmailValid,
		cellphone,
		handleCellphoneChange,
		isCellphoneValid,
		handleEmployeeTypeChange,
		employeeType,
		employeeTypes,
		alertState,
		handleOnCloseAlert,
		isLoading,
		fullValidation,
		SendCreateRequests,
		history,
	};
};

export default function Register() {
	const {
		handleNameChange,
		isNameValid,
		name,
		tomatoColor,
		birthDate,
		handleDateChange,
		isBirthdayValid,
		nationalDocument,
		handleOnCpfChange,
		isCpfValid,
		handleEmailChange,
		email,
		isEmailValid,
		cellphone,
		handleCellphoneChange,
		isCellphoneValid,
		handleEmployeeTypeChange,
		employeeType,
		employeeTypes,
		alertState,
		handleOnCloseAlert,
		isLoading,
		fullValidation,
		SendCreateRequests,
		history,
	} = useRegisterState();

	return (
		<Container>
			<Header />
			<Content>
				<FormGroup>
					<FormControl>
						<FormLabel className="">Cadastro de Colaborador.</FormLabel>
					</FormControl>

					<FormControl>
						<InputLabel htmlFor="form-name">Nome</InputLabel>
						<Input id="form-name" aria-describedby="my-helper-text" required onChange={handleNameChange} value={name} autoFocus />
						{!isNameValid && (
							<FormHelperText id="my-helper-text" style={tomatoColor}>
								O nome preenchido é inválido.
							</FormHelperText>
						)}
						<FormHelperText id="my-helper-text">Nome Completo do Cliente.</FormHelperText>
					</FormControl>

					<FormControl>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								invalidDateMessage="Preencha uma data de nascimento válida."
								required
								disableToolbar
								variant="inline"
								format="dd/MM/yyyy"
								margin="normal"
								id="date-picker-inline"
								label="Data de nascimento"
								value={birthDate}
								onChange={handleDateChange}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
						{!isBirthdayValid && (
							<FormHelperText id="my-helper-text" style={tomatoColor}>
								Favor preencher uma data de nascimento válida.
							</FormHelperText>
						)}
					</FormControl>

					<FormControl>
						<InputLabel htmlFor="form-cpf">Cpf do cliente</InputLabel>
						<Input
							id="form-cpf"
							aria-describedby="Cpf do cliente"
							required
							value={nationalDocument}
							onChange={handleOnCpfChange}
							maxLength="14"
						/>
						{!isCpfValid && (
							<FormHelperText id="my-helper-text" style={tomatoColor}>
								O CPF informado é inválido.
							</FormHelperText>
						)}
						<FormHelperText id="my-helper-text">Cpf do cliente, não é aceito outro documento.</FormHelperText>
					</FormControl>

					<FormControl>
						<InputLabel htmlFor="form-email">Email</InputLabel>
						<Input id="form-email" aria-describedby="my-helper-text" required onChange={handleEmailChange} value={email} />
						{!isEmailValid && (
							<FormHelperText id="my-helper-text" style={tomatoColor}>
								O email é inválido.
							</FormHelperText>
						)}
						<FormHelperText id="my-helper-text">Este e-mail ira receber o contrato para assinatura.</FormHelperText>
					</FormControl>

					<FormControl>
						<InputLabel htmlFor="form-email">Numero de Telefone</InputLabel>
						<Input id="form-email" aria-describedby="my-helper-text" required value={cellphone} onChange={handleCellphoneChange} />
						{!isCellphoneValid && (
							<FormHelperText id="my-helper-text" style={tomatoColor}>
								O número de telefone é inválido.
							</FormHelperText>
						)}
						<FormHelperText id="my-helper-text">Numero do telefone celular do cliente, de preferencia com whatsapp.</FormHelperText>
					</FormControl>

					<FormControl style={{ marginTop: 15 }}>
						<InputLabel htmlFor="form-type">Tipo de colaborador</InputLabel>
						<Select id="form-type" aria-describedby="my-helper-text" required onChange={handleEmployeeTypeChange} value={employeeType}>
							{employeeTypes.map((value) => {
								return (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<Alert {...alertState} onClose={handleOnCloseAlert} />

					<FormControl>
						<Button
							style={{ marginTop: 15 }}
							variant="contained"
							color="primary"
							disabled={isLoading || !fullValidation}
							onClick={() => SendCreateRequests()}
						>
							Cadastrar
						</Button>
						<Button
							style={{ marginTop: 5 }}
							variant="contained"
							color="secondary"
							onClick={() => {
								history.replace("/");
							}}
						>
							Voltar
						</Button>
					</FormControl>
				</FormGroup>
			</Content>
		</Container>
	);
}
