const TOKEN_SESSION_ID = 'ALI_ACCESS_TOKEN';
let SmsCodeToken;

const sendData = async (method, path, bodyJson, noAuth) => {
  const clearToken = () => {
    sessionStorage.removeItem(TOKEN_SESSION_ID);
  };
  const readAccessToken = () => sessionStorage.getItem(TOKEN_SESSION_ID);

  const params = {
    method,
    headers: {},
  };

  if (bodyJson) {
    params.body = JSON.stringify(bodyJson);
    params.headers["Content-Type"] = "application/json";
  }

  if (!noAuth) {
    const accessToken = readAccessToken();

    if (accessToken) {
      params.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  const url = path;

  let ret;
  try {
    ret = await fetch(url, params);
  } catch (error) {
    clearToken();
    return { error };
  }

  const { status } = ret;

  try {
    ret = await ret.json();
  } catch (error) {
    return { status, error };
  }

  return { status, ...ret };
};

export const saveAccessToken = (accessToken) => {
  sessionStorage.setItem(TOKEN_SESSION_ID, accessToken);
};

export const setSmsCodeToken = (codeToken) => {
  SmsCodeToken = codeToken;
};

export const getSmsCodeToken = () => SmsCodeToken;
export const getData = (path, noAuth) => sendData('GET', path, noAuth);
export const postData = (path, bodyJson, noAuth) => sendData('POST', path, bodyJson, noAuth);
export const updateData = (path, bodyJson, noAuth) => sendData('PUT', path, bodyJson, noAuth);
