import { API } from "config";
import axios from "axios";

const generateIRReportPDF = async (employeeDocumentNumber, date) => {
	const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");

	return await axios
		.post(
			`${API.GENERATE_IR_REPORT_PDF}`,
			{
				employeeDocumentNumber,
				date,
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
		.catch((error) => {
			return { status: error?.response?.data?.statusCode, ...error?.response?.data };
		});
};

export default generateIRReportPDF;
