import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PropTypes from "prop-types";

import { getUserRoles } from "../../../auth/user";

import { ContainerMenu } from "./styles";

const ITEM_HEIGHT = 30;

function ThreePointsMenu({ handleEditOnClick, restrictionOnClick, dataRestriction, handleIRReportOnClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    <button key="edit" onClick={handleEditOnClick} onKeyDown={handleEditOnClick} style={{ all: "unset", cursor: "pointer" }}>
      <strong style={{ color: "#3F51B5", fontSize: 14 }}>
        <EditIcon color="primary" fontSize="small" /> Editar
      </strong>
    </button>,
    <button key="restriction" onClick={restrictionOnClick} onKeyDown={restrictionOnClick} style={{ all: "unset", cursor: "pointer" }}>
      {!dataRestriction.hrRestriction ? (
        <strong style={{ color: "#3F51B5", fontSize: 14 }}>
          <LockIcon color="primary" fontSize="small" /> Bloquear
        </strong>
      ) : (
        <strong style={{ color: "#3F51B5", fontSize: 14 }}>
          <LockOpenIcon color="primary" fontSize="small" /> Desbloquear
        </strong>
      )}
    </button>,
    <button key="irf" onClick={handleIRReportOnClick} onKeyDown={handleIRReportOnClick} style={{ all: "unset", cursor: "pointer" }}>
      <strong style={{ color: "#3F51B5", fontSize: 14 }}>
        <PictureAsPdfIcon color="primary" fontSize="small" /> Baixar Informe de IR
      </strong>
    </button>,
  ];

  useEffect(() => {
    const userRoles = getUserRoles();
    const hasAdminRole = userRoles?.find((role) => role?.toLowerCase() == "admin");
    setIsAdmin(hasAdminRole);
  }, []);

  return (
    <ContainerMenu style={{ display: isAdmin ? "block" : "none" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon color="primary" />
      </IconButton>
      <Menu
        id="long-menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={`menu-${index + 1}`} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </ContainerMenu>
  );
}

ThreePointsMenu.propTypes = {
  handleEditOnClick: PropTypes.func,
  restrictionOnClick: PropTypes.func,
  dataRestriction: PropTypes.shape({
    hrRestriction: PropTypes.bool,
  }),
  handleIRReportOnClick: PropTypes.func,
};

export default ThreePointsMenu;