import { API } from "config";
import axios from "axios";

const getCompanies = async () => {
	const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
	const response = await axios
		.get(`${API.LIST_COMPANIES}`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	return response?.data ?? [];
};

export default getCompanies;
