import { onlyNumbersString } from 'utils';
import { API } from 'config';
import axios from 'axios';

import { handleCustomerOverview } from './searchCpf';

const getCustomer = async (cpf, registration) => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');

	try {
		const response = await axios
			.post(
				API.GET_CUSTOMER,
				{
					registration,
					documentNumber: onlyNumbersString(cpf),
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
			.catch((e) => {
				if (!e.response) {
					return { statusCode: 403 };
				}

				return { ...e?.response, statusCode: e?.response.status };
			});

		if (response && response.statusCode == 403) {
			return { invalidNetwork: true };
		}

		if (!response?.data) {
			return null;
		}

		return {
			goTo: response.data.goTo,
			customer: handleCustomerOverview(response.data.customer.data),
		};
	} catch (error) {
		console.error(error);
	}

	return false;
};

export default getCustomer;
