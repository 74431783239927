import { AZURE_ACCESS_TOKEN } from "config";

let userData = JSON.parse(sessionStorage.getItem(AZURE_ACCESS_TOKEN) || "{}");

export const getToken = () => userData.tokenId;

export const getUserData = () => userData.user;

export const getUserId = () => userData.user?.id;

export const getUserRoles = () => userData.roles;

export const clearUserData = () => {
	userData = {};
	sessionStorage.removeItem(AZURE_ACCESS_TOKEN);
	sessionStorage.removeItem("ALI_CURRENT_CUSTOMER");
};

export const saveUserData = (tokenId, user, roles) => {
	userData = {
		tokenId,
		user,
		roles,
	};

	sessionStorage.setItem(AZURE_ACCESS_TOKEN, JSON.stringify(userData));
};
