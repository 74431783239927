import styled from "styled-components";
import { Button } from "@material-ui/core";

export const Warning = styled.div`
	color: red;
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 5px;
`;

export const Modal = styled.div`
	inset: 50% auto auto 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 500px;
	border: 1px solid rgb(153, 153, 153);
	position: fixed;
	z-index: 999;
	border-radius: 12px;
	box-shadow: rgb(0 0 0 / 20%) 3px 3px 5px 5px;
	padding: 15px;
`;

export const CloseButton = styled.img`
	height: 20px;
	position: absolute;
	right: 6px;
	top: 6px;
	cursor: pointer;
`;

export const ModalTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: #031e52;
	padding-bottom: 24px;
`;

export const ButtonOk = styled(Button)`
	float: right;
`;

export const TextWrapper = styled.div``;

export const TableWrapper = styled.div`
	border-radius: 8px;
	padding-bottom: 16px;
	height: fit-content;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Td = styled.div`
	padding: 2px 0;
	min-width: 270px;
	background-color: "green";
	vertical-align: top;
`;

export const TdLeft = styled.div`
	vertical-align: top;
	white-space: nowrap;
	padding-right: 16px;
	color: #666666;
`;
