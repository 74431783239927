

import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import shoppingCartCancel from 'api/alicore/shoppingCartCancel';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MessageField } from '_FUTURE_LIBS_/@ali-forms';
import { Flex } from '_FUTURE_LIBS_/@ali-ui';

import { SnackbarContext } from 'contexts/SnackbarProvider';
import {
  Container,
  Content,
  ButtonWrapper,
} from './styles';
import PropTypes from "prop-types";

const AWAITING_CREDIT_VALIDATION = "awaitingCreditValidation";
const AWAITING_ONBOARDING_BTG = "awaitingOnboardingBtg";
const RECALCULATING = "recalculating";
const AWAITING_FINANCIAL_INTEGRATION = "awaitingFinancialIntegration";
const AWAITING_CCB_NUMBER = "awaitingCcbNumber";
const AWAITING_CONTRACT_GENERATION = "awaitingContractGeneration";
const AWAITING_CCONTRACT_SIGN = "awaitingContractSign";
const AWAITING_HR_APPROVAL = "awaitingHrApproval";
const AWAITING_LOAN_MANAGER_HR_APPROVAL = "awaitingLoanManagerHrApproval";
const AWAITING_PAYMENT = "awaitingPayment";
const FINISHED = "finished";
const DECLINED = "declined";
const CANCELED = "canceled";
const EXPIRED = "expired";


const CANT_CANCEL_STATUS_LIST = [
  AWAITING_CREDIT_VALIDATION,
  AWAITING_ONBOARDING_BTG,
  RECALCULATING,
  AWAITING_FINANCIAL_INTEGRATION,
  AWAITING_CCB_NUMBER,
  AWAITING_CONTRACT_GENERATION,
  AWAITING_CCONTRACT_SIGN,
  AWAITING_HR_APPROVAL,
  AWAITING_LOAN_MANAGER_HR_APPROVAL,
  AWAITING_PAYMENT,
  FINISHED,
  DECLINED,
  CANCELED,
  EXPIRED
];

const CancelProposal = ({shoppingCart}) => {
  const [showSnackbar] = useContext(SnackbarContext);

  const [abandonReason, setAbandonReason] = useState('');
  const [nameOperator, setNameOperator] = useState();
  const [emailOperator, setEmailOperator] = useState();
  const [shouldCancel, setShouldCancel] = useState(false);

  const history = useHistory();

  const getOperatorData = () => {
    const dataStg = sessionStorage.getItem('HEADER_TOKEN_KEY_AUTH');
    const obj = JSON.parse(dataStg);
    setNameOperator(obj.user.name);
    setEmailOperator(obj.user.email);
  }

  const onCancelProposal = async () => {
    const result = await shoppingCartCancel(shoppingCart.id, abandonReason, emailOperator);

    if (result) {
      sessionStorage.removeItem('ALI_CURRENT_CUSTOMER');
      history.replace('/');
    } else {
      showSnackbar({
        message: `Houve algum problema ao tentar cancelar a proposta.`,
        severity: 'error',
        duration: 6000,
      });
    }
  }

  const onChangeAbandonReason = (value) => {
    setAbandonReason(value);
  };

  useEffect(() => {
    getOperatorData();
    if (!shoppingCart.proposal) {
      setShouldCancel(true);
    } else {
      const should = CANT_CANCEL_STATUS_LIST.every(s => s !== shoppingCart.proposal.status)
      setShouldCancel(should);
    }
  }, []);

  return (shouldCancel &&
    <Container>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <b>CANCELAR PROPOSTA</b>
        </AccordionSummary>
        <AccordionDetails>
          <Content>
            <Flex>
              <MessageField
                label="Qual o motivo do cancelamento?"
                value={abandonReason}
                placeholder="Digite aqui o motivo"
                onChange={onChangeAbandonReason}
                fullWidth
              />
            </Flex>
            <Flex>
              Cancelado por <b>{nameOperator}</b>
            </Flex>
            <Flex>
              Email: <b>{emailOperator}</b>
            </Flex>
            <ButtonWrapper>
              <Button
                variant="contained"
                color="secondary"
                disabled={!abandonReason || abandonReason.length <= 2}
                onClick={onCancelProposal}
                fullWidth
              >
                Cancelar Proposta
              </Button>
            </ButtonWrapper>
          </Content>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

CancelProposal.propTypes = {
  shoppingCart: PropTypes.any,
}

export default CancelProposal;
