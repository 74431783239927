import { API } from "config";
import axios from "axios";

const getCountries = async () => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");

  const response = await axios
    .get(API.GET_COUNTRIES, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => {});

  return response?.data?.countries ?? [];
};

export default getCountries;
