import React from 'react';
import AgreementDetail from 'components/AgreementDetail';
import { TabContent } from '../styles';

const UserAgreement = () => {

  return (
    <TabContent>
      <AgreementDetail />
    </TabContent>
  );
};

export default UserAgreement;
