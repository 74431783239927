import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';

const SmsCode = ({
  label,
  onChange,
  value,
  autoFocus,
}) => (
  <TextField
    data-testid="field-sms"
    value={value}
    label={label}
    onChange={onChange}
    fontSize="15px"
    letterSpacing="0px"
    inputMode="decimal"
    format={{
      delimiters: [' ', ' ', ' ', ' ', ' ', ' '],
      blocks: [1, 1, 1, 1, 1, 1],
      numericOnly: true,
      delimiterLazyShow: true,
    }}
    autoFocus={autoFocus}
  />
);

/* istanbul ignore next */
SmsCode.defaultProps = {
  label: 'Código SMS',
  onChange: () => {},
  autoFocus: false,
  value: '',
};

SmsCode.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default SmsCode;
