import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Spacer from '../../Spacer';
import MaskedTextField from './MaskedTextField';
import { Container, LabelWrapper, StyledTextField } from './styles';

const MODE_BOOTSTRAP = true;
const variant = 'outlined';
const size = 'small';
const marginBottom = '-2px';

const TextField = ({
  label,
  placeholder,
  value,
  onChange,
  onEnterPressed,
  format,
  fontSize,
  letterSpacing,
  error,
  inputMode,
  autoCapitalize,
  onBlur,
  onFocus,
  disabled,
  autoFocus,
  inputRef,
  noBorderChangeOnHover,
  'data-testid': dataTestid,
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onEnterPressed) {
      onEnterPressed();
    }
  };

  /* istanbul ignore next */
  const inputComponent = format ? MaskedTextField : 'input';

  return (
    <Container>
      {MODE_BOOTSTRAP && (
        <InputLabel
          shrink
          style={{
            color: '#919AA7',
            fontSize: '16px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          <LabelWrapper>
            {label}
          </LabelWrapper>
        </InputLabel>
      )}
      <StyledTextField
        fullWidth
        noborderchangeonhover={noBorderChangeOnHover.toString()}
        variant={variant}
        size={size}
        error={!!error}
        /* istanbul ignore next */
        label={!MODE_BOOTSTRAP && label}
        placeholder={placeholder}
        helperText={error}
        value={value}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        autoFocus={autoFocus}
        inputProps={{
          ref: inputRef,
          inputMode,
          format,
          autoCapitalize,
          'data-testid': dataTestid,
        }}

        InputProps={{
          inputComponent,
          style: {
            fontSize,
            letterSpacing,
            marginBottom,
            height: '42px',
            color: '#031E52',
            fontFamily: 'Poppins, sans-serif',
            opacity: disabled ? 0.8 : 1,
          },
        }}
        InputLabelProps={{
          shrink: !!value,
        }}
        FormHelperTextProps={{
          style: {
            marginLeft: 0,
            fontWeight: 'bold',
          },
        }}
        style={{
          marginTop: '5px',
          minHeight: '42px',
        }}
      />
      <Spacer height={1} />
    </Container>
  );
};

/* istanbul ignore next */
TextField.defaultProps = {
  label: '',
  placeholder: '',
  onChange: () => {},
  onEnterPressed: null,
  onBlur: () => {},
  onFocus: () => {},
  value: '',
  format: {
    blocks: [99999],
    delimiter: '',
  },
  fontSize: '15px',
  letterSpacing: 'inherit',
  error: false,
  inputMode: 'text',
  autoCapitalize: null,
  disabled: false,
  'data-testid': null,
  autoFocus: false,
  inputRef: null,
  noBorderChangeOnHover: false,
};

TextField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnterPressed: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  format: PropTypes.objectOf(PropTypes.any),
  fontSize: PropTypes.string,
  letterSpacing: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  inputMode: PropTypes.string,
  autoCapitalize: PropTypes.string,
  disabled: PropTypes.bool,
  'data-testid': PropTypes.string,
  autoFocus: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
  noBorderChangeOnHover: PropTypes.bool,
};

export default TextField;
