
import React from 'react';
import PropTypes from 'prop-types';
import DateField from '../DateField';

const IssuerDateField = ({ label, placeholder, ...rest }) => {
  IssuerDateField.isValid = () => DateField.isValid();

  return (
    <DateField
      data-testid="test-document-issuer-date"
      label={label}
      placeholder={placeholder}
      {...rest}
    />
  );
};

IssuerDateField.defaultProps = {
  label: 'Data de expedição',
  placeholder: 'Digite aqui a data de expedição',
};

IssuerDateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default IssuerDateField;
