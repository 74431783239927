import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const ClientContext = createContext();

const ClientProvider = ({ children }) => {
  const [client, setClient] = useState(null);

  const contextValue = useMemo(() => [client, setClient], [client]);

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

ClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClientProvider;
