import React, { useState, useEffect, useContext } from 'react';
import ShoppingCartList from 'components/ShoppingCartList';
import { ClientContext } from 'contexts/ClientProvider';
import { SnackbarContext } from 'contexts/SnackbarProvider';
import getShoppingCartListByCPF from 'api/alicore/getShoppingCartListByCPF';
import { TabContent } from '../styles';
import { TitlePage } from './styles';

const ShoppingCart = () => {

  const [data, setData] = useState();

  const [client] = useContext(ClientContext);
  const [showSnackbar] = useContext(SnackbarContext);

  useEffect(() => {
    getShoppingCartListByCPF(client.user.document).then((response) => {
      if (response){
        setData(response)
      } else {
        showSnackbar({
          message: `Houve algum problema ao buscar os carrinhos. Tente novamente e caso não funcione contate o suporte.`,
          severity: 'error',
          duration: 6000,
        });
      }
    });
  }, []);

  return (
    <>
    { (data && data.length !== 0)? (
      <>
      <TitlePage>
        Detalhes das Simulações
      </TitlePage>
      <TabContent>
        <ShoppingCartList data={data} />
      </TabContent>
      </>
    ) :
      <TabContent>
        <TitlePage>
          Não existe nenhuma simulação vinculada a este CPF.
        </TitlePage>
      </TabContent>
    }
    </>
  );
};

export default ShoppingCart;
