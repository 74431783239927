export const fonts = {
  fontFamily: `
    font-family: Poppins;
  `,
  pageTitle: `
    font-size: 21px;
    font-weight: normal;
  `,
  button: `
    font-size: 15px;
    font-weight: 600;
  `,
  boxText: `
    font-size: 13px;
    font-weight: normal;
  `,
  boxTitleText: `
    font-size: 15px;
    font-weight: 600;
  `,
  clickableBox: `
    font-size: 12px;
    font-weight: normal;
  `,
  clickableBoxTitle: `
    font-size: 14px;
    font-weight: 600;
  `,

  uploadBoxTitle: `
    font-size: 15px;
    font-weight: 600;
  `,

  colorBox: `
    font-size: 13px;
    font-weight: normal;
  `,
  colorBoxSpotlight: `
    font-size: 21px;
    font-weight: 600;
  `,
};
