import { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';

import Simulation from '.';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const Transition = forwardRef((props, ref) => {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SimulationModal = ({ disabled, installmentsOptions, total, selectedProduct }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button variant="contained" color="default" onClick={handleClickOpen} disabled={disabled} fullWidth>
				Download da simulação
			</Button>

			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar} color="default">
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>

				<Simulation installmentsOptions={installmentsOptions} total={total} selectedProduct={selectedProduct} />
			</Dialog>
		</>
	);
};

SimulationModal.propTypes = {
  disabled: PropTypes.bool,
  total: PropTypes.number,
  selectedProduct: PropTypes.string,
  installmentsOptions: PropTypes.arrayOf(
		PropTypes.shape({
			numberOfPayableInstallments: PropTypes.number,
			installmentAmount: PropTypes.number,
      firstInstallmentDate: PropTypes.string,
		})
	),
}

export default SimulationModal