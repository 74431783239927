import { API } from 'config';

const getPriorityPolice = async (cpf) => {
  try {
    const response = await fetch(API.GET_PRIORITY_POLICE_URL.replace('{cpf}', cpf), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const historyPolice = await response.json();
      return {
        success: true,
        failure: false,
        status: response.status,
        value: historyPolice,
      };
    }
    return {
      success: false,
      failure: true,
      status: response.status,
    };
  } catch (error) {
    return {
      success: false,
      failure: true,
      error,
    };
  }
};

export default getPriorityPolice;
