import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap:wrap;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;

export const Icon = styled.div`
  color: #f50057;
  font-size: 12px;
  cursor: pointer;
`;