import { useCallback, useEffect, useRef } from 'react';
import { createFileName, useScreenshot } from 'use-react-screenshot';

export function useDownloadScreenShot({ fileName, extension } = { fileName: 'img', extension: 'png' }) {
	const ref = useRef(null);
	const [image, takeScreenShot] = useScreenshot();

	const download = useCallback((image) => {
		const a = document.createElement('a');
		a.href = image;
		a.download = createFileName(extension, fileName);
		a.click();
	}, [fileName, extension]);

	function getImage() {
    takeScreenShot(ref.current);
  };

	useEffect(() => {
		if (image) {
			download(image);
		}
	}, [image]);

	return {
    ref,
    getImage
  };
}
