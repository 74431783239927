
import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';

const StreetComplementField = ({
  label,
  placeholder,
  onChange,
  value,
  ...rest
}) => (
  <TextField
    data-testid="field-street-complement"
    label={label}
    placeholder={placeholder}
    onChange={onChange}
    value={value}
    noValidation
    {...rest}
  />
);

/* istanbul ignore next */
StreetComplementField.defaultProps = {
  label: 'Complemento (opcional)',
  placeholder: 'Digite aqui o complemento',
  onChange: () => {},
  value: '',
};

StreetComplementField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default StreetComplementField;
