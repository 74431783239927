import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isValid from './validation';

export const ERROR_MSG = 'Dados inválidos';

const DocumentIssuerField = ({
  label,
  placeholder,
  onChange,
  value,
  noValidation,
}) => {
  const [error, setError] = useState(false);

  const handleOnChange = (documentIssuer) => {
    onChange(documentIssuer);
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && !isValid(value);
      setError(invalid && ERROR_MSG);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  DocumentIssuerField.isValid = () => (value !== '' && isValid(value));

  return (
    <TextField
      data-testid="test-document-issuer"
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      error={error}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
    />
  );
};

/* istanbul ignore next */
DocumentIssuerField.defaultProps = {
  label: 'Órgão expedidor e Estado de emissão',
  placeholder: 'Digite aqui o órgão expedidor e Estado',
  onChange: () => {},
  value: '',
  noValidation: false,
};

DocumentIssuerField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
};

export default DocumentIssuerField;
