import { API } from "config";
import axios from "axios";

const updateCustomer = async (userId, name, birthDate, phone, email) => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios
    .post(
      `${API.CUSTOMER_UPDATE}`,
      {
        userId, name, birthdate: birthDate, phone, email
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .catch((e) => {});

  return (response && response.status == 204);
};

export default updateCustomer;
