import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ClientContext } from "contexts/ClientProvider";
import { getUserRoles } from "auth/user";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Header from "components/Header";
import TabPanel from "components/TabPanel";
import UserInfos from "./UserInfos";
import UserProposals from "./UserProposals";
import PriorityPolice from "./PriorityPolice";
import UserAgreement from "./UserAgreement";
import ShoppingCart from "./ShoppingCart";
import Promotions from "./Promotions";
import { Container, Content } from "./styles";
import NavTab from "./NavTab";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		height: 224,
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

const User = () => {
	const [, setClient] = useContext(ClientContext);
	const [value, setValue] = useState(0);
	const classes = useStyles();
	const history = useHistory();

	const navData = [
		{
			label: "Cliente",
			panel: <UserInfos />,
			role: "",
		},
		{
			label: "Promoções",
			panel: <Promotions />,
			role: "",
		},
		{
			label: "Propostas",
			panel: <UserProposals />,
			role: "",
		},
		{
			label: "Convênio",
			panel: <UserAgreement />,
			role: "",
		},
		{
			label: "Simulações",
			panel: <ShoppingCart />,
			role: "",
		},
		{
			label: "Políticas",
			panel: <PriorityPolice />,
			role: "priority_policy",
		},
		{
			label: "Mudar Cliente",
			panel: null,
			role: "",
		},
	].filter(({ role }) => role === "" || getUserRoles().includes(role));

	const handleChange = (event, menuIndex) => {
		if (menuIndex === navData.length - 1) {
			setClient({
				user: null,
				agreement: null,
				proposals: [],
				proposalInProgress: null,
			});
			sessionStorage.removeItem("ALI_CURRENT_CUSTOMER");
			history.replace("/");
		}
		setValue(menuIndex);
	};

	return (
		<Container>
			<Header />
			<Content>
				<div className={classes.root}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs example"
						className={classes.tabs}
					>
						{navData.map(({ label }, index) => (
							<NavTab key={label} index={index} label={label} />
						))}
					</Tabs>
					{navData.map(({ panel, label }, index) => (
						<TabPanel key={label} value={value} index={index}>
							{panel}
						</TabPanel>
					))}
				</div>
			</Content>
		</Container>
	);
};

export default User;
