import { API } from 'config';
import axios from 'axios';

const getCreditReasons = async () => {
	const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');
	const response = await axios
		.get(API.LIST_CREDIT_REASONS, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.catch((e) => {});

	let out = [];

	if (response?.data) {
		out = response.data.map((r) => ({ id: r.id, name: r.FriendlyName, internalName: r.InternalName }));
	}

	return out;
};

export default getCreditReasons;
