import React, { useState } from 'react';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ProposalListDetail from 'components/ProposalListDetail';
import { statuses } from '../../helpers/statuses';
import {
  Container,
  NotInformed,
  Icon,
} from './styles';

const ShoppingCartList = ({ data }) => {

  const [visible, setVisible] = useState(false);
  const [idProposal, setIdProposal] = useState();
  

const visibleClick = (id) => {
  if (visible === true) {
    setIdProposal(id)
  }
  setVisible(!visible)
  setIdProposal(id)
}

  return (
    <Container>
      { visible === false ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{fontSize: 16}} align="center"><b>Status</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Produto</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Vl. Oferta</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Parcelas</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Vl. Parcela</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Mot. Crédito</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Crédito Dispon.</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>CET</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>IOF</b></TableCell>
              <TableCell style={{fontSize: 16}} align="center"><b>Proposta Nº</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((v) => {
              return(
                <TableRow key={v.id}>
                  <TableCell align="center">{statuses(v.status) || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v.product || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v?.amount?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v?.installmentsQuantity  ? `${v?.installmentsQuantity} x` : <NotInformed>(não informado)</NotInformed> }</TableCell>
                  <TableCell align="center">{v?.simulation?.installmentAmount?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v?.creditReason || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v?.firstOffer?.releasedAmount?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v?.firstOffer?.cet ? `${v?.firstOffer?.cet}%` : <NotInformed>(não informado)</NotInformed> }</TableCell>
                  <TableCell align="center">{v?.firstOffer?.totalIofAmount?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) || <NotInformed>(não informado)</NotInformed>}</TableCell>
                  <TableCell align="center">{v?.proposal || <NotInformed>(sem proposta vinculada)</NotInformed>}</TableCell>
                  { v.proposal && (
                    <TableCell>
                      <Icon>
                        <FindInPageIcon onClick={() => visibleClick(v?.proposal)} />
                      </Icon>
                    </TableCell>
                  )}
                </TableRow>
            )})}
          </TableBody>
        </Table>
      ) :
      (
        <ProposalListDetail idProposal={idProposal} setVisible={setVisible} />
      ) 
      }
    </Container>
  );

};
export default ShoppingCartList;
