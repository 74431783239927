import styled from "styled-components";
import { TabContent } from "../styles";

export const Content = styled(TabContent)`
	flex-direction: column;
	align-items: center;
	margin-top: 48px;
`;

export const Title = styled.div`
	position: absolute;
	display: flex;
	margin-top: -48px;
	font-size: 24px;
	/* font-weight: 500; */
`;

export const TableWrapper = styled.div`
	border: 1px solid ${({ noBorder }) => (noBorder ? "transparent" : "#e0e0e0")};
	border-radius: 8px;
	padding: 8px 16px;
`;

export const Tr = styled.tr`
	&:nth-child(even) {
		background-color: #f8f4fa;
	}
`;

export const Th = styled.th`
	padding: 4px 16px 8px 16px;
`;

export const Td = styled.td`
	padding: 12px 16px;
	text-align: right;
	white-space: nowrap;
	font-size: 14px;

	&:last-child {
		background: white;
		padding: 6px 4px;
	}
`;

export const StatusWrapper = styled.div`
	min-width: 460px;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-flow: column;
	width: 240px;
`;
