const isValid = (value) => {
  const validChars = /^[0-9.-]+$/;
  if (!value.match(validChars)) {
    return false;
  }

  const cpf = value.replace(/\D/g, '');
  
  return cpf.length === 8;
};

export default isValid;
