import PropTypes from 'prop-types';
import { SpacerStyled } from './styles';

const Spacer = SpacerStyled;

Spacer.defaultProps = {
  width: null,
  height: null,
  justify: null,
  onlyDesktop: false,
};

Spacer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  justify: PropTypes.string,
  onlyDesktop: PropTypes.bool,
};

export default Spacer;
