import React, { useState, useContext, useEffect } from 'react';
import { getUserRoles } from '../../auth/user';

import { useHistory } from 'react-router-dom';
import { ClientContext } from 'contexts/ClientProvider';
import { SnackbarContext } from 'contexts/SnackbarProvider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CurrencyField } from '_FUTURE_LIBS_/@ali-forms';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
} from '@material-ui/core';
import { Select, Spacer, Flex, FlexSpacer } from '_FUTURE_LIBS_/@ali-ui';
import simulateShoppingCart from 'api/alicore/simulateShoppingCart';
import shoppingCartDeal from 'api/alicore/shoppingCartDeal';
import retryFirstOffer from 'api/alicore/retryFirstOffer';
import {
	Container,
	Content,
	ButtonWrapper,
	LoadTag,
	NoOffers,
	Retry,
	ChangeProduct,
	SimpleText,
	CancelShoppingCart,
	ConfirmShoppingCartCancelling,
} from './styles';
import CreditOptionDetails from './CreditOptionDetails';
import shoppingCartCancel from 'api/alicore/shoppingCartCancel';
import {
	convertStringToNumber,
	toStringBrlCurrency,
	convertStringToFloat,
} from '../../utils';
import SimulationModal from 'components/Simulation/SimulationModal';

const ERROR_MSG_VALUE = 'O Valor mínimo é R$ 200,00';
const MINIMUM_LOAN_AMOUNT = 200;

const InSettingUp = ({ onUpdateData, onUpdateDocOrRetry }) => {
	const [client] = useContext(ClientContext);
	const [showSnackbar] = useContext(SnackbarContext);

	const [creditOption, setCreditOption] = useState('');
	const [creditOptions, setCreditOptions] = useState([]);
	const [creditOptionDetails, setCreditOptionDetails] = useState(null);
	const [targetAmount, setTargetAmount] = useState();
	const [amountChanged, setAmountChanged] = useState(false);
	const [loadingInstallments, setLoadingInstallments] = useState(false);
	const [installmentsOption, setInstallmentsOption] = useState('');
	const [installmentsOptions, setInstallmentsOptions] = useState([]);
	const [simulationInstallmentsOptions, setSimulationInstallmentsOptions] = useState([]);
  const [lastAmountSelected, setLastAmountSelected] = useState();
	const [, setInstallmentsOptionDetails] = useState(null);
	const [simulationOption, setSimulationOption] = useState([]);
	const [interestRatePerMonth, setInterestRatePerMonth] = useState();
	const [internalLoading, setInternalLoading] = useState(false);
	const [
		shoppingCartCancellingConfirmation,
		setShoppingCartCancellingConfirmation,
	] = useState(false);
	const [, setMaxLimit] = useState(0);
	const [error, setError] = useState();
	const [hasError, setHasError] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null)

	const history = useHistory();

	const firstOffer =
		typeof client?.shoppingCart?.firstOffer?.result === 'undefined'
			? ''
			: client.shoppingCart.firstOffer.result;
	const productForFirstOffer =
		typeof client?.shoppingCart?.firstOffer?.result === 'undefined'
			? ''
			: client.shoppingCart.product;

	const [emailOperator, setEmailOperator] = useState();

	const getOperatorData = () => {
		const dataStg = sessionStorage.getItem('HEADER_TOKEN_KEY_AUTH');
		const obj = JSON.parse(dataStg);
		setEmailOperator(obj.user.email);
	};

	const getValidInstallments = (option) => {
		return option.map((o) => ({
			value: o.numberOfPayableInstallments.toString(),
			text: `${o.numberOfPayableInstallments}x - ${toStringBrlCurrency(
				o.installmentAmount
			)} - Taxa de juros: ${parseFloat(o.interestRatePerMonth).toFixed(2)}%`,
		}));
	};

	const getCurrentInstallmentsOptions = () => {
		const option = amountChanged
			? simulationOption.result
			: firstOffer.filter((o) => o.type == creditOption)[0];
		return option.installmentOptions;
	};

	const onChangeCreditOption = (value) => {
		let result = [];
		if (client?.currentValidOffer?.result) {
			result = Array.isArray(client.currentValidOffer.result)
				? client.currentValidOffer.result
				: [client.currentValidOffer.result];
		}

		setCreditOption(value);

		const option = firstOffer.filter((o) => o.type == value)[0];
		const optionDetails = {
			maxLimit: option.valor_total,
			maxInstallmentQuantity: option.maxInstallmentQuantity,
			limit: option.limit,
			margin: option.margin,
			utilizedLimit: option.utilizedLimit,
		};
		setCreditOptionDetails(optionDetails);
		setTargetAmount(
			toStringBrlCurrency(result[0].valor_total || result[0].requested_amount)
		);
		setAmountChanged(false);

		let installments = getValidInstallments(result[0].installmentOptions);
		installments = installments.sort((a, b) => {
			return parseInt(a.value) - parseInt(b.value);
		});

    setSelectedProduct(option.product)
		setInstallmentsOptions(installments);
    setSimulationInstallmentsOptions(
      result[0].installmentOptions
    );
 		setInstallmentsOption('');
		setInstallmentsOptionDetails(null);
		setInterestRatePerMonth(null);
	};

	const onChangeTargetAmount = (value) => {
		const minValue = convertStringToNumber(value);
		if (isNaN(minValue) || minValue < MINIMUM_LOAN_AMOUNT) {
			setError(ERROR_MSG_VALUE);
			setHasError(true);
		} else {
			setError('');
			setHasError(false);
		}
		setTargetAmount(value);
	};

	const onTargetAmountBlur = (value) => {
		if (
			!value ||
			!parseFloat(value.toString().replace('.', '').replace(',', '.'))
		) {
			setInstallmentsOptions([]);
      setSimulationInstallmentsOptions([]);
			setInstallmentsOption('');
			setInstallmentsOptionDetails(null);
			setInterestRatePerMonth(null);
			setAmountChanged(true);
		}

		const numberValue = parseFloat(
			value.toString().replace('.', '').replace(',', '.')
		);
		const firstOfferResultItem = firstOffer.find((o) => o.type == creditOption);
		const firstOfferReleasedAmount =
			firstOfferResultItem.installmentOptions[0].releasedAmount;

		if (numberValue > firstOfferReleasedAmount) {
			const newValue = toStringBrlCurrency(firstOfferReleasedAmount).replace(
				'R$ ',
				''
			);
			alert('O Valor escolhido não pode ser maior que o limite do cliente.');
      setTargetAmount(0);
			onTargetAmountBlur(newValue);
			return;
		}

		let result = [];
		if (client?.currentValidOffer?.result) {
			result = Array.isArray(client.currentValidOffer.result)
				? client.currentValidOffer.result
				: [client.currentValidOffer.result];
		}

		const changed = lastAmountSelected != numberValue;
		if (changed) {
			setInstallmentsOptions([]);
      setSimulationInstallmentsOptions([]);
		} else {
			let installments = getValidInstallments(result[0].installmentOptions);
			installments = installments.sort((a, b) => {
				return parseInt(a.value) - parseInt(b.value);
			});
			setInstallmentsOptions(installments);
      setSimulationInstallmentsOptions(
        result[0].installmentOptions
      );
		}
    setLastAmountSelected(numberValue);
		setInstallmentsOption('');
		setInstallmentsOptionDetails(null);
		setInterestRatePerMonth(null);
		setAmountChanged(changed);
	};

	const onGetInstallments = () => {
		setLoadingInstallments(true);

		simulateShoppingCart(
			client.shoppingCart.id,
			convertStringToFloat(targetAmount)
		).then(simulation => {
      if (simulation) {
        setSimulationOption(simulation);
  
        let options = simulation.result.installmentOptions.map((o) => ({
          value: o.numberOfPayableInstallments.toString(),
          text: `${o.numberOfPayableInstallments}x - ${toStringBrlCurrency(
            o.installmentAmount
          )} - Taxa de juros: ${parseFloat(o.interestRatePerMonth).toFixed(2)}%`,
        }));
        options = options.sort((a, b) => {
          return parseInt(a.value) - parseInt(b.value);
        });
        setInstallmentsOptions(options);
        setSimulationInstallmentsOptions(simulation.result.installmentOptions);
      } else {
        showSnackbar({
          message: `Houve algum problema. Tente novamente e caso não funcione contate o suporte.`,
          severity: 'error',
          duration: 6000,
        });
      }
    }).finally(() => {
      setLoadingInstallments(false);
    });
	};

	const onChangeInstallmentsOption = (value) => {
		setInstallmentsOption(value);
		const option = getCurrentInstallmentsOptions().filter(
			(o) => o.numberOfPayableInstallments == value
		)[0];
		setInstallmentsOptionDetails({
      limit: option.limit,
			margin: option.margin,
			utilizedLimit: option.utilizedLimit,
			numberOfPayableInstallments: option.numberOfPayableInstallments,
    });
		setInterestRatePerMonth(option.interestRatePerMonth);
	};

	const onSendData = () => {
		setInternalLoading(true);

		const amount = convertStringToFloat(targetAmount);
		const installmentsQuantity = parseInt(installmentsOption);

		shoppingCartDeal(
			client.shoppingCart.id,
			amount,
			installmentsQuantity
		).then(async result => {
      if (result) {
        await onUpdateData();
      }
    }).finally(() => {
      setInternalLoading(false);
    });
	};

	const onRetry = async () => {
		setInternalLoading(true);
		const cpf = client.shoppingCart.user.nationalDocument;
		const result = await retryFirstOffer(client.shoppingCart.id, cpf);
	  if (!result?.data) {
      showSnackbar({
        message: result.message,
        severity: "error",
        duration: 10000,
      });
    }
    if (result?.data?.riskAuditMessage) {
      return showSnackbar({
        message: result?.data?.riskAuditMessage.operationMessage,
        severity: "error",
        duration: 6000,
      });
    }

    await onUpdateDocOrRetry();

    setInternalLoading(false);
	};

	const onChangeProduct = async () => {
		const abandonReasonWhenChangingProduct = 'Troca de produto';
		const result = await shoppingCartCancel(
			client.shoppingCart.id,
			abandonReasonWhenChangingProduct,
			emailOperator
		);

		if (result) {
			history.replace('/user');
			history.go();
		} else {
			showSnackbar({
				message: `Houve algum problema ao tentar mudar o produto.`,
				severity: 'error',
				duration: 6000,
			});
		}
	};

	const onCancelShoppingCart = async () => {
		if (!shoppingCartCancellingConfirmation) {
			setShoppingCartCancellingConfirmation(true);
			return;
		}
		setInternalLoading(true);
		const abandonReasonWhenCancellingShoppingCart =
			'Cancelamento de Carrinho de Compras';
		const result = await shoppingCartCancel(
			client.shoppingCart.id,
			abandonReasonWhenCancellingShoppingCart,
			emailOperator
		);

		if (result) {
			history.replace('/user');
			history.go();
		} else {
			showSnackbar({
				message: `Houve algum problema ao tentar mudar o produto.`,
				severity: 'error',
				duration: 6000,
			});
		}
	};

	useEffect(() => {
		try {
			getOperatorData();
			const options = firstOffer.map((o) => ({
				value: o.type,
				text: `${productForFirstOffer.friendlyName.toUpperCase()} - ${toStringBrlCurrency(
					o.installmentOptions[0].releasedAmount
				)}`,
			}));
			setMaxLimit(firstOffer[0].valor_total);
			setCreditOptions(options);

			const userRoles = getUserRoles();
			const hasAdminRole = userRoles.find((role) => role.toLowerCase() == 'admin');
			setIsAdmin(hasAdminRole);
		} catch (e) {
			setCreditOptions([]);
		}
	}, []);

	return (
		<Container>
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls='panel1a-content'
					id='panel1a-header'
					data-testid='create-proposal-button'
				>
					<b>{'CRIAR PROPOSTA: ETAPA 2/2'}</b>
				</AccordionSummary>
				<AccordionDetails>
					<Content>
						{creditOptions && creditOptions.length > 0 && (
              <div>
                <Select
                  id='first-offer-credit-option'
                  data-testid='first-offer-credit-option'
                  disabled={amountChanged}
                  label="Opções de 'Primeira Oferta'"
                  options={creditOptions}
                  value={creditOption}
                  onChange={onChangeCreditOption}
                  fullWidth
                />
              </div>
						)}
						{creditOptionDetails && (
							<CreditOptionDetails
								creditOptionDetails={creditOptionDetails}
								isMax={true}
							/>
						)}
						{(!creditOptions || creditOptions.length == 0) && (
							<>
								<NoOffers>
									{'Não foi possível criar oferta.'}
									<br />
									{client.shoppingCart?.status === 'noPolicy' ? (
										<>
											{'Empresa do cliente não possui política cadastrada'}
											<br />
											{'ou está bloqueada.'}
										</>
									) : (
										'Cliente não possui limite ou margem disponíveis.'
									)}
									<br />
								</NoOffers>
								<Spacer height={5} />
								<Retry onClick={onRetry} to='#'>
									{'Tentar novamente'}
								</Retry>
								<SimpleText> ou </SimpleText>
								<ChangeProduct onClick={onChangeProduct} to='#'>
									{'Alterar produto'}
								</ChangeProduct>
							</>
						)}
						<Spacer height={5} />
						{creditOption && (
							<>
								<Flex>
									<CurrencyField
										id='desired-value'
										data-testid='desired-value'	
										label='Valor da última simulação'
										value={targetAmount}
										onChange={onChangeTargetAmount}
										onBlur={onTargetAmountBlur}
										error={error}
										fullWidth
									/>
									<FlexSpacer />
									<ButtonWrapper>
										<Button
											disabled={!amountChanged || loadingInstallments || hasError}
											variant='contained'
											onClick={onGetInstallments}
											fullWidth
										>
											{'Calcular parcelas'}
										</Button>
										{loadingInstallments && <LoadTag>{'Calculando...'}</LoadTag>}
									</ButtonWrapper>
								</Flex>
								<Select
									label='Selecione as parcelas'
									options={installmentsOptions}
									value={installmentsOption}
									onChange={onChangeInstallmentsOption}
									fullWidth
								/>
								{(!installmentsOptions || installmentsOptions.length == 0) && (
                  <NoOffers>
                    {'Não há opções de parcelamento para o valor solicitado.'}
                  </NoOffers>
								)}
							</>
						)}
						<Spacer height={5} />
						{interestRatePerMonth && (
							<Flex>
								{'Taxa de juros:'}{' '}
								<b>
									{interestRatePerMonth.toLocaleString('pt-BR')}
									{'%'}
								</b>
							</Flex>
						)}
						<ButtonWrapper>
							<Button
								disabled={!installmentsOption || internalLoading}
								variant='contained'
								color='primary'
								onClick={onSendData}
								fullWidth
							>
								{'Enviar dados'}
							</Button>
							{internalLoading && <LoadTag>{'Enviando...'}</LoadTag>}
						</ButtonWrapper>

            <ButtonWrapper>
              <SimulationModal disabled={internalLoading || !simulationInstallmentsOptions.length || !targetAmount ||  !selectedProduct} installmentsOptions={simulationInstallmentsOptions} total={targetAmount} selectedProduct={selectedProduct} />
            </ButtonWrapper>

						<Spacer height={24} />
					</Content>
				</AccordionDetails>
			</Accordion>
			{isAdmin && (
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls='shopping-cart-cancel'
						id='shopping-cart-cancel'
					>
						<b>{'CANCELAR CARRINHO DE COMPRAS'}</b>
					</AccordionSummary>
					<AccordionDetails>
						{!shoppingCartCancellingConfirmation ? (
							<CancelShoppingCart
								onClick={() => setShoppingCartCancellingConfirmation(true)}
								to='#'
							>
								{'Cancelar Carrinho de Compras'}
							</CancelShoppingCart>
						) : (
							<ConfirmShoppingCartCancelling onClick={onCancelShoppingCart} to='#'>
								{internalLoading ? 'Cancelando...' : 'Clique novamente para confirmar'}
							</ConfirmShoppingCartCancelling>
						)}
					</AccordionDetails>
				</Accordion>
			)}
		</Container>
	);
};

export default InSettingUp;
