import axios from "axios";
import { API } from "config";

const STORAGE_KEY_AUTH = "HEADER_TOKEN_KEY_AUTH";
const ALI_ACCESS_TOKEN = "ALI_ACCESS_TOKEN";

const getToken = () => sessionStorage.getItem(ALI_ACCESS_TOKEN);

const getOperatorEmail = () => {
  const data = JSON.parse(sessionStorage.getItem(STORAGE_KEY_AUTH));
  return data?.user?.email || "";
};

const uploadDocument = async (id, type, data) => {
  try {
    data.append("operatorEmail", getOperatorEmail());

    const response = await axios.put(`${API.SHOPPING_CART_UPLOAD}${type}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response && response?.status === 204;
  } catch (error) {
    console.error("Error in uploadDocument:", error.message);
    return false;
  }
};

export default uploadDocument;
