/* eslint-disable */

import React, { useState, useEffect, useCallback } from "react";

import iconClose from "../assets/close-window-xxl.png";
import { DropDownSearchable } from "_FUTURE_LIBS_/@ali-ui";
import { IssuerDateField, CurrencyField } from "_FUTURE_LIBS_/@ali-forms";
import { Modal, CloseButton, ModalTitle, ButtonOk, TableWrapper, Row, Td, TdLeft } from "./styles";

import { formatDisplayCpf, formatDisplayCnpj, convertStringToNumber } from "utils";
import getCompanies from "api/alicore/getCompanies";
import isInvalidDate from "_FUTURE_LIBS_/@ali-forms/FormFields/DateField/validation";
import Alert from "components/Alert";
import PropTypes from "prop-types";

export const validateAgreementCreation = (validationData, { hasLoanManager }) => {
	const invalid =
		!validationData.companyDocumentNumber ||
		!validationData.bondBeginDate ||
		!validationData.monthlyIncome ||
		validationData.monthlyIncome <= 0 ||
		isInvalidDate(validationData.bondBeginDate);
	return hasLoanManager || !invalid;
};

const AgreementCreate = ({ data, loading, isOpen, onClose, onOK }) => {
	const [name, setName] = useState();
	const [documentNumber, setDocumentNumber] = useState();
	const [companies, setCompanies] = useState([]);
	const [companyDocumentNumber, setCompanyDocumentNumber] = useState();
	const [bondBeginDate, setBondBeginDate] = useState();
	const [monthlyIncome, setMonthlyIncome] = useState();
	const [consignableFreeMargin, setConsignableFreeMargin] = useState(0);
	const [confirming, setConfirming] = useState(false);
	const [hasLoanManager, setHasLoanManager] = useState(false);

	const handleOnClose = useCallback(() => {
		setConfirming(false);
		if (onClose) onClose();
	}, []);

	const handleOnOK = () => {
		if (confirming) {
			const internalData = getInternalData();
			if (onOK) onOK(internalData);
		}
		setConfirming(!confirming);
	};

	const getInternalData = () => {
		return {
			documentNumber,
			companyDocumentNumber,
			bondBeginDate,
			monthlyIncome: convertStringToNumber(monthlyIncome),
			consignableFreeMargin: convertStringToNumber(consignableFreeMargin),
		};
	};

	const handleOnChangeCode = async (value) => {
		if (!value.target.innerText) {
			setCompanyDocumentNumber(null);
			setHasLoanManager(false);
			return;
		}

		const selectedCompany = value.target.innerText.split(" - ")[0];
		const company = companies.find((bank) => formatDisplayCnpj(bank.document) === selectedCompany);

		if (company) {
			setCompanyDocumentNumber(company.document);
			setHasLoanManager(company.hasLoanManager);
		} else {
			setCompanyDocumentNumber(null);
			setHasLoanManager(false);
		}
	};

	const handleGetOptionLabel = useCallback((option) => `${formatDisplayCnpj(option.document)} - ${option.name}`, []);

	useEffect(() => {
		if (isOpen) {
			const getData = async () => {
				const result = await getCompanies();
				setCompanies(result);
			};

			if (data?.client) {
				if (data.client.user.name) setName(data.client.user.name);
				if (data.client.user.document) setDocumentNumber(data.client.user.document);
			}
			getData();
		}
	}, [isOpen]);

	useEffect(() => {
		validateAgreementCreation(getInternalData(), { hasLoanManager });
	}, [monthlyIncome, bondBeginDate, hasLoanManager]);

	return (
		<Modal style={{ display: isOpen ? "block" : "none" }}>
			<ModalTitle>Novo convênio</ModalTitle>
			<CloseButton data-testid="close-modal" src={iconClose} onClick={handleOnClose} />
			<div>
				<Alert visible={hasLoanManager}>Esta empresa possui utiliza os dados dos nossos gerenciadores de crédito</Alert>
				<TableWrapper>
					<Row>
						<TdLeft>Nome:</TdLeft>
						<Td>{name}</Td>
					</Row>
					<Row>
						<TdLeft>CPF:</TdLeft>
						<Td>{formatDisplayCpf(documentNumber)}</Td>
					</Row>
					<Row>
						<Td style={{ width: 800 }}>
							<DropDownSearchable
								label="Empresa"
								placeholder="Selecione a empresa"
								findValueFromProperty="document"
								value={companyDocumentNumber}
								options={companies}
								getOptionLabel={handleGetOptionLabel}
								onChange={handleOnChangeCode}
								onSelect={handleOnChangeCode}
								maxVisibleLines={5}
								fullWidth
							/>
						</Td>
					</Row>
					{!hasLoanManager && companyDocumentNumber && (
						<>
							<Row>
								<Td style={{ width: 800 }}>
									<IssuerDateField
										label="Data de admissão"
										placeholder=""
										value={bondBeginDate}
										onChange={setBondBeginDate}
										onBlur={setBondBeginDate}
									/>
								</Td>
							</Row>
							<Row>
								<Td style={{ width: 800 }}>
									<CurrencyField label="Salário" value={monthlyIncome} onChange={setMonthlyIncome} onBlur={setMonthlyIncome} fullWidth />
								</Td>
							</Row>
							<Row>
								<Td style={{ width: 800 }}>
									<CurrencyField
										label="Margem"
										value={consignableFreeMargin}
										onChange={setConsignableFreeMargin}
										onBlur={setConsignableFreeMargin}
										fullWidth
									/>
								</Td>
							</Row>
						</>
					)}
				</TableWrapper>
			</div>
			<ButtonOk
				data-testid="submit-button"
				disabled={loading || !validateAgreementCreation(getInternalData(), { hasLoanManager })}
				variant="contained"
				color={!confirming ? "primary" : "secondary"}
				onClick={handleOnOK}
			>
				{loading && "Enviando"}
				{!loading && (confirming ? "Clique novamente para confirmar" : "OK")}
			</ButtonOk>
		</Modal>
	);
};

AgreementCreate.propTypes = {
  data: {
    client: {
      user: {
        name: PropTypes.string,
        document: PropTypes.string,
      },
    },
  },
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOK: PropTypes.func,
};

export default AgreementCreate;