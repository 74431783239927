import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '_FUTURE_LIBS_/@ali-ui';
import isValid from './validation';

export const ERROR_MSG = 'CPF inválido';

const CPFField = ({
  label,
  placeholder,
  onChange,
  noValidation,
  value,
  errorMsg,
  autoFocus,
  ...rest
}) => {
  const [error, setError] = useState(false);

  const handleOnChange = (cpf) => {
    if (!noValidation) {
      const invalid = cpf.length === 14 && !isValid(cpf);
      setError(invalid && errorMsg);
    }
    onChange(cpf);
  };

  const handleOnBlur = () => {
    if (!noValidation) {
      const invalid = value !== '' && !isValid(value);
      setError(invalid && errorMsg);
    }
  };

  const handleOnFocus = () => {
    if (!noValidation) {
      setError(false);
    }
  };

  CPFField.isValid = (inValue) => isValid(inValue || value);

  return (
    <TextField
      data-testid="field-cpf"
      label={label}
      placeholder={placeholder}
      onChange={handleOnChange}
      value={value}
      inputMode="decimal"
      format={{
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        numericOnly: true,
        delimiterLazyShow: true,
      }}
      error={error}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      autoFocus={autoFocus}
      {...rest}
    />
  );
};

/* istanbul ignore next */
CPFField.defaultProps = {
  label: 'CPF',
  placeholder: 'Digite aqui seu CPF',
  onChange: () => {},
  value: '',
  noValidation: false,
  errorMsg: ERROR_MSG,
  autoFocus: false,
};

CPFField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  noValidation: PropTypes.bool,
  errorMsg: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default CPFField;
