import styled from 'styled-components';

export const TableWrapper = styled.div`
  padding: 16px 16px;
  height: fit-content;
`;

export const TableTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #031e52;
  padding: 0px 8px 24px 8px;
  text-align: center;
`;

export const Warning = styled.div`
  color: red;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Td = styled.div`
  padding: 2px 0;
  min-width: 270px;
  background-color: 'green';
`;

export const TdLeft = styled.div`
  vertical-align: top;
  white-space: nowrap;
  padding-right: 8px;
  color: #666666;
`;

export const NotInformed = styled.div`
  color: #b0b0b0;
  font-size: 12px;
`;

export const Abandon = styled.div`
  border-top: 1px solid #b0b0b0;
  width: 100%;
  padding-right: 12px;
  margin-top: 8px;
`;

export const ButtonBack = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #b0b0b0;
  margin-top: 14px;
  padding-top: 14px;
  font-size:16px;
  font-weight: 900;
  color: #031e52;
  cursor: pointer;
`;

