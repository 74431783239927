import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Container, SelectStyled, Label } from "./styles";

const Select = ({ label, disabled, value, options, onChange, placeholder, fullWidth, "data-testid": dataTestid }) => {
	const ref = useRef();
	const [hasSelection, setHasSelection] = useState(ref.current?.selectedIndex === 0);

	const handleChange = (e) => {
		setHasSelection(ref.current.selectedIndex !== 0);
		onChange(e.target.value);
	};

	return (
		<Container>
			<Label>{label}</Label>
			<SelectStyled
				data-testid={dataTestid}
				ref={ref}
				value={value}
				onChange={handleChange}
				hasSelection={hasSelection}
				fullWidth={fullWidth}
				disabled={disabled}
			>
				<option value="" disabled>
					{placeholder}
				</option>
				{options?.map((option) => (
						<option data-testid={option.value} key={option.value} value={option.value}>
							{option.text || option.value}
						</option>
					))}
			</SelectStyled>
		</Container>
	);
};

/* istanbul ignore next */
Select.defaultProps = {
	label: "",
	onChange: () => {},
	value: "",
	placeholder: "Selecione...",
	fullWidth: false,
	"data-testid": null,
	options: null,
};

Select.propTypes = {
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			text: PropTypes.string,
		})
	),
	onChange: PropTypes.func,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	fullWidth: PropTypes.bool,
	"data-testid": PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;
