import { API } from "config";
import axios from "axios";

const resendMail = async (proposalId) => {
  const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
  const response = await axios.post(
    `${API.RESEND_MAIL}${proposalId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch(e => {});

  return response && response.status == 200 && response.data;
};

export default resendMail;
