import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  ${({ justify }) => (justify ? `justify-content: ${justify};` : '')}
`;

export const Clickable = styled.div`
  font-weight: 600;
  color: #3772FF;
  cursor: pointer;
`;
