import styled from "styled-components";

export const Container = styled.div`
	max-width: 360px;
	p {
		font-size: 0.75rem;
		line-height: 1rem;
		margin: 0;
	}

	.loading {
		margin: 1rem 0;
	}
`;
export const CollapseButton = styled.button`
	background: none;
	border: 0;
	box-shadow: 0;
	padding: 0;
	margin-top: 1rem;
	font-weight: bold;
	color: #031e52;
	cursor: pointer;
	display: flex;
	align-items: center;
	line-height: 24px;

	&:hover {
		opacity: 0.8;
	}
`;

export const Collapsible = styled.div`
	max-height: 0;
	overflow: hidden;
	transition: 0.5s;
	margin: 0 0 1rem;

	&.open {
		max-height: 999px;
	}
`;
export const MaxOfferCard = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	.error {
		color: #f00;
	}
	h5 {
		margin: 0;
		font-size: 1rem;
	}
	h6 {
		margin: 0;
		font-size: 0.8rem;
		margin-top: 0.5rem;
	}
	p {
		display: flex;
		justify-content: space-between;
		margin-top: 0.25rem;
		span {
			white-space: nowrap;
		}
	}
`;

export const RefreshButton = styled.button`
	border: 0;
	background: #664d03;
	color: #fff;
	padding: 0.25rem 0.5rem;
	border-radius: 0.25rem;
	cursor: pointer;
`;
