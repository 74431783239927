import { API } from 'config';
import axios from 'axios';

const searchAddressByCEP = async (cep) => {
	try {
		const accessToken = sessionStorage.getItem('ALI_ACCESS_TOKEN');

		const response = await axios
			.get(`${API.SEARCH_ADDRESS_BY_CEP}/${cep}`, {
				headers: {
					aliauth: `Bearer ${accessToken}`,
				},
			})
			.catch((e) => {});

		if (!response?.data?.success) {
			return null;
		}

		return response.data.data;
	} catch (error) {
		return null;
	}
};

export default searchAddressByCEP;
