import React, { useContext, useState, useEffect, useCallback } from "react";
import getPromotions from "api/alicore/getPromotions";
import createShoppingCartByPromotion from "api/alicore/createShoppingCartByPromotion";
import { Content, Title, TableWrapper, Th, Td, Tr } from "./styles";
import { Button } from "@material-ui/core";
import { SnackbarContext } from "contexts/SnackbarProvider";

const Promotions = () => {
	const [promotions, setPromotions] = useState([]);
	const [showSnackbar] = useContext(SnackbarContext);
	const [disabled, setDisabled] = useState(false);

	const delay = (ms) => new Promise((res) => setTimeout(res, ms));

	const listPromotions = () => {
		getPromotions(sessionStorage.getItem("ALI_CURRENT_CUSTOMER"))
			.then((res) => {
				setPromotions(res);
			})
			.catch((error) =>
				showSnackbar({
					message: `Dados de contrato incompletos.`,
					severity: "error",
					duration: 5000,
				})
			);
	};

	const onClick = useCallback((event) => {
		setDisabled(true);
		createShoppingCartByPromotion(sessionStorage.getItem("ALI_CURRENT_CUSTOMER"), event.currentTarget.id).then((res) => {
			if (res && res.length > 0) {
				const resObj = res[0];
				const { success } = resObj;

				if (!success) {
					showSnackbar({
						message: `Um erro ocorreu ao criar o carrinho de compras. Verifique se o cliente possui limite de crédito disponível e Margem.`,
						severity: "error",
						duration: 5000,
					});
				}

				delay(5000).then(() => {
					setDisabled(false);
					window.location.reload(false);
				});
			}
		});
	}, []);

	useEffect(() => {
		listPromotions();
	}, []);

	return (
		<Content>
			<TableWrapper>
				<Title>Promoções</Title>
				{!promotions && <div>Carregando...</div>}
				{!!promotions && (
					<table>
						<tbody>
							<tr>
								<Th>Id</Th>
								<Th>Nome</Th>
								<Th>Valor</Th>
								<Th>Numero de Parcelas</Th>
								<Th>Taxa</Th>
								<Th>Carencia</Th>
							</tr>
							{promotions.map((promotion) => (
								<Tr key={promotion.uniquePromotionId}>
									<Td>{promotion.uniquePromotionId}</Td>
									<Td>{promotion.name}</Td>
									<Td>R$ {promotion.demoValue}</Td>
									<Td>{promotion.demoNumberOfInstallments}x</Td>
									<Td>{promotion.demoRate}%</Td>
									<Td>{promotion.demoGracePeriod} dias</Td>
									<Td>
										<Button
											id={promotion.uniquePromotionId}
											disabled={disabled}
											variant="contained"
											color="primary"
											onClick={onClick}
											fullWidth
										>
											<b>Criar Proposta</b>
										</Button>
									</Td>
								</Tr>
							))}
						</tbody>
					</table>
				)}
			</TableWrapper>
		</Content>
	);
};

export default Promotions;
