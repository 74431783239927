import React, { useContext, useState, useEffect } from "react";
import { ClientContext } from "contexts/ClientProvider";
import { statuses } from "../../../helpers/statuses";
import { formatDisplayDate, formatDisplayCpf } from "utils";
import { Content, Title, TableWrapper, Th, Td, Tr } from "./styles";

const UserProposals = () => {
	const [client] = useContext(ClientContext);
	const [proposals, setProposals] = useState();

	const getProposals = () => {
		let data = [];
		if (client.proposals && client.proposals.length > 0) {
			data = client.proposals.map((p) => ({
				id: p.id,
				sequentialNumber: p.id,
				cpf: p.user.nationalDocument,
				status: p.status,
				creationDate: p.created_at,
			}));
		}
		setProposals(data);
	};

	useEffect(() => {
		getProposals();
	}, []);

	return (
		<Content>
			<TableWrapper>
				<Title>Propostas</Title>
				{!proposals && <div>Carregando...</div>}
				{!!proposals && (
					<table>
						<tbody>
							<tr>
								<Th>Status</Th>
								<Th>CPF</Th>
								<Th>Seq. Number</Th>
								<Th>Data Criação</Th>
								<Th>ID</Th>
							</tr>
							{proposals.map(({ id, sequentialNumber, cpf, status, creationDate }) => (
								<Tr key={`${id}`}>
									<Td>{status ? statuses(status) : status}</Td>
									<Td>{formatDisplayCpf(cpf)}</Td>
									<Td>{sequentialNumber}</Td>
									<Td>{formatDisplayDate(creationDate)}</Td>
									<Td>{id}</Td>
								</Tr>
							))}
						</tbody>
					</table>
				)}
			</TableWrapper>
		</Content>
	);
};

export default UserProposals;
