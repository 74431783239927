import { API } from 'config';

const postPriorityPolice = async (body) => {
  try {
    const response = await fetch(API.POST_PRIORITY_POLICE_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const policy = await response.json();
      return {
        success: true,
        failure: false,
        status: response.status,
        value: policy,
      };
    }
    return {
      success: false,
      failure: true,
      status: response.status,
    };
  } catch (error) {
    return {
      success: false,
      failure: true,
      error,
    };
  }
};

export default postPriorityPolice;
