import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SnackbarContext } from "contexts/SnackbarProvider";

import iconUnchecked from "../assets/iconUnchecked.svg";
import iconChecked from "../assets/iconChecked.svg";
import { Button } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { API } from "config";
import { Link } from "react-router-dom";
import {
	Container,
	Title,
	TitleDisabled,
	IconWrapper,
	Icon,
	TableTitle,
	InvalidDoc,
	TableSubTitle,
	SubTitleObs,
	StatusTag,
	MagicLinkBox,
} from "./styles";

import NationalId, { validateNationalId, getNationalIdFormData } from "./NationalId";

import PostalInformation, { validatePostalInformation, getPostalInformationFormData } from "./PostalInformation";

import Payslip, { validatePayslip, getPayslipFormData } from "./Payslip";

import BankAccount, { validateBankAccount, getBankAccountFormData } from "./BankAccount";

import Selfie, { validateSelfie, getSelfieFormData } from "./Selfie";

import uploadDocument from "api/alicore/uploadDocument";

const docTypes = [
	{ key: "value-object.national-id", value: "RG ou CNH", validate: validateNationalId },
	{ key: "value-object.postal-information", value: "Comprovante de Endereço", validate: validatePostalInformation },
	{ key: "value-object.payslip", value: "Holerite / Contracheque", validate: validatePayslip },
	{ key: "value-object.bank-account", value: "Dados bancários", validate: validateBankAccount },
	{ key: "value-object.selfie", value: "Selfie", validate: validateSelfie },
];

const DOCUMENTS_THAT_CAN_BE_VIEWED = ["value-object.bank-account", "value-object.payslip"];

export const INVALID_DATA = (
	<>
		Os dados preenchidos são inválidos e <b>não serão</b> enviados. Clique no <b>botão ao lado</b> para que possam ser <b>corrigidos</b>.
	</>
);

export const renderInvalidDoc = (isValid) => {
	if (isValid == null || isValid) {
		return <></>;
	}

	return <InvalidDoc data-testid="invalid-doc">{INVALID_DATA}</InvalidDoc>;
};

const DocumentBox = ({ shoppingCart, onUpdateDocOrRetry, loading, setLoading }) => {
	const [showSnackbar] = useContext(SnackbarContext);

	const [documents, setDocuments] = useState(null);
	const [selfieBiometryIntegration, setSelfieBiometryIntegration] = useState(false);

	const [isNationalIdOpen, setIsNationalIdOpen] = useState(false);
	const [isNationalIdValid, setIsNationalIdValid] = useState(null);

	const [isPostalInformationOpen, setIsPostalInformationOpen] = useState(false);
	const [isPostalInformationValid, setIsPostalInformationValid] = useState(null);

	const [payslipInfos, setPayslipInfos] = useState({
		isOpen: false,
		document: null,
	});
	const [isPayslipValid, setIsPayslipValid] = useState(null);

	const [isSelfieOpen, setIsSelfieOpen] = useState(false);
	const [isSelfieValid, setIsSelfieValid] = useState(null);

	const [bankAccountInfos, setBankAccountInfos] = useState({
		isOpen: false,
		document: null,
	});
	const [isBankAccountValid, setIsBankAccountValid] = useState(null);

	const onClick = (doc, document) => {
		if (doc.key.endsWith("national-id")) {
			setIsNationalIdOpen(true);
		} else if (doc.key.endsWith("postal-information")) {
			setIsPostalInformationOpen(true);
		} else if (doc.key.endsWith("payslip")) {
			setPayslipInfos({
				isOpen: true,
				document,
			});
		} else if (doc.key.endsWith("bank-account")) {
			setBankAccountInfos({
				isOpen: true,
				document,
			});
		} else if (doc.key.endsWith("selfie")) {
			setIsSelfieOpen(true);
		}
	};

	const onCloseModal = (doc) => {
		if (doc.key.endsWith("national-id")) {
			setIsNationalIdOpen(false);
		} else if (doc.key.endsWith("postal-information")) {
			setIsPostalInformationOpen(false);
		} else if (doc.key.endsWith("payslip")) {
			setPayslipInfos((prevState) => ({
				...prevState,
				isOpen: false,
			}));
		} else if (doc.key.endsWith("bank-account")) {
			setBankAccountInfos((prevState) => ({
				...prevState,
				isOpen: false,
			}));
		} else if (doc.key.endsWith("selfie")) {
			setIsSelfieOpen(false);
		}
	};

	const onOKModal = (doc, data) => {
		if (doc.key.endsWith("national-id")) {
			onOKNationalId(data);
		} else if (doc.key.endsWith("postal-information")) {
			onOKPostalInformation(data);
		} else if (doc.key.endsWith("payslip")) {
			onOKPayslip(data);
		} else if (doc.key.endsWith("bank-account")) {
			onOKBankAccount(data);
		} else if (doc.key.endsWith("selfie")) {
			onOKSelfie(data);
		}
	};

	const onOKNationalId = async (data) => {
		const isNationalIdValid = validateNationalId(data);
		setIsNationalIdValid(isNationalIdValid);

		if (isNationalIdValid) {
			setLoading(true);

			const formData = getNationalIdFormData(data);
			const result = await uploadDocument(shoppingCart.id, "national-id", formData);
			if (result) {
				if (onUpdateDocOrRetry) onUpdateDocOrRetry();
			} else {
				showErrorMessage();
			}

			setLoading(false);
		}

		setIsNationalIdOpen(false);
	};

	const onOKPostalInformation = async (data) => {
		const isPostalInformationValid = validatePostalInformation(data);
		setIsPostalInformationValid(isPostalInformationValid);

		if (isPostalInformationValid) {
			setLoading(true);

			const formData = getPostalInformationFormData(data);
			const result = await uploadDocument(shoppingCart.id, "postal-information", formData);
			if (result) {
				if (onUpdateDocOrRetry) onUpdateDocOrRetry();
			} else {
				showErrorMessage();
			}

			setLoading(false);
		}

		setIsPostalInformationOpen(false);
	};

	const onOKPayslip = async (data) => {
		const isPayslipValid = validatePayslip(data);
		setIsPayslipValid(isPayslipValid);

		if (isPayslipValid) {
			setLoading(true);

			const formData = getPayslipFormData(data);
			const result = await uploadDocument(shoppingCart.id, "payslip", formData);
			if (result) {
				if (onUpdateDocOrRetry) onUpdateDocOrRetry();
			} else {
				showErrorMessage();
			}

			setLoading(false);
		}

		setPayslipInfos((prevState) => ({
			...prevState,
			isOpen: false,
		}));
	};

	const onOKBankAccount = async (data) => {
		const isBankAccountValid = validateBankAccount(data);
		setIsBankAccountValid(isBankAccountValid);

		if (isBankAccountValid) {
			setLoading(true);

			const formData = getBankAccountFormData(data);
			const result = await uploadDocument(shoppingCart.id, "bank-account", formData);
			if (result) {
				if (onUpdateDocOrRetry) onUpdateDocOrRetry();
			} else {
				showErrorMessage();
			}

			setLoading(false);
		}

		setBankAccountInfos((prevState) => ({
			...prevState,
			isOpen: false,
		}));
	};

	const onOKSelfie = async (data) => {
		const isSelfieValid = validateSelfie(data);
		setIsSelfieValid(isSelfieValid);

		if (isSelfieValid) {
			setLoading(true);

			const formData = getSelfieFormData(data);
			const result = await uploadDocument(shoppingCart.id, "selfie", formData);
			if (result) {
				if (onUpdateDocOrRetry) onUpdateDocOrRetry();
			} else {
				showErrorMessage();
			}

			setLoading(false);
		}

		setIsSelfieOpen(false);
	};

	const showErrorMessage = () => {
		showSnackbar({
			message: `Houve algum problema. Tente novamente e caso não funcione contate o suporte.`,
			severity: "error",
			duration: 6000,
		});
	};

	const isValidDocumentByType = (doc, data) => {
		if (doc.key.endsWith("national-id")) {
			return validateNationalId(data);
		} else if (doc.key.endsWith("postal-information")) {
			return validatePostalInformation(data);
		} else if (doc.key.endsWith("payslip")) {
			return validatePayslip(data);
		} else if (doc.key.endsWith("bank-account")) {
			return validateBankAccount(data);
		} else if (doc.key.endsWith("selfie")) {
			return validateSelfie(data);
		} else {
			return false;
		}
	};

	const documentIcon = (doc) => {
		const document = documents.filter((d) => d.item[0].__component == doc.key);
		if (document && document.length > 0 && doc.validate(document[0]?.item[0] || {}) && isValidDocumentByType(doc, document[0]?.item[0])) {
			return iconChecked;
		}
		return iconUnchecked;
	};
	const isIntegrationDocument = (doc) => {
		const document = documents.filter((d) => d.item[0].__component == doc.key);
		return !!document[0]?.integrationApp;
	};

	const documentDescription = (doc) => {
		const document = documents.filter((d) => d.item[0].__component == doc.key);

		if (
			document &&
			document.length > 0 &&
			doc.validate(document[0]?.item[0]) &&
			!DOCUMENTS_THAT_CAN_BE_VIEWED.includes(document[0]?.item[0]?.__component)
		) {
			return doc.value;
		}

		return (
			<>
				<Link
					onClick={() => {
						onClick(doc, document[0]?.item[0]);
					}}
					to="#"
					data-testid={doc.key}
				>
					{doc.value}
				</Link>
				{documentType(doc)}
			</>
		);
	};

	const documentType = (doc) => {
		if (doc.key.endsWith("national-id")) {
			return (
				<>
					<NationalId isOpen={isNationalIdOpen} onClose={onCloseModal} onOK={onOKModal} doc={doc} loading={loading} />
					{renderInvalidDoc(isNationalIdValid)}
				</>
			);
		} else if (doc.key.endsWith("postal-information")) {
			return (
				<>
					<PostalInformation isOpen={isPostalInformationOpen} onClose={onCloseModal} onOK={onOKModal} doc={doc} loading={loading} />
					{renderInvalidDoc(isPostalInformationValid)}
				</>
			);
		} else if (doc.key.endsWith("payslip")) {
			return (
				<>
					<Payslip
						isOpen={payslipInfos.isOpen}
						onClose={onCloseModal}
						onOK={onOKModal}
						doc={doc}
						loading={loading}
						data={payslipInfos.document}
					/>
					{renderInvalidDoc(isPayslipValid)}
				</>
			);
		} else if (doc.key.endsWith("bank-account")) {
			return (
				<>
					<BankAccount
						isOpen={bankAccountInfos.isOpen}
						onClose={onCloseModal}
						onOK={onOKModal}
						doc={doc}
						loading={loading}
						data={bankAccountInfos.document}
					/>
					{renderInvalidDoc(isBankAccountValid)}
				</>
			);
		} else if (doc.key.endsWith("selfie")) {
			return (
				<>
					<Selfie isOpen={isSelfieOpen} onClose={onCloseModal} onOK={onOKModal} doc={doc} loading={loading} />
					{renderInvalidDoc(isSelfieValid)}
				</>
			);
		}

		return <>{doc.key}</>;
	};

	const handleCreateSelfieLink = async () => {
		const accessToken = sessionStorage.getItem("ALI_ACCESS_TOKEN");
		const payload = {
			shoppingCartId: shoppingCart.id,
			type: "selfie",
			userId: shoppingCart.user.id,
			sendMessage: true,
		};

		const response = await axios
			.post(API.CREATE_SELFIE_MAGIC_LINK, payload, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then((res) => res.data)
			.catch((e) => {});

		if (response.success) {
			showSnackbar({
				message: `Link da selfie enviado para o WhatsApp do cliente!`,
				severity: "success",
				duration: 6000,
			});
		} else {
			showSnackbar({
				message: `Erro ao enviar o link da selfie. Tente novamente!`,
				severity: "error",
				duration: 6000,
			});
		}
	};

	useEffect(() => {
		setDocuments(shoppingCart.documents ? shoppingCart.documents : []);
		setSelfieBiometryIntegration(sessionStorage.getItem("HAS_SELFIE_BIOMETRY_INTEGRATION"));
	}, []);

	return (
		<>
			<TableTitle>Checklist de Envio de Documentos</TableTitle>
			<TableSubTitle>
				Para enviar documentos clique nos links <b>em azul</b> abaixo:
				<br />
				<SubTitleObs>
					<b>Obs: </b>caso o cliente possua documentos válidos na base eles já virão preenchidos.
				</SubTitleObs>
			</TableSubTitle>
			<Container>
				{documents &&
					docTypes.map((d) => (
						<IconWrapper key={d.key}>
							<Icon src={documentIcon(d)} />
							{d.value == "Selfie" &&
								(selfieBiometryIntegration == "true" ? (
									<MagicLinkBox>
										<TitleDisabled>Selfie</TitleDisabled>
										{documentIcon(d) == iconUnchecked && (
											<Button variant="contained" color="primary" onClick={handleCreateSelfieLink} fullWidth data-testid="send-selfie">
												<SendIcon />
												<b>Enviar Link</b>
											</Button>
										)}
									</MagicLinkBox>
								) : (
									<Title>{documentDescription(d)}</Title>
								))}
							{d.value !== "Selfie" && <Title>{documentDescription(d)}</Title>}
							{isIntegrationDocument(d) && <StatusTag>Via integração</StatusTag>}
						</IconWrapper>
					))}
			</Container>
		</>
	);
};

DocumentBox.propTypes = {
	shoppingCart: PropTypes.any,
  onUpdateDocOrRetry: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default DocumentBox;
