import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 430px;
	margin: 0 auto;
	background: #fcfcfd;
	padding: 0 18px 24px 18px;

	* {
		box-sizing: border-box;
	}
`;

export const HeaderContainer = styled.header`
	height: 124px;
	z-index: 1;
`;

export const Header = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: end;
	border-bottom: 0.7px solid #d5d5d5;
	padding-bottom: 20px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Main = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	width: 100%;
`;

export const Container = styled.div`
	display: flex;
	margin: 18px 0 0 0;
	gap: 12px;
	width: 100%;
	flex-direction: column;
	z-index: 1;
`;

export const Column = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 44px;
`;

export const SubTitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #0f62fe;
	text-align: center;
`;

export const Title = styled.h1`
	font-weight: 700;
	font-size: 30px;
	line-height: 24px;
	color: #001d6c;
	text-align: center;
`;

export const ModalitiesWrapper = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
`;

export const Results = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 0.5px solid #7e7e7e;

	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
	}

	p {
		margin: 0;
		display: block;
		line-height: 16px;
	}
`;

export const ResultsValue = styled.p`
	min-width: 100px;
	text-align: left;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid #d6d7dd;
	border-radius: 15px;
	padding: 24px 16px;
	gap: 4px;

	h2 {
		margin: 0 0 10px 0;
	}

	h4 {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #57595f;
		text-align: center;
		margin: 8px 0 8px 0;
	}
`;

export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	background: #000000;
	border-radius: 15px;
	width: 100%;
`;

export const ModalTitle = styled.h2`
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	margin: 0;
`;

export const MiniModal = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #0f62fe;
	border-radius: 7px;

	header {
		background: #0f62fe;
		border-radius: 7px;

		h3 {
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
			color: #fefefe;
			text-align: center;
		}
	}

	p {
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
	}
`;

export const FirstParcelDate = styled.p`
	font-weight: 600;
	font-size: 12px;
	line-height: 13px;
	color: #b3b4b8;
	text-align: center;
	margin: 8px 0 0 0;
`;

export const ParcelDate = styled.p`
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	color: #1e1e1e;
	margin: 0;
`;

export const ModalFooter = styled.footer`
	width: 100%;

	p {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		color: #ffffff;
		margin: 4px 0;

		span {
			font-weight: 600;
		}
	}
`;

export const ModalitiesContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: #f5f6fa;
	border-radius: 7px;
`;

export const ModalitiesHeader = styled.header`
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: #969696;
	padding: 14px 8px 14px 8px;
`;

export const ModalitiesItemContainer = styled.div`
	background: #0f62fe;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	padding: 8px;
`;

export const ModalitiesItem = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding: 8px 0 8px 0;

	text-align: center;
	font-weight: 700;
	font-size: 11px;
	line-height: 12px;
	color: #ffffff;

	p {
		margin: 0 0 4px 0;
	}

	&:not(:last-child) {
		border-bottom: 0.7px solid #4d91ff;
	}
`;

export const ModalitiesItemValue = styled.p`
	font-weight: 500;
`;

export const Footer = styled.div`
	display: flex;

	color: #1e1e1e;
	font-size: 11px;
	line-height: 18px;
	text-align: center;
`;
