
import React from 'react';
import PropTypes from 'prop-types';
import CurrencyField from '../CurrencyField';

const FinancedValueField = ({
  label,
  ...rest
}) => (
  <CurrencyField
    label={label}
    data-testid="field-financed-value"
    {...rest}
  />
);

FinancedValueField.defaultProps = {
  label: 'Digite aqui o quanto você precisa',
};

FinancedValueField.propTypes = {
  label: PropTypes.string,
};

export default FinancedValueField;
