import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { isMobile } from 'utils';
import { Content, CloseWrapper } from './styles';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DialogModal = ({
  open,
  onClose,
  noIconClose,
  noMargins,
  children,
}) => (
  <Dialog fullScreen={isMobile()} open={open} onClose={onClose} TransitionComponent={Transition}>
    {!noIconClose && (
      <CloseWrapper>
        <IconButton
          edge="end"
          size="small"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </CloseWrapper>
    )}
    <Content noMargins={noMargins}>
      {children}
    </Content>
  </Dialog>
);

DialogModal.defaultProps = {
  noIconClose: false,
  noMargins: false,
};

DialogModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  noIconClose: PropTypes.bool,
  noMargins: PropTypes.bool,
};

export default DialogModal;
